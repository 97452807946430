import { useFilestack } from 'hooks/useFilestack';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
const PickerDropPane = dynamic(
  () => import('../../components/FacilityMaps/PickerDropPane'),
  {
    ssr: false
  }
);
type ImageUploaderProps = {
  show: boolean;
  options?: any;
  onSuccess: (result: any) => void;
  onError: (error: any) => void;
};

export const ImageUploader = (props: ImageUploaderProps) => {
  const { show, ...rest } = props;
  const { policy, signature } = useFilestack();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
    return function cleanup() {
      setLoaded(false);
    };
  }, []);
  if (!policy || !signature) {
    return <div />;
  }
  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      {loaded && <PickerDropPane {...rest} />}
    </div>
  );
};
