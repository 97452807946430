import { BasicFilters } from './BasicFilters';
import { Button, Grid } from 'react-md';
import { Collapse } from '@react-md/transition';
import {
  FilterConditionView,
  FilterRuleView
} from 'modules/reports/domain/ReportView';
import { FilterOption, SelectedFiltersType } from './FilterTypes';
import { FiltersResponse } from 'modules/shared/UseCases/GetFilters/types';
import { LayoutFilters } from './LayoutFilters';
import { Loading } from 'components/Layout/Loading';
import { MdTune } from 'react-icons/md';
import { findPlans } from 'services/plans';
import { getFilters } from 'services/filters';
import { useEffect, useState } from 'react';
import AdvancedFilter from './AdvancedFilter';
import styles from './filter-drawer.module.scss';
import useService from 'hooks/useService';
export interface FilterDrawerProps {
  onFilter: (selectedFilters: SelectedFiltersType, isChanged: boolean) => void;
  selectedFilters?: SelectedFiltersType;
  rolling?: boolean;
  maps?: boolean;
  includeCharts?: boolean;
  includeCustomCharts?: boolean;
  surfacePlans?: boolean;
  locationId?: string;
  clientId?: string;
  permanent?: boolean;
  reportTypes?: FilterOption[];
  disableDateRange?: boolean;
  nonConformances?: boolean;
  buttonTitle?: string;
  canUseBasicFilters?: boolean;
  includeUntestedToggle?: boolean;
}

export const FilterDrawer = (props: FilterDrawerProps) => {
  const {
    clientId,
    locationId,
    rolling,
    surfacePlans,
    disableDateRange,
    maps,
    selectedFilters,
    onFilter,
    permanent,
    reportTypes,
    includeCharts,
    includeCustomCharts,
    nonConformances,
    buttonTitle,
    canUseBasicFilters,
    includeUntestedToggle
  } = props;

  const [collapsed, setCollapsed] = useState(!permanent);
  const [hasFilters, setHasFilters] = useState(false);

  const { data, isLoading } = useService<FiltersResponse>(
    `/api/filters/?clientId=${clientId}&locationId=${locationId}&plans=${
      surfacePlans ?? false
    }`,
    () =>
      getFilters({
        clientId,
        locationId,
        plans: surfacePlans || false
      })
  );

  useEffect(() => {
    if (
      maps &&
      data &&
      data.facilityMaps?.length &&
      data.facilityMaps?.length > 0
    ) {
      if (!selectedFilters?.facilityMap && !selectedFilters?.map) {
        onFilter(
          {
            ...selectedFilters,
            facilityMap: data.facilityMaps[0]
          },
          true
        );
      } else if (selectedFilters?.map && !selectedFilters?.facilityMap) {
        onFilter(
          {
            ...selectedFilters,
            facilityMap: data.facilityMaps.find(
              fm => fm.id === selectedFilters.map
            )
          },
          true
        );
      }
    }
    if (selectedFilters) {
      const activeFields = [...(selectedFilters?.conditions || [])].some(
        c =>
          c.rules.length &&
          c.rules.some(
            r =>
              (r.hasOwnProperty('value') &&
                (r as FilterRuleView).value !== 'all') ||
              (r.hasOwnProperty('condition') &&
                (r as FilterConditionView).rules.length > 0)
          )
      );
      setHasFilters(activeFields);
    }
  }, [data, maps, selectedFilters]);

  const searchPlans = async (input: string): Promise<FilterOption[]> => {
    const plans = await findPlans({
      clientId: clientId!,
      locationId: locationId!,
      search: input
    });
    return (
      plans.map(plan => ({
        label: plan.name,
        value: plan.id
      })) ?? []
    );
  };

  const updateConditions = (condition: FilterConditionView) => {
    const newFilters = {
      ...selectedFilters,
      conditions: [condition]
    };
    onFilter(newFilters, true);
  };

  const resetFilters = () => {
    onFilter(
      {
        ...selectedFilters,
        conditions: []
      },
      true
    );
  };

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <>
      {!permanent && (
        <Button
          onClick={() => setCollapsed(!collapsed)}
          themeType="outline"
          theme="secondary"
        >
          <MdTune className={styles.toggleBtnIcon} />
          {buttonTitle ?? 'Filters'}
        </Button>
      )}
      <Collapse collapsed={collapsed}>
        <>
          {selectedFilters?.advanced ? (
            <>
              <Grid>
                <LayoutFilters
                  selectedFilters={selectedFilters}
                  onFilter={onFilter}
                  filtersData={data}
                  rolling={rolling}
                  includeCharts={includeCharts}
                  includeCustomCharts={includeCustomCharts}
                  disableDateRange={disableDateRange}
                  surfacePlans={surfacePlans}
                  reportTypes={reportTypes}
                  includeUntestedToggle={includeUntestedToggle}
                  maps={maps}
                />
              </Grid>
              <AdvancedFilter
                filtersData={data}
                onFilter={updateConditions}
                childLevel={1}
                selectedCondition={selectedFilters?.conditions?.[0]}
                surfacePlans={surfacePlans}
                nonConformances={nonConformances}
              />
            </>
          ) : (
            <Grid>
              <BasicFilters
                filtersData={data}
                onFilter={onFilter}
                selectedFilters={selectedFilters}
                rolling={rolling}
                includeCharts={includeCharts}
                includeCustomCharts={includeCustomCharts}
                disableDateRange={disableDateRange}
                surfacePlans={surfacePlans}
                reportTypes={reportTypes}
                nonConformances={nonConformances}
                maps={maps}
                permanent={permanent}
                includeUntestedToggle={includeUntestedToggle}
                searchPlans={searchPlans}
              />
            </Grid>
          )}
          {canUseBasicFilters && (
            <Button
              themeType="outline"
              theme="secondary"
              onClick={() => {
                onFilter(
                  {
                    ...selectedFilters,
                    advanced: selectedFilters?.advanced ? false : true
                  },
                  true
                );
              }}
            >
              {selectedFilters?.advanced ? 'Basic' : 'Advanced'}
            </Button>
          )}
          {hasFilters && (
            <div className="grid">
              <Button
                onClick={() => resetFilters()}
                theme="secondary"
                className="justify-self-end"
              >
                Clear Filters
              </Button>
            </div>
          )}
        </>
      </Collapse>
    </>
  );
};
