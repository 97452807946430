import * as filestack from 'filestack-js';
import { useEffect, useState } from 'react';
import { useStorageState } from 'react-storage-hooks';
import fetchJson from 'utils/fetchJson';
import fromUnixTime from 'date-fns/fromUnixTime';
import isBefore from 'date-fns/isBefore';

type Security = {
  policy: string;
  signature: string;
  expiry: number;
};

export interface FilestackImage {
  name: string;
  status: string;
  type: string;
  size: number;
  url: string;
  handle: string;
}

type UseFilestackT = {
  policy?: string;
  signature?: string;
  getImageUrl: (
    handle: string,
    size: string,
    mimeType: string
  ) => Promise<string>;
  getFileUrl: (handle: string) => Promise<string>;
  deleteImage: (url: string) => Promise<boolean>;
  client: filestack.Client;
  isSignatureValid: boolean;
  uploadImageFromDataUri: (dataUri: string) => Promise<FilestackImage | string>;
};

const dummyStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {}
};

const isExpired = expiry => {
  return isBefore(fromUnixTime(expiry), new Date());
};

export const parseOldFilestackUrl = url => {
  const parts = url.split('/');
  return parts.pop();
};

export const useFilestack = (): UseFilestackT => {
  const isSsr = typeof window === 'undefined';
  const apiKey = process.env.NEXT_PUBLIC_FILEPICKER_APIKEY || '';
  const client = filestack.init(apiKey);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [signPolicy, setSignPolicy] = useStorageState<Security | undefined>(
    isSsr ? dummyStorage : localStorage,
    'fs-sign',
    undefined
  );
  const fetchSignature = async () => {
    if (loading) return;
    setLoading(true);
    const result = await fetchJson('/api/signature', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    setSignPolicy(result);
    setLoading(false);
  };
  useEffect(() => {
    if (!loading && !signPolicy) {
      setIsValid(false);
      fetchSignature();
    }
    if (signPolicy) {
      const { expiry } = signPolicy;
      if (!expiry || isExpired(expiry)) {
        setIsValid(false);
        fetchSignature();
      } else {
        setIsValid(true);
      }
    }
  }, [signPolicy, loading]);

  const getImageUrl = async (
    handle: string,
    size: string,
    mimeType: string
  ): Promise<string> => {
    if (!signPolicy) return 'policy expired';
    const { policy, signature, expiry } = signPolicy;
    if (!expiry || isExpired(expiry)) {
      await fetchSignature();
    }
    if (mimeType === 'image/svg+xml') {
      return `https://cdn.filestackcontent.com/security=p:${policy},s:${signature}/${handle}`;
    }
    if (size === 'original') {
      return `https://cdn.filestackcontent.com/security=p:${policy},s:${signature}/${handle}`;
    }
    return `https://cdn.filestackcontent.com/security=p:${policy},s:${signature}/resize=fit:max,${size}/${handle}`;
  };

  const getFileUrl = async (handle: string): Promise<string> => {
    if (!signPolicy) return 'policy expired';
    const { policy, signature, expiry } = signPolicy;
    if (!expiry || isExpired(expiry)) {
      await fetchSignature();
    }
    return `https://cdn.filestackcontent.com/${handle}?policy=${policy}&signature=${signature}`;
  };

  const deleteImage = async (url: string): Promise<boolean> => {
    if (!signPolicy) return false;
    const { policy, signature, expiry } = signPolicy;
    if (!expiry || isExpired(expiry)) {
      await fetchSignature();
    }
    const handle = url.slice(-20);
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'image/jpeg' }
    };

    try {
      await fetch(
        `https://www.filestackapi.com/api/file/${handle}?key=${apiKey}&policy=${policy}&signature=${signature}`,
        requestOptions
      );
      return true;
    } catch (err) {
      return false;
    }
  };

  const uploadImageFromDataUri = async (
    dataUri: string
  ): Promise<FilestackImage | string> => {
    if (!signPolicy) return 'policy expired';
    const { policy, signature, expiry } = signPolicy;
    if (!expiry || isExpired(expiry)) {
      await fetchSignature();
    }
    const fileRes = await client.upload(
      dataUri,
      {},
      {},
      {},
      { policy, signature }
    );
    return fileRes;
  };

  return {
    policy: signPolicy?.policy,
    signature: signPolicy?.signature,
    getImageUrl,
    getFileUrl,
    deleteImage,
    uploadImageFromDataUri,
    client,
    isSignatureValid: isValid
  };
};
