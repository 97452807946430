import { Button } from '@react-md/button';
import { Container, OutlineButton } from 'components';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { MdClose } from 'react-icons/md';
import { Typography } from '@react-md/typography';
import Parser from 'html-react-parser';

import React, { ReactElement } from 'react';
import styles from './confirmationModal.module.scss';

interface ConfirmationModalProps {
  title: string;
  copy: string;
  visible: boolean;
  confirmCallback: () => void;
  onRequestClose: () => void;
  cancelCallback?: () => void;
  cancelText?: string;
  confirmText?: string;
}

export function ConfirmationModal(props: ConfirmationModalProps): ReactElement {
  const {
    title,
    copy,
    confirmCallback,
    visible,
    onRequestClose,
    cancelText,
    confirmText,
    cancelCallback
  } = props;

  return (
    <Container>
      <Dialog
        id="confirmation-modal"
        role="alertdialog"
        modal={true}
        visible={visible}
        onRequestClose={cancelCallback ? cancelCallback : onRequestClose}
        aria-labelledby="dialog-title"
      >
        <DialogContent className={styles.dialogHeader}>
          <Typography className={styles.dialogTitle} type="headline-5">
            {title}
          </Typography>
          {cancelCallback ? (
            <MdClose className={styles.closeIcon} onClick={onRequestClose} />
          ) : null}
        </DialogContent>
        <DialogContent>
          <Typography>{Parser(copy)}</Typography>
        </DialogContent>
        <DialogFooter>
          <Button
            style={{ marginRight: '5px' }}
            onClick={cancelCallback ? cancelCallback : onRequestClose}
          >
            {cancelText ? cancelText : 'Cancel'}
          </Button>
          <OutlineButton
            ariaLabel="Yes"
            theme="error"
            onClick={() => confirmCallback()}
          >
            {confirmText ? confirmText : 'Yes'}
          </OutlineButton>
        </DialogFooter>
      </Dialog>
    </Container>
  );
}

export default ConfirmationModal;
