import { ErrorFontIcon } from '@react-md/material-icons';
import { TextIconSpacing } from '@react-md/icon';
import { ToastMessage } from '@react-md/alert';
import styles from './alerts.module.scss';

export const ErrorToastMessage = (message: string): ToastMessage => {
  const ErrorBody = (
    <TextIconSpacing
      icon={<ErrorFontIcon color={'#ffffff'} />}
      className={styles.errorMessage}
    >
      {message}
    </TextIconSpacing>
  );
  return {
    messagePriority: 'immediate',
    className: styles.errorMessage,
    children: ErrorBody
  };
};
