import { Controller } from 'react-hook-form';
import { TextFieldWithMessage } from '@react-md/form';
import React from 'react';

export function ControllerTextField({ id, name, message, label, ...props }) {
  const { error } = props;
  const messageId = `${id}-message`;
  return (
    <Controller
      {...props}
      name={name}
      render={({ field }) => (
        <TextFieldWithMessage
          id={id}
          type="text"
          aria-label={label}
          {...field}
          messageProps={{
            id: messageId,
            error,
            children: message
          }}
        />
      )}
      aria-describedby={messageId}
      defaultValue=""
    />
  );
}
