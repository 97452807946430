import {
  AssignmentSVGIcon,
  BlurLinearSVGIcon,
  BugReportSVGIcon,
  BurstModeSVGIcon,
  CenterFocusStrongSVGIcon,
  ClearAllSVGIcon,
  DehazeSVGIcon,
  DetailsSVGIcon,
  DvrSVGIcon,
  FilterListSVGIcon,
  LocationCitySVGIcon,
  LocationSearchingSVGIcon,
  TextureSVGIcon
} from '@react-md/material-icons';
import {
  CategoryFilterOption,
  FiltersResponse,
  MapFilterOption,
  OrganismFilterOption,
  TestFilterOption
} from 'modules/shared/UseCases/GetFilters/types';
import { Container } from 'components/Layout';
import { ExploreSVGIcon, GridCell, Label, Switch, TextField } from 'react-md';
import { FIELDS } from 'modules/reports/domain/FilterRuleFieldValue';
import {
  Filter,
  FilterDateRange,
  FilterOption,
  SelectedFiltersType
} from './FilterTypes';
import {
  FilterConditionView,
  FilterRuleValue,
  FilterRuleView
} from 'modules/reports/domain/ReportView';
import { FilterSelect } from './FilterSelect';
import { ReportDateRange } from './ReportDateRange';
import { TYPES } from 'modules/reports/domain/ReportDateRangeType';
import { filterListByParent, getConditionByField } from 'utils/reportFilters';
import { flatten } from 'utils/array';
import { useEffect, useState } from 'react';
import styles from './filter-drawer.module.scss';

const buildOptions = (
  data: any[],
  labelKey: string,
  includeAll: boolean = true,
  secondaryField?: string
): FilterOption[] => {
  if (!data || !data.length) {
    return includeAll ? [{ label: 'All', value: 'all' }] : [];
  }

  return (includeAll ? [{ label: 'All', value: 'all' }] : []).concat(
    data.map(result => {
      return {
        label: secondaryField ? (
          <>
            {result[labelKey]}&nbsp;
            <span className="block text-xs">{result[secondaryField]}</span>
          </>
        ) : (
          result[labelKey]
        ),
        value: result.id,
        searchable: secondaryField
          ? `${result[labelKey]} ${result[secondaryField]}`.toLowerCase()
          : result[labelKey].toLowerCase()
      };
    })
  );
};

const findTests = (
  tests: TestFilterOption[],
  categoryIds?: string[],
  organismIds?: string[]
): TestFilterOption[] => {
  const filteredTests = [...tests].filter(test => {
    if (categoryIds && categoryIds.length > 0) {
      if (organismIds && organismIds.length > 0) {
        return (
          organismIds.includes(test.organismId) &&
          categoryIds.includes(test.categoryId)
        );
      } else {
        return categoryIds.includes(test.categoryId);
      }
    }

    if (organismIds && organismIds.length > 0) {
      return organismIds.includes(test.organismId);
    }

    return true;
  });
  return filteredTests;
};

const filterOrganisms = (
  categories: CategoryFilterOption[],
  selectedCategories: string[] | undefined,
  organisms: OrganismFilterOption[]
) => {
  if (!selectedCategories || selectedCategories.length === 0) {
    return organisms;
  }
  const filteredOrganismIds: string[] = flatten(
    categories
      .filter(c => selectedCategories.includes(c.id))
      .map(c => c.organisms)
  );
  return organisms.filter(o => filteredOrganismIds.includes(o.id));
};

export interface BasicFilterProps {
  onFilter: (selectedFilters: SelectedFiltersType, isChanged: boolean) => void;
  selectedFilters?: SelectedFiltersType;
  rolling?: boolean;
  maps?: boolean;
  includeCharts?: boolean;
  includeCustomCharts?: boolean;
  filtersData: FiltersResponse;
  disableDateRange?: boolean;
  nonConformances?: boolean;
  reportTypes?: FilterOption[];
  surfacePlans?: boolean;
  permanent?: boolean;
  includeUntestedToggle?: boolean;
  searchPlans: (input: string) => Promise<FilterOption[]>;
}
export const BasicFilters = (props: BasicFilterProps) => {
  const {
    filtersData,
    onFilter,
    selectedFilters,
    maps,
    rolling,
    includeCharts,
    reportTypes,
    includeCustomCharts,
    disableDateRange,
    nonConformances,
    surfacePlans,
    permanent,
    includeUntestedToggle
  } = props;

  const [tolerance, setTolerance] = useState<number>(
    selectedFilters?.dailyFailureTolerance ?? 100
  );
  const [filteredTestDefinitions, setFilteredTestDefinitions] = useState<
    TestFilterOption[]
  >([]);

  const [selectedFacilityMap, setSelectedFacilityMap] =
    useState<MapFilterOption>();
  const [filteredDateRangeTypes, setFilteredDateRangeTypes] =
    useState<string[]>(TYPES);

  useEffect(() => {
    if (!filtersData) return;
    if (selectedFilters?.map) {
      const selectedMap = filtersData.facilityMaps?.find(
        m => m.id === selectedFilters.map
      );
      if (selectedMap && !selectedFacilityMap) {
        setSelectedFacilityMap(selectedMap);
        onFilter(
          {
            ...selectedFilters,
            facilityMap: selectedMap,
            map: selectedMap.id
          },
          false
        );
      }
    }
    const selectedTestCondition = getConditionByField(
      selectedFilters?.conditions ?? [],
      'testDefinition'
    );
    if (selectedTestCondition) {
      const selectedTests = filtersData.testDefinitions?.filter(t =>
        (selectedTestCondition as string[]).includes(t.id)
      );
      setFilteredTestDefinitions(selectedTests || []);
    }
  }, []);

  useEffect(() => {
    if (!filtersData) return;

    if (
      selectedFilters?.map &&
      selectedFacilityMap &&
      selectedFilters.map !== selectedFacilityMap?.id
    ) {
      const selectedMap = filtersData.facilityMaps?.find(
        m => m.id === selectedFilters.map
      );
      if (selectedMap) {
        setSelectedFacilityMap(selectedMap);
        onFilter(
          {
            ...selectedFilters,
            facilityMap: selectedMap,
            map: selectedMap.id
          },
          false
        );
      }
      if (selectedFilters.chartType === 'custom') {
        setFilteredDateRangeTypes(['day', 'week', 'month']);
        onFilter(
          {
            ...selectedFilters,
            dateRange: {
              type: 'month'
            }
          },
          false
        );
      }
    }
    const selectedCategoryCondition = getConditionByField<string[]>(
      selectedFilters?.conditions ?? [],
      'category'
    );
    const selectedOrganismCondition = getConditionByField<string[]>(
      selectedFilters?.conditions ?? [],
      'organism'
    );
    if (selectedCategoryCondition) {
      if (selectedOrganismCondition) {
        setFilteredTestDefinitions(
          findTests(
            filtersData.testDefinitions,
            selectedCategoryCondition,
            selectedOrganismCondition
          )
        );
      } else {
        setFilteredTestDefinitions(
          findTests(filtersData.testDefinitions, selectedCategoryCondition)
        );
      }
    } else if (selectedOrganismCondition) {
      setFilteredTestDefinitions(
        findTests(
          filtersData.testDefinitions,
          undefined,
          selectedOrganismCondition
        )
      );
    } else {
      setFilteredTestDefinitions(filtersData.testDefinitions || []);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (!filtersData) return;
    if (maps && filtersData?.facilityMaps?.length) {
      let tour;
      if (filtersData.tours?.length && !selectedFilters?.tour) {
        tour = filtersData.tours[0].id;
      }
      let selectedMap;
      if (selectedFilters?.map) {
        selectedMap = filtersData.facilityMaps?.find(
          m => m.id === selectedFilters?.map
        );
      } else {
        selectedMap = filtersData.facilityMaps[0];
      }

      if (
        selectedMap &&
        (!selectedFilters?.map ||
          !selectedFacilityMap ||
          (selectedMap && selectedFacilityMap !== selectedMap))
      ) {
        setSelectedFacilityMap(selectedMap);
        onFilter(
          {
            ...selectedFilters,
            facilityMap: selectedMap,
            map: selectedMap.id,
            tour
          },
          false
        );
      } else if (!selectedMap) {
        setSelectedFacilityMap(undefined);
        onFilter(
          {
            ...selectedFilters,
            facilityMap: undefined,
            map: '',
            tour
          },
          false
        );
      }
    } else {
      setSelectedFacilityMap(undefined);
      onFilter(
        {
          ...selectedFilters,
          facilityMap: undefined,
          map: ''
        },
        false
      );
    }
  }, [filtersData, maps]);

  const { categories, zones, plans, surfaceTypes, facilityMaps } = filtersData;
  const handleInputChange = (name, value: number) => {
    const updated = {
      ...selectedFilters,
      [name]: value
    };
    onFilter(updated, true);
  };

  const handleFilter = (
    name: string,
    value: string[] | FilterDateRange | boolean | number
  ) => {
    if (name === 'map') {
      const selectedMap = filtersData?.facilityMaps?.find(
        m => m.id === String(value)
      );
      if (selectedMap) {
        setSelectedFacilityMap(selectedMap);
      }
      onFilter(
        {
          ...selectedFilters,
          facilityMap: selectedMap,
          //@ts-ignore
          facilityMapId: String(value),
          name: selectedMap?.title,
          map: String(value)
        },
        true
      );
      return;
    }
    if (!FIELDS.includes(name)) {
      onFilter(
        {
          ...selectedFilters,
          [name]: value
        },
        true
      );
      return;
    }
    const field = availableFilters.find(f => f?.name === name);
    const isMulti = field?.multiple ?? false;
    let conditions = [...(selectedFilters?.conditions ?? [])];
    const firstCondition: FilterConditionView =
      conditions.length > 0 ? conditions[0] : { condition: 'and', rules: [] };
    const existingRule: FilterRuleView | undefined = firstCondition.rules.find(
      r => (r as FilterRuleView).field === name
    ) as FilterRuleView;
    if (existingRule) {
      existingRule.value = isMulti
        ? (value as string[])
        : (value as FilterRuleValue);
      if (
        (isMulti && (existingRule.value as string[]).length > 0) ||
        (!isMulti && existingRule.value)
      ) {
        firstCondition.rules = firstCondition.rules.map(r => {
          if ((r as FilterRuleView).field === name) {
            return existingRule;
          }
          return r;
        });
      } else {
        firstCondition.rules = firstCondition.rules.filter(
          r => (r as FilterRuleView).field !== name
        );
      }
    } else {
      if ((isMulti && (value as string[]).length > 0) || (!isMulti && value)) {
        firstCondition.rules.push({
          field: name,
          operator: isMulti ? 'in' : 'equal-to',
          value: value as FilterRuleValue
        });
      }
    }

    const updated = {
      ...selectedFilters,
      conditions: [firstCondition]
    };
    if (name === 'chartType') {
      if (typeof value === 'string' && value === 'custom') {
        setFilteredDateRangeTypes(['day', 'week', 'month']);
        updated['dateRange'] = {
          type: 'month'
        };
      } else {
        setFilteredDateRangeTypes(TYPES);
      }
    }
    if (name === 'category' || name === 'organism') {
      updated.conditions = updated.conditions.map(c => ({
        ...c,
        rules: c.rules.filter(
          r => (r as FilterRuleView).field !== 'testDefinition'
        )
      }));
    }
    if (name === 'zone') {
      updated.conditions = updated.conditions.map(c => ({
        ...c,
        rules: c.rules.filter(r => (r as FilterRuleView).field !== 'site')
      }));
    }
    onFilter(updated, true);
  };

  const availableFilters = [
    {
      name: 'category',
      label: 'Category',
      multiple: true,
      options: buildOptions(categories || [], 'name', false),
      value: getConditionByField(selectedFilters?.conditions ?? [], 'category'),
      icon: FilterListSVGIcon
    },
    {
      name: 'organism',
      label: 'Subcategory',
      multiple: true,
      options: buildOptions(
        filterOrganisms(
          categories || [],
          getConditionByField(selectedFilters?.conditions ?? [], 'category'),
          filtersData.organisms
        ),

        'name',
        false
      ),
      value: getConditionByField(selectedFilters?.conditions ?? [], 'organism'),
      icon: BugReportSVGIcon
    },
    {
      name: 'testDefinition',
      label: 'Test',
      multiple: true,
      options: buildOptions(
        filteredTestDefinitions || [],
        'subTest',
        false,
        'categoryOrganism'
      ),
      value: getConditionByField(
        selectedFilters?.conditions ?? [],
        'testDefinition'
      ),
      icon: LocationSearchingSVGIcon
    },
    {
      name: 'zone',
      label: 'Zone',
      multiple: true,
      options: buildOptions(zones || [], 'name', false),
      value: getConditionByField(selectedFilters?.conditions ?? [], 'zone'),
      icon: DetailsSVGIcon
    },
    {
      name: 'site',
      label: 'Site',
      multiple: true,
      options: buildOptions(
        filterListByParent(
          filtersData.sites,
          'zoneId',
          getConditionByField(selectedFilters?.conditions ?? [], 'zone') as
            | string[]
            | undefined
        ) || [],
        'zoneCode',
        false,
        'description'
      ),
      value: getConditionByField(selectedFilters?.conditions ?? [], 'site'),
      icon: DetailsSVGIcon
    },
    ...(maps && (selectedFilters?.reportType !== 'tour' || !filtersData.tours)
      ? [
          {
            name: 'map',
            label: 'Map',
            multiple: false,
            options: buildOptions(facilityMaps || [], 'title', false),
            value: selectedFilters?.map || 'all',
            icon: LocationCitySVGIcon
          }
        ]
      : []),
    ...(selectedFilters?.reportType === 'tour' &&
    filtersData.tours &&
    filtersData.tours.length
      ? [
          {
            name: 'tour',
            label: 'Tour',
            multiple: false,
            options: buildOptions(filtersData.tours || [], 'title', false),
            value: selectedFilters?.tour,
            icon: ExploreSVGIcon
          }
        ]
      : []),
    ...(surfacePlans
      ? [
          {
            name: 'planId',
            label: 'Plan',
            multiple: true,
            options: buildOptions(plans || [], 'name', false),
            value: getConditionByField(
              selectedFilters?.conditions ?? [],
              'planId'
            ),
            icon: DvrSVGIcon
          },
          {
            name: 'surfaceTypeId',
            label: 'Surface',
            multiple: true,
            options: buildOptions(surfaceTypes || [], 'name', false),
            value: getConditionByField(
              selectedFilters?.conditions ?? [],
              'surfaceTypeId'
            ),
            icon: TextureSVGIcon
          }
        ]
      : []),
    ...(!disableDateRange
      ? [
          {
            label: 'Date Range',
            name: 'dateRange',
            multiple: false,
            options: filteredDateRangeTypes,
            value: selectedFilters?.dateRange,
            icon: DehazeSVGIcon
          }
        ]
      : []),
    rolling && selectedFilters?.dataType !== 'rolling'
      ? {
          name: 'interval',
          label: 'Interval',
          multiple: false,
          options: [
            {
              label: 'Day',
              value: 'day'
            },
            {
              label: 'Week',
              value: 'week'
            },
            {
              label: 'Month',
              value: 'month'
            },
            {
              label: 'Quarter',
              value: 'quarter'
            },
            {
              label: 'Year',
              value: 'year'
            }
          ],
          value: selectedFilters?.interval || 'day',
          icon: DehazeSVGIcon
        }
      : null,
    rolling
      ? {
          name: 'dataType',
          label: 'Data Type',
          multiple: false,
          options: [
            {
              label: 'Rolling',
              value: 'rolling'
            },
            {
              label: 'Calendar',
              value: 'calendar'
            }
          ],
          value: selectedFilters?.dataType || 'rolling',
          icon: AssignmentSVGIcon
        }
      : null,
    rolling &&
    (selectedFilters?.dataType === 'rolling' || !selectedFilters?.dataType)
      ? {
          name: 'rollingInterval',
          label: 'Rolling Interval',
          multiple: false,
          options: [
            {
              label: '7 Day',
              value: 'sevenDay'
            },
            {
              label: '30 Day',
              value: 'thirtyDay'
            },
            {
              label: '90 Day',
              value: 'ninetyDay'
            }
          ],
          value: selectedFilters?.rollingInterval || 'sevendDay',
          icon: ClearAllSVGIcon
        }
      : null,
    includeCharts
      ? {
          name: 'chartType',
          label: 'Chart Type',
          multiple: false,
          options: [
            {
              label: 'Line',
              value: 'line'
            },
            {
              label: 'Bar',
              value: 'bar'
            },
            ...(includeCustomCharts
              ? [
                  {
                    label: 'Custom',
                    value: 'custom'
                  }
                ]
              : [])
          ],
          value: selectedFilters?.chartType || 'line',
          icon: BurstModeSVGIcon
        }
      : null,
    ...(!nonConformances
      ? [
          {
            name: 'resultRating',
            label: 'Result Type',
            multiple: false,
            options: [
              {
                label: 'All',
                value: 'all'
              },
              {
                label: 'Conformance',
                value: 'conformance'
              },
              {
                label: 'Nonconformance',
                value: 'nonconformance'
              }
            ],
            value:
              getConditionByField(
                selectedFilters?.conditions ?? [],
                'resultRating'
              ) ?? 'all',
            icon: BlurLinearSVGIcon
          }
        ]
      : []),
    reportTypes
      ? {
          name: 'reportType',
          label: 'Report Type',
          multiple: false,
          options: reportTypes,
          value: selectedFilters?.reportType || reportTypes[0],
          icon: CenterFocusStrongSVGIcon
        }
      : null,
    ...(includeCharts && !includeCustomCharts
      ? [
          {
            label: 'Show Test Result Counts',
            name: 'showTestCounts',
            type: 'toggle',
            value: selectedFilters?.showTestCounts || false
          }
        ]
      : []),
    ...(selectedFilters?.chartType === 'custom' &&
    selectedFilters?.dateRange?.type === 'month'
      ? [
          {
            label: 'Daily Pass Rate Target %',
            name: 'dailyFailureTolerance',
            type: 'input',
            value: selectedFilters?.dailyFailureTolerance || '100'
          }
        ]
      : []),
    ...(maps
      ? [
          {
            label: 'Include Vector Sites',
            name: 'includeVectors',
            type: 'toggle',
            value: selectedFilters?.includeVectors || false
          }
        ]
      : []),
    ...(includeUntestedToggle
      ? [
          {
            label: 'Hide Untested Sites',
            name: 'hideUntested',
            type: 'toggle',
            value: selectedFilters?.hideUntested || false
          }
        ]
      : [])
  ].filter(f => f);

  return (
    <>
      {availableFilters.map((element, index) => {
        if (element?.type && element.type === 'toggle') {
          return (
            <GridCell key={index} colSpan={permanent ? 12 : 4}>
              <Switch
                key={index}
                id={element.name}
                name={element.name}
                label={element.label}
                defaultChecked={!!element.value}
                onChange={e => handleFilter(element?.name, e.target.checked)}
              />
            </GridCell>
          );
        } else if (element?.type && element.type === 'input') {
          return (
            <GridCell key={index} colSpan={permanent ? 12 : 4}>
              <TextField
                key={index}
                id={element.name}
                name={element.name}
                label={element.label}
                value={tolerance.toString()}
                type="number"
                onChange={e => setTolerance(parseFloat(e.target.value))}
                onBlur={e =>
                  handleInputChange(element?.name, parseFloat(e.target.value))
                }
              />
            </GridCell>
          );
        } else if (element!.label !== 'Date Range') {
          return (
            <GridCell key={index} colSpan={permanent ? 12 : 4}>
              <FilterSelect
                index={index}
                multiple={element?.multiple ?? false}
                filter={element! as Filter}
                filterCallback={(name: string, value: any) =>
                  handleFilter(name, value)
                }
              />
            </GridCell>
          );
        } else {
          return (
            <GridCell colSpan={permanent ? 12 : 4} key={index}>
              <Container className={styles.filterContainer}>
                <Label className={styles.filterLabel} htmlFor={element!.name}>
                  {element!.label}
                </Label>
                {element?.icon ? (
                  <element.icon className={styles.filterIcon} />
                ) : null}
                <ReportDateRange
                  type={(element!.value as FilterDateRange)?.type}
                  start={(element!.value as FilterDateRange)?.start}
                  end={(element!.value as FilterDateRange)?.end}
                  onDatesChange={dateRange => {
                    handleFilter('dateRange', dateRange);
                  }}
                  options={element!.options}
                />
              </Container>
            </GridCell>
          );
        }
      })}
    </>
  );
};
