import { Card, CardActions, CardContent } from '@react-md/card';
import { FacilityMapView } from 'modules/facility-maps/domain/FacilityMapView';
import { MapActions } from 'components';
import React, { ReactElement } from 'react';

import { GridCell } from '@react-md/utils';
import { MapImage } from './MapImage';
import styles from './map.module.scss';

interface IMapProps {
  map: FacilityMapView;
  getFacilityMaps: () => void;
}
export function Map(props: IMapProps): ReactElement {
  const { map, getFacilityMaps } = props;
  const imageName =
    map.title.length > 15 ? map.title.substring(0, 15) + '...' : map.title;

  return (
    <GridCell
      largeDesktop={{ colSpan: 3 }}
      desktop={{ colSpan: 4 }}
      tablet={{ colSpan: 4 }}
      phone={{ colSpan: 12 }}
    >
      <Card className={styles.mapCard}>
        <CardContent className={styles.mapCardContent}>
          <a href={`/facility-maps/${map.id}`} className={styles.mapThumbnail}>
            <MapImage
              url={map.url!}
              name={map.title}
              width={150}
              height={150}
              isThumb={true}
              mimeType={map?.mimetype || 'image/jpeg'}
            />
          </a>
        </CardContent>
        <CardActions className={styles.mapCardActions}>
          <a href={`/facility-maps/${map.id}`}>{imageName}</a>
          <section>
            <MapActions map={map} small getFacilityMaps={getFacilityMaps} />
          </section>
        </CardActions>
      </Card>
    </GridCell>
  );
}
