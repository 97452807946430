import { BOTTOM_CENTER_ANCHOR } from '@react-md/utils';
import { DropdownMenu } from '@react-md/menu';
import { SimpleListItem } from '@react-md/list';
import React from 'react';

import styles from './dropDownMenu.module.scss';

export function DropdownList({ id, items }) {
  if (!items || !items.length || items[0] === '') {
    return <span />;
  }
  const dropdownId = `${id}-dropdownlist`;
  const simpleItems = items.map((item, i) => {
    if (item !== '') {
      return (
        <SimpleListItem key={i} className={styles.menu_item}>
          {item}
        </SimpleListItem>
      );
    }
  });
  return (
    <DropdownMenu
      id={dropdownId}
      anchor={BOTTOM_CENTER_ANCHOR}
      buttonChildren={
        items[0] && items[0].length > 20
          ? `${items[0].substr(0, 8)}...`
          : items[0]
      }
      menuLabel={`${items[0]}`}
      disableDropdownIcon={items.length === 1 ? true : false}
    >
      {simpleItems}
    </DropdownMenu>
  );
}
