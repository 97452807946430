import { FetchService } from './FetchService';
import { GetUserFiltersResponse } from 'modules/users/use-cases/GetUserFilters/types';
import { PaginateParamsT, SearchParamsT } from 'common/PaginationTypes';
import { UserPaginateResponse } from 'modules/users/repos/UserRepo';
import { UserPreferences } from 'modules/users/types';
import { UserView } from 'modules/users/domain/UserView';
import fetchJson from 'utils/fetchJson';

export const paginateUsers = async (
  params: SearchParamsT & PaginateParamsT
): Promise<UserPaginateResponse> => {
  const { limit, offset, sort, query, ...rest } = params;
  const url = `/api/users?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    ...(sort && { sort: sort }),
    ...(query && { query: query }),
    ...rest
  })}`;
  const users = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return users;
};

export const paginateAssociateUsers = async (
  params: SearchParamsT & PaginateParamsT
): Promise<UserPaginateResponse> => {
  const { limit, offset, sort, query, ...rest } = params;
  const url = `/api/associate-users?${new URLSearchParams({
    limit: limit ? limit.toString() : '10',
    offset: offset ? offset.toString() : '0',
    ...(sort && { sort: sort }),
    ...(query && { query: query }),
    ...rest
  })}`;
  const users = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return users;
};

export const getUserById = async (id: string): Promise<UserView> => {
  const users = await fetchJson(`/api/users/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return users;
};

export const createUser = async (user): Promise<string> => {
  const userId = await fetchJson(
    '/api/users',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...user
      })
    },
    'User'
  );
  return userId;
};

export const updateUser = async (
  user,
  isProfile = false,
  updateStatus = false
): Promise<UserView> => {
  const updatedUser = await fetchJson(
    `/api/users/${user.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...user,
        updateStatus
      })
    },
    isProfile ? 'Profile' : 'User'
  );
  return updatedUser;
};

export const deleteUser = async (id: string): Promise<void> => {
  const removed = await fetchJson(`/api/users/${id}`, {
    method: 'DELETE'
  });
  return removed;
};

export const updateDashboardWidgets = async (reportIds: string[]) => {
  const url = `/api/users/update-dashboard`;
  const saved = await fetchJson(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reportIds })
    },
    'Dashboard'
  );
  return saved;
};

export const getUserPreferences = () =>
  FetchService<UserPreferences, void>('GET', `/api/users/preferences/`);

export const saveUserPreferences = (request: UserPreferences) =>
  FetchService<boolean, UserPreferences>(
    'PUT',
    `/api/users/preferences/`,
    request
  );

export const getUserFilters = (url: string) =>
  FetchService<void, GetUserFiltersResponse>('GET', url);
