import { OffsetT } from 'components/FacilityMaps/types';
import { useEffect, useState } from 'react';

export const useMapImage = () => {
  const [imgRef, setImgRef] = useState<HTMLImageElement>();
  const [ratio, setRatio] = useState<number>(0);
  const [imgPos, setImgPosition] = useState([0, 0]);
  const [zoom, setZoom] = useState<number>(1);
  const [offsets, setOffsets] = useState<OffsetT>({ left: 0, top: 0 });

  const findPosition = oElement => {
    if (!oElement) return [0, 0];

    if (oElement.offsetParent && typeof oElement.offsetParent != 'undefined') {
      for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
      return [posX, posY];
    } else {
      return [oElement.x, oElement.y];
    }
  };

  useEffect(() => {
    if (imgRef) {
      setImgPosition(findPosition(imgRef));
    }
  }, [imgRef]);

  return {
    ratio,
    setRatio,
    imgRef,
    setImgRef,
    findPosition,
    imgPos,
    setImgPosition,
    zoom,
    setZoom,
    setOffsets,
    offsets
  };
};
