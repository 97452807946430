import * as yup from 'yup';

export const CreateSiteSchema = yup
  .object({
    location: yup.string().required(),
    zone: yup.string().required(),
    siteId: yup
      .string()
      .matches(/^[A-Za-z0-9_\-\d\s.]+$/, 'Cannot contain special characters')
      .required('Must enter a Site Id'),
    description: yup
      .string()
      .matches(/^[A-Za-z0-9_\-\d\s.]+$/, 'Cannot contain special characters')
      // .test('len', 'Max 31 characters', (val?: string) =>
      //   Boolean(val && val.length && val.length <= 31)
      // )
      .required(),
    isComposite: yup.boolean().required(),
    vectorId: yup.string().notRequired(),
    limit: yup.number().notRequired(),
    parentSite: yup.string().notRequired()
  })
  .required();

export const UpdateSiteSchema = yup
  .object({
    location: yup.string().required(),
    zone: yup.string().notRequired(),
    siteId: yup
      .string()
      .matches(/^[A-Za-z0-9_\-\d\s.]+$/, 'Cannot contain special characters')
      .notRequired(),
    description: yup
      .string()
      .matches(/^[A-Za-z0-9_\-\d\s.]+$/, 'Cannot contain special characters')
      // .test('len', 'Max 31 characters', (val: string) => val.length <= 31)
      .notRequired(),
    isComposite: yup.boolean().notRequired(),
    vectorId: yup.string().notRequired(),
    limit: yup.number().notRequired(),
    parentSite: yup.string().notRequired()
  })
  .required();

export const ZoneSchema = yup
  .object({
    name: yup.string().required()
  })
  .required();

export const CreateFieldMappingSchema = yup.object({
  locationId: yup.string().required(),
  mapping: yup.object().required()
});

export const ImportSitesFormSchema = yup
  .object({
    location: yup.string().required(),
    sites: yup
      .array(
        yup.object({
          location: yup.string().notRequired(),
          zoneId: yup.string().required(),
          siteId: yup.string().required(),
          description: yup.string().required(),
          isComposite: yup.boolean().required(),
          vectorId: yup.string().notRequired()
        })
      )
      .required()
  })
  .required();

export const CreateSiteGroupSchema = yup
  .object({
    location: yup.string().notRequired(),
    name: yup.string().required(),
    sites: yup.array().of(yup.string()).required()
  })
  .required();

export const UpdateSiteGroupSchema = yup
  .object({
    location: yup.string().notRequired(),
    name: yup.string().notRequired(),
    sites: yup.array().of(yup.string()).notRequired()
  })
  .required();
