import { Badge } from '@react-md/badge';
import { Button } from '@react-md/button';
import { DropdownMenu, MenuItem } from '@react-md/menu';
import {
  NewReleasesSVGIcon,
  NotificationsSVGIcon
} from '@react-md/material-icons';
import { UserNotificationView } from 'modules/notifications/domain/UserNotificationView';
import {
  addSubscription,
  getNotifications,
  markRead
} from 'services/notifications';
import { useActionClassName } from '@react-md/app-bar';
import { useRouter } from 'next/router';
import { useUser } from 'hooks/useUser';
import React, { useEffect } from 'react';
import styles from './nav.module.scss';
import useService from 'hooks/useService';
const base64ToUint8Array = base64 => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export interface NotificationMenuProps {
  showChangesPanel?: boolean;
  setShowChangesPanel?: (showChangesPanel: boolean) => void;
}

export const NotificationsMenuItem = (props: NotificationMenuProps) => {
  const router = useRouter();
  const { isLoggedIn } = useUser();

  const { data, mutate } = useService<UserNotificationView[]>(
    isLoggedIn ? `/api/notifications` : null,
    getNotifications
  );
  const unseenNotifications = data?.length !== 0;
  const askToSubscribe = async (registration: ServiceWorkerRegistration) => {
    try {
      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(
          process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY
        )
      });
      if (sub) {
        //save subscription to user
        await addSubscription(sub);
      }
    } catch (err) {
      // console.log('webpusherr', err);
    }
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      // @ts-ignore
      window.workbox !== undefined
    ) {
      // run only in browser
      navigator.serviceWorker.ready.then(reg => {
        if (reg.pushManager) {
          reg.pushManager.getSubscription().then(sub => {
            if (
              !sub ||
              //@ts-ignore
              (sub.expirationTime &&
                //@ts-ignore
                Date.now() > sub.expirationTime - 5 * 60 * 1000)
            ) {
              askToSubscribe(reg);
            } else {
              addSubscription(sub);
            }
          });
        }
      });
    }
  }, []);

  const readItem = async (item: UserNotificationView) => {
    await markRead([item.id]);
    mutate([...data!].filter(n => n.id !== item.id));
    if (item.reportId) router.push(`/reports/${item.reportId}`);
    else if (item.testResultId)
      router.push(`/test-results/${item.testResultId}`);
    else if (item.swabScheduleId) {
      router.push(`/sample-schedule/${item.swabScheduleId}`);
    }
    return;
  };

  const clearAllItems = async () => {
    const notificationIds = data?.map(n => n.id);
    await markRead(notificationIds ?? []);
    mutate([]);
    return;
  };

  const includeChangesPanel = props.setShowChangesPanel !== undefined;

  return (
    <div className={useActionClassName({ first: true })}>
      {includeChangesPanel && (
        <Button
          buttonType="icon"
          aria-label="changes"
          id="changes"
          className={styles.appBarAction}
          onClick={() =>
            props.setShowChangesPanel
              ? props.setShowChangesPanel(!props.showChangesPanel)
              : () => {}
          }
        >
          <NewReleasesSVGIcon />
        </Button>
      )}
      <DropdownMenu
        id="notification-menu"
        aria-label="Notifications..."
        className={styles.appBarAction}
        disableDropdownIcon={true}
        buttonType={'icon'}
        buttonChildren={
          <>
            <NotificationsSVGIcon />
            <Badge
              id="notification-badge"
              className={styles.appBarBadge}
              disableNullOnZero={false}
            >
              {data?.length}
            </Badge>
          </>
        }
      >
        {unseenNotifications && data ? (
          <>
            <MenuItem
              key={`clear-all-notifications`}
              style={{ textAlign: 'right' }}
              onClick={() => clearAllItems()}
            >
              Clear All
            </MenuItem>
            {data.map((item, i) => (
              <MenuItem key={`notify-item-${i}`} onClick={() => readItem(item)}>
                {item.subject}
              </MenuItem>
            ))}
          </>
        ) : (
          <MenuItem key="no-items">You have no new notifications</MenuItem>
        )}
      </DropdownMenu>
    </div>
  );
};
