import { ConfirmationModal } from 'components';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Router from 'next/router';

interface UseWarningOnExitProps {
  shouldWarn: boolean;
}
let haveWarned = false;

export const UseWarningOnExit = (
  props: UseWarningOnExitProps
): ReactElement => {
  const { shouldWarn } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string>();
  const message =
    'You have unsaved changes that will be lost if you leave the page.';
  const lastHistoryState = useRef(global.history?.state);

  const storeLastHistoryState = () => {
    lastHistoryState.current = history.state;
  };

  const routeChangeStart = url => {
    if (Router.asPath !== url && shouldWarn && !visible && !haveWarned) {
      setVisible(true);
      setNextUrl(url);
      haveWarned = true;
      Router.events.emit('routeChangeError');
      // eslint-disable-next-line no-throw-literal
      throw 'Abort route change. Please ignore this error.';
    }
  };

  const confirmCallback = () => {
    if (nextUrl) {
      Router.events.off('routeChangeStart', routeChangeStart);
      setVisible(false);
      Router.push(nextUrl);
    }
  };

  const cancelCallback = () => {
    setVisible(false);
    haveWarned = false;
    // HACK
    const state = lastHistoryState.current;
    if (
      state != null &&
      history.state != null &&
      state.idx !== history.state.idx
    ) {
      history.go(state.idx < history.state.idx ? -1 : 1);
    }
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', storeLastHistoryState);
    return () => {
      Router.events.off('routeChangeComplete', storeLastHistoryState);
      haveWarned = false;
    };
  }, []);

  useEffect(() => {
    // const beforeUnload = e => {
    //   if (shouldWarn && !visible) {
    //     const event = e || window.event;
    //     event.returnValue = message;
    //     return message;
    //   }
    //   return null;
    // };
    if (shouldWarn) {
      Router.events.on('routeChangeStart', routeChangeStart);
      // window.addEventListener('beforeunload', beforeUnload);
    } else {
      Router.events.off('routeChangeStart', routeChangeStart);
      // window.removeEventListener('beforeunload', beforeUnload);
    }

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      // window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [shouldWarn]);
  return (
    <ConfirmationModal
      title="Are you sure you want to leave?"
      copy={message}
      confirmCallback={confirmCallback}
      visible={visible}
      onRequestClose={cancelCallback}
    />
  );
};
