import { AdvancedFilterRules, Filter } from './FilterTypes';
import { ArrowDropDownSVGIcon, TextField } from 'react-md';
import { FilterRuleView } from 'modules/reports/domain/ReportView';
import { MdClose, MdWarningAmber } from 'react-icons/md';
import { Tooltip } from 'components/Layout/Tooltip';
import { useState } from 'react';
import Select from 'react-select';

export type AdvancedFilterCellType = {
  condition: 'and' | 'or';
  rules: Array<AdvancedFilterRules>;
};

type AdvancedFilterCellProps = {
  validCellSelections: Array<any>;
  currentRule?: FilterRuleView;
  handleRemove: () => void;
  checkTriggerNonValid: () => boolean;
  onChange: (rule: FilterRuleView) => void;
};

const DropdownIndicator = () => {
  return <ArrowDropDownSVGIcon />;
};
const AdvancedFilterCell = (props: AdvancedFilterCellProps) => {
  const {
    validCellSelections,
    handleRemove,
    checkTriggerNonValid,
    currentRule
  } = props;
  const [selectedField, setSelectedField] = useState<Filter | undefined>(
    currentRule
      ? validCellSelections.find(field => field.name === currentRule.field)
      : undefined
  );

  const [values, setValues] = useState<FilterRuleView>(
    currentRule ?? {
      field: '',
      operator: '',
      value: ''
    }
  );

  const [warnForInvalidHover, setWarnForInvalidHover] = useState(false);

  const fieldChanged = (field: string, value: any) => {
    const newValues = { ...values, [field]: value };
    if (field === 'field') {
      newValues['operator'] = '';
      newValues['value'] = '';
    }
    setValues(newValues);
    triggerChange(newValues);
  };
  const triggerChange = (values: FilterRuleView) => {
    if (values.field === '' || values.operator === '' || values.value === '') {
      return;
    }
    props.onChange(values);
  };

  return (
    <div className="filter-group">
      <div className={'filter-cell md:flex flex-row gap-2 items-center'}>
        <Select
          name="field"
          className={'w-48'}
          placeholder="(select one)"
          value={{
            label: selectedField?.label,
            value: selectedField?.name
          }}
          onChange={val => {
            fieldChanged('field', val?.value);
            const field = validCellSelections.find(
              field => field.name === val?.value
            );
            setSelectedField(field);
            if (field.validComparisons?.length === 1) {
              fieldChanged('operator', field.validComparisons[0].value);
            }
          }}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              borderColor: 'rgb(148 163 184)'
            }),
            menu: baseStyles => ({
              ...baseStyles,
              zIndex: 10
            })
          }}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          options={validCellSelections.map(field => ({
            label: field.label,
            value: field.name
          }))}
        />

        <Select
          name="operator"
          className={'w-48'}
          placeholder={''}
          value={
            selectedField?.validComparisons?.find(
              opt => opt.value === values.operator
            ) ?? null
          }
          onChange={val => fieldChanged('operator', val?.value)}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              borderColor: 'rgb(148 163 184)'
            }),
            menu: baseStyles => ({
              ...baseStyles,
              zIndex: 10
            })
          }}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          options={selectedField?.validComparisons ?? []}
        />

        {selectedField?.fieldType === 'input' ? (
          <TextField
            name="value"
            className="h-10 border-slate-400 border rounded-md px-2 w-96"
            theme="none"
            onChange={e => {
              fieldChanged('value', e.target.value);
            }}
            id={'value'}
          />
        ) : (
          <Select
            name="value"
            className={'w-96'}
            placeholder=""
            value={
              selectedField?.multiple
                ? validCellSelections
                    .find(f => f.name === selectedField?.name)
                    ?.options?.filter(opt =>
                      ((values.value ?? []) as string[]).includes(opt.value)
                    )
                : validCellSelections
                    .find(f => f.name === selectedField?.name)
                    ?.options.find(v => v.value === values.value)
            }
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderColor: 'rgb(148 163 184)'
              }),
              menu: baseStyles => ({
                ...baseStyles,
                zIndex: 10
              })
            }}
            filterOption={(candidate, input) => {
              if (!input || input === '') return true;
              const opt = validCellSelections
                .find(f => f.name === selectedField?.name)
                ?.options?.find(o => o.value === candidate.value);

              if (!opt) return false;

              return opt.searchable
                ? opt.searchable.includes(input)
                : opt.label.includes(input);
            }}
            isSearchable={selectedField?.multiple}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            onChange={val => {
              fieldChanged(
                'value',
                selectedField?.multiple ? val.map(x => x.value) : val.value
              );
            }}
            options={
              validCellSelections.find(f => f.name === selectedField?.name)
                ?.options ?? []
            }
            isMulti={selectedField?.multiple}
          />
        )}
        <div
          onPointerOver={() => {
            if (checkTriggerNonValid()) {
              setWarnForInvalidHover(true);
            }
          }}
          onPointerLeave={() => setWarnForInvalidHover(false)}
          className={'w-6 h-6 mx-4 cursor-pointer'}
        >
          {warnForInvalidHover ? (
            <div className={`positon: absolute`}>
              <Tooltip text="Removing this condition will make the whole condition invalid.">
                <MdWarningAmber
                  onClick={handleRemove}
                  className={'w-6 h-6'}
                  color="orange"
                ></MdWarningAmber>
              </Tooltip>
            </div>
          ) : (
            <MdClose onClick={handleRemove} className={'w-6 h-6'}></MdClose>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilterCell;
