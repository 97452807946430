export const NON_AUTH_PAGES = [
  '/forgot',
  '/login',
  '/reset-password',
  '/subscribe',
  '/components'
];

export const ROLES_NEEDING_PERMISSION = [
  'user',
  'facility_admin',
  'customer_service',
  'restricted_user'
];
