import { PaginateParamsT, SearchParamsT } from 'common/PaginationTypes';
import { ZonePaginateResponse } from 'modules/sites/repos/ZoneRepo';
import { ZoneView } from 'modules/sites/domain/ZoneView';
import fetchJson from 'utils/fetchJson';

export const paginateZones = async (
  params: SearchParamsT & PaginateParamsT
): Promise<ZonePaginateResponse> => {
  const url = `/api/zones?${new URLSearchParams({
    limit: params.limit.toString(),
    offset: params.offset.toString(),
    ...(params.locationId && { locationId: params.locationId }),
    ...(params.sort && { sort: params.sort }),
    ...(params.query && { query: params.query }),
    ...(params.clientId && { clientId: params.clientId })
  })}`;
  const zones = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return zones;
};

export const getZoneById = async (id: string): Promise<ZoneView> => {
  const zone = await fetchJson(`/api/zones/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return zone;
};

export const createZone = async (zone): Promise<boolean> => {
  const zoneId = await fetchJson(
    '/api/zones',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...zone
      })
    },
    'Zone'
  );
  return zoneId;
};

export const updateZone = async (zone): Promise<boolean> => {
  const updatedZone = await fetchJson(
    `/api/zones/${zone.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...zone
      })
    },
    'Zone'
  );
  return updatedZone;
};

export const deleteZone = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/zones/${id}`,
    { method: 'DELETE' },
    'Zone'
  );
  return removed;
};
