import { CircularProgress } from '@react-md/progress';
import { Container } from 'components';
import { Grid, GridCell } from '@react-md/utils';
import React from 'react';

interface LoadingProps {
  isSmall?: boolean;
  loaderStyles?: any;
}

export const Loading = (props: LoadingProps) => {
  return (
    <Container>
      <Grid>
        <GridCell
          colSpan={12}
          style={props.loaderStyles ? { ...props.loaderStyles } : undefined}
        >
          <CircularProgress
            id="loading"
            color="primary"
            small={props.isSmall ? true : false}
          />
        </GridCell>
      </Grid>
    </Container>
  );
};
