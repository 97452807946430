import { Button, ButtonProps } from '@react-md/button';
import { CircularProgress } from '@react-md/progress';
import React, { ReactElement } from 'react';
import styles from './buttons.module.scss';

interface CustomButtonProps extends ButtonProps {
  label?: string;
  ariaLabel: string;
  loading: boolean;
}

export const LoadingButton = React.memo(function LoadingButtonComponent(
  props: CustomButtonProps
): ReactElement {
  const { label, id, ariaLabel, children, loading, ...rest } = props;
  return (
    <React.Fragment>
      <Button id={id} {...rest} type="button" aria-label={ariaLabel}>
        {label || children}
        {loading && (
          <span className={styles.overlay}>
            <CircularProgress id={`${id}-progress`} />
          </span>
        )}
      </Button>
    </React.Fragment>
  );
});
