import { FieldMappingView } from 'modules/sites/domain/FieldMapping';
import { ImportSitesRequest } from 'modules/sites/use-cases/ImportSites';
import { PaginateParamsT } from 'common/PaginationTypes';
import { SiteGroupPaginateResponse } from 'modules/sites/repos/SiteGroupRepo';
import { SiteGroupView } from 'modules/sites/domain/SiteGroupView';
import { SitePaginateResponse } from 'modules/sites/repos/SiteRepo';
import { SiteView } from 'modules/sites/domain/SiteView';
import fetchJson from 'utils/fetchJson';

interface SitePaginatParamsT extends PaginateParamsT {
  location: string;
  zone?: string;
}
interface SiteGroupPaginateParamsT extends PaginateParamsT {
  location: string;
}

export const paginateSites = async (
  params: SitePaginatParamsT
): Promise<SitePaginateResponse> => {
  const { limit, offset, sort, location, ...rest } = params;
  const url = `/api/sites?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    location,
    ...rest
  })}&sort=${sort}`;
  const sites = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return sites;
};

export const getSiteById = async (id: string): Promise<SiteView> => {
  const site = await fetchJson(`/api/sites/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return site;
};

export const createSite = async (
  site,
  redirectOnCreate = true
): Promise<string> => {
  const siteId = await fetchJson(
    '/api/sites',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...site
      })
    },
    'Site',
    redirectOnCreate
  );
  return siteId;
};

export const updateSite = async (site): Promise<SiteView> => {
  const updatedSite = await fetchJson(
    `/api/sites/${site.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...site
      })
    },
    'Site'
  );
  return updatedSite;
};

export const importSites = async (
  request: ImportSitesRequest,
  reasonForOverride?: string
): Promise<boolean> => {
  const saved = await fetchJson('/api/sites/import', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...request,
      reasonForOverride
    })
  });
  return saved;
};

export const createMapping = async (site): Promise<void> => {
  await fetchJson('/api/sites/mapping', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...site
    })
  });
  return;
};

export const listMappings = async (
  locationId: string
): Promise<FieldMappingView[]> => {
  const sites = await fetchJson(
    `/api/sites/mapping?${new URLSearchParams({ locationId })}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return sites;
};

export const deleteSite = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/sites/${id}`,
    {
      method: 'DELETE'
    },
    'Site'
  );
  return removed;
};

export const getSiteGroupById = async (id: string): Promise<SiteGroupView> => {
  const site = await fetchJson(`/api/groups/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return site;
};

export const createSiteGroup = async (site): Promise<string> => {
  const siteId = await fetchJson(
    '/api/groups',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...site
      })
    },
    'Site Group'
  );
  return siteId;
};

export const updateSiteGroup = async (siteGroup): Promise<SiteGroupView> => {
  const updatedSite = await fetchJson(
    `/api/groups/${siteGroup.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...siteGroup
      })
    },
    'Site Group'
  );
  return updatedSite;
};

export const deleteSiteGroup = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/groups/${id}`,
    {
      method: 'DELETE'
    },
    'Site Group'
  );
  return removed;
};

export const paginateSiteGroups = async (
  params: SiteGroupPaginateParamsT
): Promise<SiteGroupPaginateResponse> => {
  const { limit, offset, sort, location, ...rest } = params;
  const url = `/api/groups?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    location,
    ...rest
  })}&sort=${sort}`;
  const sites = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return sites;
};
