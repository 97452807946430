import { Button, Grid, GridCell, SwapHorizSVGIcon, Typography } from 'react-md';
import { Checkbox, FormMessage, Select, TextField } from '@react-md/form';
import { CreateSiteSchema } from 'modules/sites/validation-schema';
import { Field, Form, Formik } from 'formik';
import { ImageUploader } from './ImageUploader';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { SiteLocationView } from 'modules/sites/domain/SiteView';
import { SubmitButton } from 'components/Buttons';
import { ZonePaginateResponse } from 'modules/sites/repos/ZoneRepo';
import { createSite, getSiteById } from 'services/sites';
import { hasPermission } from 'utils/permissions';
import { paginateZones } from 'services/zones';
import { useBetween } from 'use-between';
import { useEffect, useState } from 'react';
import { useFilestack } from 'hooks/useFilestack';
import { useLocation, useUser } from 'hooks';
import MapImage from './MapImage';
import useService from 'hooks/useService';

type UploadedImageT = {
  title?: string;
  filename?: string;
  container?: string;
  awskey?: string;
  mimetype?: string;
  url?: string;
  size?: number;
};

export interface NewSiteFormPropsT {
  onSave: (site: any) => void;
  onCancel: () => void;
  site?: SiteLocationView;
}

export const NewSiteForm = (props: NewSiteFormPropsT) => {
  const { onSave, onCancel, site } = props;
  const { user } = useUser();
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const { deleteImage } = useFilestack();
  const [isComposite, setIsComposite] = useState<boolean>(false);
  const [hasNovalumView, setHasNovalumView] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);
  const [removeImageOnNew, setRemoveImageOnNew] = useState<boolean>(false);
  const [addNewImage, useAddNewImage] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<
    UploadedImageT | undefined
  >();

  const { data } = useService<ZonePaginateResponse>(
    `/api/zones?limit=-1&offset=0&clientId=${user?.client}`,
    () => paginateZones({ limit: -1, offset: 0, clientId: user?.client })
  );

  const handleImageUploaded = async event => {
    const upload = event.filesUploaded[0];
    const newUploadedImage = {
      title: upload.title,
      filename: upload.filename,
      container: upload.container,
      awskey: upload.aswkey,
      mimetype: upload.mimetype,
      url: upload.url,
      size: upload.size
    };
    if (removeImageOnNew) {
      await deleteImage(uploadedImage?.url!);
      setRemoveImageOnNew(false);
    }
    setUploadedImage(newUploadedImage);
    useAddNewImage(false);
  };
  const handleChecked = (value: string, checked: boolean) => {
    if (value === 'isComposite') {
      setIsComposite(checked);
    } else if (value === 'active') {
      setActive(checked);
    }
  };

  const handleSwap = async () => {
    setRemoveImageOnNew(true);
    useAddNewImage(true);
  };

  useEffect(() => {
    if (user && location) {
      setHasNovalumView(
        hasPermission(
          user,
          location?.id,
          PermissionDomainValue.Novalum,
          PermissionActionValue.View
        )
      );
    }
  }, [user, location]);

  const saveNewSite = async site => {
    //send request and handle errors or success
    const created = await createSite(
      {
        ...site,
        location: location!.id
      },
      false
    );
    if (created) {
      const updatedSite = await getSiteById(created);
      onSave(updatedSite);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        location: location?.id,
        zone: site ? site.zone : '',
        siteId: site ? site.siteId : '',
        description: site ? site.description : '',
        vectorId: '',
        limit: site ? site.limit : 0,
        isComposite: site ? site.isComposite : false,
        active: true,
        parentSite: site ? site.id : undefined,
        image: site ? site.image : undefined
      }}
      validationSchema={CreateSiteSchema}
      onSubmit={formValues => {
        saveNewSite({
          zone: formValues.zone,
          siteId: formValues.siteId,
          description: formValues.description,
          image: { ...uploadedImage },
          isComposite,
          vectorId: formValues.vectorId,
          parentSite: formValues.parentSite,
          active,
          ...(formValues.limit && { limit: formValues.limit })
        });
        setUploadedImage(undefined);
      }}
    >
      {props => {
        return (
          <Form onSubmit={props.handleSubmit}>
            <h2 className="text-lg text-center">New Site</h2>
            <Grid>
              <GridCell colSpan={4}>
                <Field
                  as={Select}
                  id="zone"
                  label="Zone ID"
                  theme="underline"
                  placeholder="Zone"
                  value={props.values.zone || ''}
                  options={[...(data?.zones || [])].map(z => ({
                    label: z.name,
                    value: z.id
                  }))}
                  onChange={z => {
                    props.setFieldValue('zone', z);
                  }}
                />
                {props.touched.zone && props.errors.zone && (
                  <FormMessage id={`zoneId-message`} error>
                    {props.errors.zone}
                  </FormMessage>
                )}
              </GridCell>
              <GridCell colSpan={8}>
                <TextField
                  id="siteId"
                  name="siteId"
                  value={props.values.siteId || ''}
                  theme="underline"
                  label="Site ID "
                  error={Boolean(props.errors.siteId)}
                  onChange={props.handleChange}
                />
                <FormMessage
                  id={`siteId-message`}
                  style={{
                    marginBottom: 0,
                    fontSize: '10px',
                    lineHeight: '11px'
                  }}
                >
                  Note: It&#39;s recommended to keep the Site ID as short as
                  possible.
                </FormMessage>
                {props.touched.siteId && props.errors.siteId && (
                  <FormMessage id={`siteId-message`} error>
                    {props.errors.siteId}
                  </FormMessage>
                )}
              </GridCell>
              <GridCell colSpan={4}>
                <TextField
                  id="vectorId"
                  name="vectorId"
                  value={props.values.vectorId || ''}
                  theme="underline"
                  label="Vector ID"
                  error={Boolean(props.errors.vectorId)}
                  onChange={props.handleChange}
                />
                {props.touched.vectorId && props.errors.vectorId && (
                  <FormMessage id={`vectorId-message`} error>
                    {props.errors.vectorId}
                  </FormMessage>
                )}
              </GridCell>
              <GridCell colSpan={8}>
                <TextField
                  id="description"
                  name="description"
                  value={props.values.description || ''}
                  theme="underline"
                  label="Description"
                  error={Boolean(props.errors.description)}
                  onChange={props.handleChange}
                />
                {hasNovalumView && (
                  <FormMessage
                    id="novalum-description"
                    style={{
                      marginBottom: 0,
                      fontSize: '10px',
                      lineHeight: '11px'
                    }}
                  >
                    To keep sites compatible with your Novalum&reg; device, the
                    description must be under 31 characters.
                  </FormMessage>
                )}
                {props.touched.description && props.errors.description && (
                  <FormMessage id={`description-message`} error>
                    {props.errors.description}
                  </FormMessage>
                )}
              </GridCell>
              <GridCell colSpan={6}>
                <Checkbox
                  id="isComposite"
                  label="Is Composite"
                  value="isComposite"
                  defaultChecked={isComposite}
                  onChange={event => {
                    handleChecked('isComposite', event.currentTarget.checked);
                  }}
                />
                <Checkbox
                  id="active"
                  label="Active"
                  value="active"
                  defaultChecked={active}
                  onChange={event => {
                    handleChecked('active', event.currentTarget.checked);
                  }}
                />
              </GridCell>
              <GridCell colSpan={6}>
                {hasNovalumView && (
                  <>
                    <TextField
                      id="limit"
                      name="limit"
                      type="number"
                      value={String(props.values.limit ?? -1)}
                      theme="underline"
                      label="Limit"
                      placeholder="Limit"
                      error={Boolean(props.errors.limit)}
                      onChange={props.handleChange}
                    />
                    {props.touched.limit && props.errors.limit && (
                      <FormMessage id={`limit-message`} error>
                        {props.errors.limit}
                      </FormMessage>
                    )}
                  </>
                )}
              </GridCell>
              <GridCell colSpan={12}>
                {uploadedImage?.url && !addNewImage && (
                  <div style={{ width: '250px', margin: '0 auto' }}>
                    <SwapHorizSVGIcon
                      title="Swap Image"
                      onClick={() => handleSwap()}
                      style={{
                        display: 'block',
                        float: 'right',
                        cursor: 'pointer'
                      }}
                    />
                    <MapImage
                      url={uploadedImage?.url!}
                      name={uploadedImage?.title || ''}
                      mimeType={uploadedImage?.mimetype || 'image/jpeg'}
                      width={250}
                      height={250}
                    />
                  </div>
                )}
                <Typography type="headline-5">Site Image</Typography>
                <ImageUploader
                  show={!uploadedImage?.url || addNewImage}
                  onSuccess={handleImageUploaded}
                  onError={_err => {
                    //console.log(err)
                  }}
                />
              </GridCell>
            </Grid>

            <Button onClick={onCancel}>Cancel</Button>
            <SubmitButton
              theme="primary"
              label="Save"
              ariaLabel={'Save'}
              saving={false}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
