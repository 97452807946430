import { TextIconSpacing } from '@react-md/icon';
import { ToastMessage } from '@react-md/alert';
import { WarningFontIcon } from '@react-md/material-icons';
import styles from './alerts.module.scss';

export const WarnToastMessage = (message: string): ToastMessage => {
  const ToastBody = (
    <TextIconSpacing icon={<WarningFontIcon />} className={styles.warnMessage}>
      {message}
    </TextIconSpacing>
  );
  return {
    messagePriority: 'next',
    className: styles.warnMessage,
    children: ToastBody
  };
};
