import * as XLSX from 'xlsx';
import { Button } from '@react-md/button';
import {
  CheckBoxSVGIcon,
  MoreHorizSVGIcon,
  RemoveRedEyeSVGIcon,
  TuneSVGIcon
} from '@react-md/material-icons';
import { Checkbox } from '@react-md/form';
import { CircularProgress } from '@react-md/progress';
import { Container, Pagination } from 'components';
import {
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@react-md/dialog';
import { DropdownList } from 'components/Menu';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Grid, GridCell, useToggle } from '@react-md/utils';
import { LoadingButton } from 'components/Buttons/LoadingButton';
import { PaginateParamsT } from 'common/PaginationTypes';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { RequiresPermission } from 'components/RequiresPermission';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@react-md/table';
import { TableActionDropdownMenu } from './TableActionDropdownMenu';
import { Tooltip } from 'components/Layout/Tooltip';
import { alertService } from 'utils/alerts';
import { saveUserPreferences } from 'services/users';
import { useBetween } from 'use-between';
import { useHasChanged, useLocation } from 'hooks';
import DownloadingModal from 'components/Modals/DownloadingModal';
import React, { ReactElement, useEffect, useState } from 'react';
import WarningModal from 'components/Modals/WarningModal';
import currency from 'currency.js';
import dotProp from 'dot-prop';
import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import styles from './data-table.module.scss';

export interface DataTableHeaders {
  label: string;
  field: string;
  sortable: boolean;
  renderCell?: (item: any) => any;
  relatedField?: string;
  dateFormat?: boolean;
  timeFormat?: boolean;
  currencyFormat?: boolean;
  hideOnPrint?: boolean;
  boolean?: boolean;
  // onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
export interface TableHeaders extends DataTableHeaders {
  selected: boolean;
}

export type ActionsT = {
  edit?: (id: string) => void;
  remove?: (id: string) => void;
  view?: (id: string) => void;
  string?: {
    icon: ReactElement;
    callback: (id: string) => void;
  };
  menu?: {
    items: { title: string; callback: (id: string) => void }[];
  };
};

interface DataTableProps {
  caption: string;
  actions?: ActionsT;
  headers: DataTableHeaders[];
  limit?: number;
  offset?: number;
  total?: number;
  loading?: boolean;
  data: any[];
  fileName?: string;
  hidePagination?: boolean;
  initialSort?: { field: string; dir: number };
  getCSVData?: () => Promise<any[]>;
  renderTableRow?: <TableDataProps>(
    item: TableDataProps,
    index: number,
    headers: TableHeaders[]
  ) => JSX.Element;
  onFilterPaginate?: (filterProps: PaginateParamsT, query?: string) => void;
  csvHeaders?: any[];
  notDownloadable?: boolean;
  actionValue?: PermissionActionValue;
  domainValue?: PermissionDomainValue;
  allowRoles?: string[];
  role?: string;
  showPDFButton?: boolean;
  handlePDFDownload?: () => void;
  updateTableHeaders?: (headers: string[]) => void;
  allowSavePreferences?: boolean;
  isDownloading?: boolean;
  onSort?: (sort: string) => void;
  hideHeaders?: boolean;
}

const MAX_EXPORTABLE_RESULTS = 10000;

export const paginateTableResults = (
  results: any[],
  limit: number,
  offset: number,
  sortField: string,
  sortDir: number
): any[] => {
  if (!results || !results.length) return [];
  const lim = limit === -1 ? 10 : limit;

  const sorted = results.sort((a, b) =>
    a[sortField] > b[sortField] ? sortDir : -sortDir
  );
  let length = Math.ceil(sorted.length / lim);
  if (isNaN(length)) length = sorted.length;
  if (!length || isNaN(length) || length === Infinity) return results;
  const chunked = Array.from({ length }, (_v, i) =>
    sorted.slice(i * lim, i * lim + lim)
  );
  const chunkNum = offset / lim;
  return chunked[chunkNum] || chunked[0];
};

export function DataTable(props: DataTableProps) {
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const locationHasChanged = useHasChanged(location?.id);
  const [toggled, enable, disable] = useToggle(false);
  const {
    headers,
    data,
    actions,
    onFilterPaginate,
    renderTableRow,
    hidePagination,
    getCSVData,
    initialSort,
    csvHeaders,
    notDownloadable,
    actionValue,
    domainValue,
    allowRoles,
    role,
    showPDFButton,
    handlePDFDownload,
    updateTableHeaders,
    isDownloading,
    onSort
  } = props;
  // const defaultSort: (field: string, dir: number) => any = field => {
  //   return [...data].sort((a, b) => (a[field] > b[field] ? 1 : -1));
  // };
  const [currOffset, setCurrOffset] = useState(props.offset || 0);
  const [paginationLimit, setPaginationLimit] = useState(props.limit ?? 10);
  const [tableHeaders, setTableHeaders] = useState(
    headers.map(h => {
      return { selected: true, ...h };
    })
  );

  const [currentSort, setCurrentSort] = useState<any>(
    initialSort ? initialSort : { field: '_id', dir: 1 }
  );

  const [showDownloading, setShowDownloading] = useState(false);
  const [showExportWarning, setShowExportWarning] = useState(false);

  useEffect(() => {
    setCurrOffset(props.offset || 0);
  }, [props.offset]);

  useEffect(() => {
    if (headers) {
      setTableHeaders(
        headers.map(h => {
          return { selected: true, ...h };
        })
      );
    }
  }, [headers]);
  useEffect(() => {
    setCurrOffset(0);
    if (onFilterPaginate) {
      onFilterPaginate(
        buildFilterParams({
          limit: paginationLimit,
          offset: 0,
          sort: currentSort
        })
      );
    }
  }, [locationHasChanged]);

  const timezone = Intl.DateTimeFormat(undefined, {
    timeZoneName: 'short'
  })
    ?.formatToParts(new Date())
    ?.find(part => part.type == 'timeZoneName')?.value;

  const formatDataToExport = exportData => {
    const headersForDownload = csvHeaders ? csvHeaders : tableHeaders;
    let filterFields = headersForDownload.filter(header => {
      if (header.selected) return header.field;
    });
    let filteredData = exportData?.map(item => {
      return filterFields.reduce((obj, key) => {
        let cellValue = dotProp.get(item, key.field);
        if (key.dateFormat && cellValue) {
          const parsed = parseJSON(cellValue as string);
          if (key.timeFormat) {
            cellValue = `${format(parsed, 'MM/dd/yyyy h:mm:ss a')} ${timezone}`;
          } else {
            cellValue = format(parsed, 'MM/dd/yyyy');
          }
        } else if (key.timeFormat && cellValue) {
          const parsed = parseJSON(cellValue as string);
          cellValue = format(parsed, 'h:mm:ss a');
        }

        if (key.field === 'resultValue' && item.planId) {
          cellValue =
            item.rating === 1 || item.rating === 'Green' ? 'Pass' : 'Fail';
        }
        if (key.field === 'rlu') {
          cellValue = item.resultValue;
        }
        obj[key.label] = cellValue;
        return obj;
      }, {});
    });
    return filteredData;
  };

  const resultsTotal = props.total ? props.total : data ? data.length - 1 : 0;
  const resultsTotalExceedsMax = resultsTotal > MAX_EXPORTABLE_RESULTS;

  const handleCSVDownload = async () => {
    const { fileName } = props;
    if (resultsTotalExceedsMax) {
      //show alert
      setShowExportWarning(true);
      return;
    }

    setShowDownloading(true);
    const exportData = getCSVData ? await getCSVData() : data;
    if (exportData) {
      const formattedData = formatDataToExport(exportData);

      if (formattedData) {
        const dataToExportWS = XLSX.utils.json_to_sheet(formattedData);
        const csvData = XLSX.utils.sheet_to_csv(dataToExportWS);
        downloadToFile(csvData, fileName, 'text/csv');
        setShowDownloading(false);
      }
    }
  };

  const downloadToFile = (content, filename, contentType) => {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
  };

  const updatePagination = ({ offset, limit }) => {
    setCurrOffset(offset);
    setPaginationLimit(limit);
    if (onFilterPaginate) {
      onFilterPaginate(buildFilterParams({ limit, offset, sort: currentSort }));
    }
  };

  const buildFilterParams = ({ limit, offset, sort }) => {
    return {
      sort: `${sort.field}:${sort.dir}`,
      limit,
      offset
    };
  };

  const renderActions = item => {
    if (!actions) {
      return;
    }
    const { edit, remove, view, menu, ...custom } = actions;
    let canDelete = true;
    if (role === 'restricted_user') canDelete = false;
    return (
      <TableCell key={`actions-${item.id}`} className={'no-print'}>
        <RequiresPermission
          permissionDomain={domainValue!}
          action={actionValue!}
          allowRoles={allowRoles}
        >
          {view && (
            <Tooltip text="View">
              <Button onClick={() => view(item.id)}>
                <RemoveRedEyeSVGIcon />
              </Button>
            </Tooltip>
          )}
          {edit && (
            <Tooltip text="Edit">
              <Button onClick={() => edit(item.id)}>
                <FaPen />
              </Button>
            </Tooltip>
          )}
          {remove && canDelete && (
            <Tooltip text="Delete">
              <Button onClick={() => remove(item.id)}>
                <FaTrash />
              </Button>
            </Tooltip>
          )}
          {custom && (
            <>
              {Object.keys(custom).map((k, i) => {
                return (
                  <Button key={i} onClick={() => custom[k].callback(item.id)}>
                    {custom[k].icon}
                  </Button>
                );
              })}
            </>
          )}
          {menu && (
            <>
              <TableActionDropdownMenu
                id={`action-select-${item.id}`}
                position={{ last: true }}
                title={<MoreHorizSVGIcon />}
                items={menu.items}
                resultId={item.id}
              />
            </>
          )}
        </RequiresPermission>
      </TableCell>
    );
  };
  const renderTableHeaders = () => {
    return (
      <>
        {[...tableHeaders]
          .filter(el => el.selected)
          .map((el, i) => (
            <TableCell
              key={`${el.field} - ${i}`}
              aria-sort={
                el.field === currentSort.field
                  ? currentSort.dir === 1
                    ? 'ascending'
                    : 'descending'
                  : 'none'
              }
              onClick={
                el.sortable
                  ? () => {
                      const dir =
                        currentSort.field === el.field ? -currentSort.dir : 1;
                      setCurrentSort({
                        field: el.field,
                        dir
                      });
                      if (onSort) onSort(`${el.field}:${dir}`);
                      if (onFilterPaginate) {
                        onFilterPaginate(
                          buildFilterParams({
                            limit: paginationLimit,
                            offset: currOffset,
                            sort: {
                              field: el.field,
                              dir
                            }
                          })
                        );
                      } else {
                        // setTableData(defaultSort(el.field, dir));
                      }
                    }
                  : () => {}
              }
              className={el.hideOnPrint ? 'no-print' : ''}
            >
              {el.label}
            </TableCell>
          ))}
        {actions && (
          <TableCell key={`actions-header`} className={'no-print'}>
            Actions
          </TableCell>
        )}
      </>
    );
  };
  const tableData = onFilterPaginate
    ? data
    : paginateTableResults(
        data,
        paginationLimit,
        currOffset,
        currentSort.field,
        currentSort.dir
      );
  const tableDataMap = [...(tableData || [])].map((item, i) => {
    return renderTableRow ? (
      renderTableRow(
        item,
        i,
        [...tableHeaders].filter(el => el.selected)
      )
    ) : (
      <TableRow key={`tr-${i}`} disableHover className={styles.dataTableRow}>
        {[...tableHeaders]
          .filter(el => el.selected)
          .map((el, n) => {
            if (el.renderCell) return el.renderCell(item);
            let cellValue = dotProp.get(item, el.field);
            if (el.dateFormat && cellValue) {
              const parsed = parseJSON(cellValue as string);
              if (el.timeFormat) {
                const parsed = parseJSON(cellValue as string);
                cellValue = `${format(
                  parsed,
                  'MM/dd/yyyy hh:mm:ss a'
                )} ${timezone}`;
              } else {
                cellValue = format(parsed, 'MM/dd/yyyy');
              }
            } else {
              if (el.timeFormat && cellValue) {
                const parsed = parseJSON(cellValue as string);
                cellValue = format(parsed, 'hh:mm:ss a');
              }
            }
            if (el.currencyFormat) {
              cellValue = `$${currency(cellValue as string).value}`;
            }
            if (el.boolean) {
              cellValue = cellValue ? 'Yes' : 'No';
            }
            return (
              <TableCell
                key={`${el.field}-${i}-${n}`}
                className={
                  el.hideOnPrint
                    ? styles.dataTableCellNoPrint
                    : styles.dataTableCell
                }
                // onClick={() => console.log(item[el.field])}
              >
                {el.relatedField ? (
                  <DropdownList
                    id={`${item.id}-${el.field}`}
                    items={item[el.field].map(i => i[el.relatedField!])}
                  />
                ) : (
                  <span>{cellValue as string}</span>
                )}
              </TableCell>
            );
          })}
        {renderActions(item)}
      </TableRow>
    );
  });

  const saveTableHeaders = async () => {
    await saveUserPreferences({
      tableHeaders: tableHeaders.filter(h => h.selected).map(h => h.field)
    });
    const alertOptions = {
      id: 'global-alerts',
      autoClose: true,
      keepAfterRouteChange: false
    };
    alertService.success('Column preferences saved', alertOptions);
  };

  const handleSelectField = (field, isChecked) => {
    const selectedHeaders = [...tableHeaders].map(h => {
      return {
        ...h,
        selected: h.field === field ? isChecked : h.selected
      };
    });
    setTableHeaders(selectedHeaders);
    if (updateTableHeaders)
      updateTableHeaders(
        selectedHeaders.filter(h => h.selected).map(h => h.field)
      );
  };

  const dialogHeadersMap = [...tableHeaders].sort().map(el => (
    <GridCell key={el.field} colSpan={4} className={styles.gridListCell}>
      <Checkbox
        id={el.field}
        name={el.field}
        label={el.label}
        icon={<CheckBoxSVGIcon />}
        checked={el.selected}
        onClick={evt => handleSelectField(el.field, evt.currentTarget.checked)}
      />
    </GridCell>
  ));

  return (
    <Container>
      <div className={styles.dataTableDialog}>
        {!notDownloadable && (
          <Button
            className={styles.dataTableDialogBtn}
            theme="primary"
            themeType="outline"
            onClick={handleCSVDownload}
          >
            Export to CSV
          </Button>
        )}
        {showPDFButton ? (
          <Tooltip text="Download PDF">
            <LoadingButton
              className={styles.dataTableDialogBtn}
              ariaLabel="Download PDF"
              themeType="outline"
              theme="primary"
              style={{
                marginLeft: '1rem'
              }}
              loading={isDownloading ?? false}
              onClick={handlePDFDownload}
            >
              Download PDF
            </LoadingButton>
          </Tooltip>
        ) : null}
        <Tooltip text="Choose Columns">
          <Button
            className={styles.dataTableDialogBtn}
            style={{ marginLeft: '1rem' }}
            theme="primary"
            themeType="outline"
            onClick={enable}
          >
            <TuneSVGIcon />
          </Button>
        </Tooltip>
      </div>
      <Dialog
        id="dialog-1"
        // className={styles.dialog1}
        visible={toggled}
        onRequestClose={disable}
        aria-labelledby="dialog-title"
      >
        <Container style={{ overflowY: 'auto' }}>
          <DialogHeader>
            <DialogTitle id="dialog-title">Choose Columns</DialogTitle>
          </DialogHeader>
          <Grid>{dialogHeadersMap}</Grid>
        </Container>
        <DialogFooter>
          {props.allowSavePreferences && (
            <Button onClick={saveTableHeaders}>Save Column Preferences</Button>
          )}
          <Button onClick={disable}>Close</Button>
        </DialogFooter>
      </Dialog>
      <div className={styles.tableContainer}>
        <Table className={styles.dataTable}>
          <TableHeader className={styles.tableHeader}>
            <TableRow>{renderTableHeaders()}</TableRow>
          </TableHeader>
          <TableBody style={{ overflow: 'auto', height: '200px' }}>
            {props.loading ? (
              <TableRow>
                <TableCell
                  colSpan={
                    actions
                      ? [...tableHeaders].filter(el => el.selected).length + 1
                      : [...tableHeaders].filter(el => el.selected).length
                  }
                >
                  <CircularProgress id="fetching" color="primary" />
                </TableCell>
              </TableRow>
            ) : (
              tableDataMap
            )}
          </TableBody>
        </Table>
      </div>
      {!hidePagination ? (
        <Pagination
          id={'something'}
          total={resultsTotal}
          limit={paginationLimit === -1 ? 10 : paginationLimit}
          offset={currOffset}
          onChange={props => updatePagination(props)}
        />
      ) : null}
      <DownloadingModal
        title="Downloading Data"
        copy="Preparing data, your download will start shortly. Depending on the amount of data, this may take a few moments."
        visible={showDownloading}
        onRequestClose={() => setShowDownloading(false)}
      />
      <WarningModal
        title="Too many results"
        copy="The amount of filtered data exceeds the max for export. Please adjust your filters to include less data to be exported."
        visible={showExportWarning}
        onRequestClose={() => setShowExportWarning(false)}
      />
    </Container>
  );
}
export default DataTable;
