import { Button } from '@react-md/button';
import { Card, CardActions, CardContent } from '@react-md/card';
import {
  CheckSVGIcon,
  CloseSVGIcon,
  DeleteSVGIcon,
  ZoomOutMapSVGIcon
} from '@react-md/material-icons';
import { Overlay } from '@react-md/overlay';
import { getFixedPosition } from '@react-md/utils';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { AddSVGIcon } from 'react-md';
import { ConfirmationModal } from 'components';
import { NewSiteForm } from './NewSiteForm';
import { SiteLocationView } from 'modules/sites/domain/SiteView';
import { Tooltip } from 'components/Layout/Tooltip';
import ReactSelect from 'react-select';
import styles from './map-marker.module.scss';

type MapMarkerModalPropsT = {
  visible: boolean;
  value: string;
  sites: SiteLocationView[];
  mapMarker: HTMLElement | null;
  onSave: (site: SiteLocationView) => void;
  onDelete: (site: string) => void;
  onMove: () => void;
  onRequestClose: () => void;
};

interface SelectOption {
  label: React.ReactNode;
  value: string;
  data?: SiteLocationView;
}

export const MapMarkerModal = (props: MapMarkerModalPropsT): ReactElement => {
  const { visible, value, sites, mapMarker, onRequestClose } = props;
  const cardRef = useRef(null);
  const [allowSave, setAllowSave] = useState(false);
  const [style, setStyle] = useState<any>();
  const [site, setSite] = useState<SelectOption | null>();
  const [selectedSite, setSelectedSite] = useState<
    SiteLocationView | undefined
  >();
  const [hasSite, setHasSite] = useState<boolean>(false);
  const [ShowModal, setShowModal] = useState(false);
  const [addingSite, setAddingSite] = useState(false);
  const [siteOptions, setSiteOptions] = useState<any[]>([]); //SiteLocationView[]
  const updatePosition = useCallback(() => {
    if (!cardRef.current || !mapMarker) {
      return;
    }
    const newStyle = getFixedPosition({
      container: mapMarker,
      element: cardRef.current
    }).style;
    setStyle(newStyle);
  }, []);

  const cardRefCB = useCallback(card => {
    cardRef.current = card;
    updatePosition();
    // only want to call this once on mount
  }, []);

  const handleChange = useCallback((nextvalue?: SelectOption) => {
    if (!nextvalue) return;
    setSite(nextvalue);
    setAllowSave(true);
    const foundSite = sites.find(s => s.id === nextvalue.value);
    setSelectedSite(foundSite);
    setHasSite(foundSite ? true : false);
  }, []);

  const showNewSite = () => {
    setSite(null);
    setSelectedSite(undefined);
    setAddingSite(true);
    const newStyle = getFixedPosition({
      container: mapMarker,
      element: cardRef.current
    }).style;
    setStyle({
      ...newStyle,
      top: '50%',
      transform: 'translateY(-50%)'
    });
  };
  const showNewVector = () => {
    setAddingSite(true);
    const newStyle = getFixedPosition({
      container: mapMarker,
      element: cardRef.current
    }).style;
    setStyle({
      ...newStyle,
      top: '50%',
      transform: 'translateY(-50%)'
    });
  };
  const cancelAddSite = () => {
    setAddingSite(false);
    setTimeout(() => updatePosition(), 100);
  };

  const addNewSite = async site => {
    const newOption = {
      label: (
        <>
          {site.zoneCode}&nbsp;
          <span className="text-xs">{site.description}</span>
        </>
      ),
      value: site.id,
      data: site
    };
    const options = [...siteOptions, newOption];
    setSiteOptions(options);
    setSite(newOption);
    cancelAddSite();
    setAllowSave(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setAllowSave(false);
    onRequestClose();
  };

  const buildOptions = (
    data: any[],
    labelKey: string,
    secondaryField: string
  ): SelectOption[] => {
    if (!data || !data.length) {
      return [];
    }

    return data.map(result => ({
      label: (
        <>
          {result[labelKey]}&nbsp;
          <span className="text-xs">{result[secondaryField]}</span>
        </>
      ),
      value: result.id,
      data: result
    }));
  };

  const saveMarker = () => {
    if (!site) return;
    props.onSave(site.data!);
    setSite(undefined);
    setSelectedSite(undefined);
    setHasSite(false);
  };

  useEffect(() => {
    const options = buildOptions(sites, 'zoneCode', 'description');
    setSiteOptions(options);
    if (value) {
      setSite(options.find(o => o.value === value));
    }
  }, [sites]);

  useEffect(() => {
    if (!value || mapMarker?.style.background === 'red') {
      setAllowSave(true);
    } else {
      setAllowSave(false);
    }
  }, [mapMarker?.style.background]);

  if (!visible) {
    return <div />;
  }

  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input && candidate) {
      return (
        candidate.data.data.siteId
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        candidate.data.data.zoneCode
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        candidate.data.data.description
          .toLowerCase()
          .includes(input.toLowerCase())
      );
    }
    return true;
  };

  return (
    <Overlay
      id="portal-menu-overlay"
      visible={visible}
      onRequestClose={() => {}}
    >
      <Card
        id="map-marker-modal"
        role="alertdialog"
        aria-labelledby="marker-select"
        ref={cardRefCB}
        className={styles.markerCard}
        style={style}
      >
        {!addingSite ? (
          <>
            <CardContent>
              <ReactSelect
                id={'sites'}
                name={'sites'}
                value={site}
                options={siteOptions}
                placeholder="Choose A Site"
                closeMenuOnSelect={true}
                isClearable={true}
                onChange={handleChange}
                filterOption={filterOptions}
                required={true}
              />
              <div className="flex justify-between">
                <Button
                  id={'newsite'}
                  themeType="outline"
                  onClick={showNewSite}
                  className={styles.filterBarIconBtn}
                >
                  <AddSVGIcon /> New Site
                </Button>
                {hasSite && (
                  <Button
                    id={'newsite'}
                    themeType="outline"
                    onClick={showNewVector}
                    className="mt-[20px]"
                  >
                    <AddSVGIcon /> New Vector
                  </Button>
                )}
              </div>
            </CardContent>
            <CardActions>
              {allowSave ? (
                <>
                  <Tooltip text="Cancel">
                    <Button
                      buttonType="icon"
                      aria-label="Cancel"
                      theme="error"
                      onClick={handleClose}
                    >
                      <CloseSVGIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip text="Save">
                    <Button
                      buttonType="icon"
                      aria-label="Save"
                      theme="secondary"
                      disabled={!allowSave}
                      onClick={() => saveMarker()}
                    >
                      <CheckSVGIcon />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip text="Cancel">
                    <Button
                      buttonType="icon"
                      aria-label="Cancel"
                      theme="error"
                      onClick={handleClose}
                    >
                      <CloseSVGIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip text="Move">
                    <Button onClick={props.onMove} buttonType="icon">
                      <ZoomOutMapSVGIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip text="Delete">
                    <Button
                      onClick={() => setShowModal(true)}
                      buttonType="icon"
                    >
                      <DeleteSVGIcon />
                    </Button>
                  </Tooltip>
                </>
              )}
            </CardActions>
          </>
        ) : (
          <CardContent>
            <NewSiteForm
              onSave={addNewSite}
              onCancel={cancelAddSite}
              site={selectedSite}
            />
          </CardContent>
        )}
      </Card>
      <ConfirmationModal
        title="Delete Marker"
        copy="Are you sure you want to delete this marker?"
        confirmCallback={() => props.onDelete(site!.value)}
        visible={ShowModal}
        onRequestClose={hideModal}
      />
    </Overlay>
  );
};
