import { Controller } from 'react-hook-form';
import { PasswordWithMessage } from '@react-md/form';
import {
  VisibilityOffSVGIcon,
  VisibilitySVGIcon
} from '@react-md/material-icons';
import React from 'react';

const getVisibilityIcon = (type: string) => {
  if (type === 'text') return <VisibilityOffSVGIcon />;
  return <VisibilitySVGIcon />;
};
export function ControllerPassword({ id, name, message, label, ...props }) {
  const { error } = props;
  const messageId = `${id}-message`;

  return (
    <Controller
      {...props}
      name={name}
      render={({ field }) => (
        <PasswordWithMessage
          id={id}
          {...field}
          getVisibilityIcon={getVisibilityIcon}
          aria-label={label}
          messageProps={{
            id: messageId,
            error,
            children: message
          }}
        />
      )}
      aria-describedby={messageId}
      defaultValue=""
    />
  );
}
