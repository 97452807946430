import Result from 'common/core/Result';
import ValueObject from 'common/core/ValueObject';
import validate from 'common/core/validate';

interface Constructor<M> {
  new (...args: any[]): M;
}

export default function EnumValueObject<ValuesDictionary>(
  values: ValuesDictionary,
  label: string,
  shortLabel = label
) {
  type Value = ValuesDictionary[keyof ValuesDictionary];
  //@ts-ignore
  const acceptableValues: Value[] = Object.values(values);

  class EnumValueObject extends ValueObject<{ value: Value }> {
    get value(): Value {
      return this.props.value;
    }

    static build<T extends EnumValueObject>(
      this: Constructor<T>,
      value: Value | string | undefined | null
    ): Result<T> {
      const validateResult = validate({
        value,
        name: shortLabel,
        oneOf: {
          list: acceptableValues,
          label: `a valid ${label}`
        }
      });
      if (validateResult.isFailure) {
        return validateResult as any;
      }
      return Result.ok(new this({ value: value as Value }));
    }
  }
  //@ts-ignore
  const statics = Object.entries(values).reduce(
    (obj, [key, value]) => ({
      ...obj,
      //@ts-ignore
      [key]: new EnumValueObject({ value })
    }),
    {}
  ) as {
    [P in keyof ValuesDictionary]: EnumValueObject;
  };

  return Object.assign(EnumValueObject, statics);
}
