import EnumValueObject from 'common/core/EnumValueObject';

export enum FilterOperatorValues {
  In = 'in',
  NotIn = 'not-in',
  EqualTo = 'equal-to',
  GreaterThan = 'greater-than',
  LessThan = 'less-than',
  GreaterThanOrEqualTo = 'greater-than-or-equal-to',
  LessThanOrEqualTo = 'less-than-or-equal-to'
}

export const OPERATORS: string[] = Object.values(FilterOperatorValues);

export default class FilterOperatorValue extends EnumValueObject(
  FilterOperatorValues,
  'operator'
) {}
