import { FormMessage, TextField } from '@react-md/form';
import { Grid, GridCell } from '@react-md/utils';
import { useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';

import { Container, UseWarningOnExit } from 'components';
import Dropdown from '../Form/Dropdown';
import allCountries from 'data/allCountries.json';
import usStates from 'data/us-states.json';

const states: string[] = usStates.states.map(stateObj => stateObj.code);
const countries: string[] = allCountries.map(countryObj => countryObj.name);
export interface CompanySaveProps {
  id?: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export default function CompanyInfo(): ReactElement {
  const { values, errors, touched, handleChange, dirty } =
    useFormikContext<CompanySaveProps>();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsEditing(dirty);
  }, [dirty]);

  return (
    <Container>
      <UseWarningOnExit shouldWarn={isEditing} />
      <Grid>
        <GridCell colSpan={12}>
          <TextField
            id="name"
            label="Company Name *"
            value={values.name}
            type="text"
            theme="underline"
            onChange={handleChange}
            required
            error={Boolean(errors.name)}
          />
          {touched.name && Boolean(errors.name) && (
            <FormMessage id={`companyName-message`} error>
              {errors.name}
            </FormMessage>
          )}
        </GridCell>
        <GridCell colSpan={12}>
          <TextField
            id="address"
            label="Address *"
            value={values.address}
            type="text"
            theme="underline"
            onChange={handleChange}
            required
            error={Boolean(errors.address)}
          />
          {touched.address && Boolean(errors.address) && (
            <FormMessage id={`address-message`} error>
              {errors.address}
            </FormMessage>
          )}
        </GridCell>
        <GridCell colSpan={5}>
          <TextField
            id="city"
            label="City *"
            value={values.city}
            type="text"
            theme="underline"
            onChange={handleChange}
            required
            error={Boolean(errors.city)}
          />
          {touched.city && Boolean(errors.city) && (
            <FormMessage id={`city-message`} error>
              {errors.city}
            </FormMessage>
          )}
        </GridCell>
        <GridCell colSpan={2}></GridCell>
        <GridCell colSpan={5}>
          <Dropdown
            id="state"
            name="state"
            label="State *"
            value={values.state}
            options={states}
            theme="underline"
            onChange={handleChange}
            error={Boolean(touched.state && errors.state)}
          />
        </GridCell>
        <GridCell colSpan={5}>
          <TextField
            id="zip"
            label="Zip *"
            value={values.zip}
            type="text"
            theme="underline"
            onChange={handleChange}
            required
            error={Boolean(errors.zip)}
          />
          {touched.zip && Boolean(errors.zip) && (
            <FormMessage id={`zip-message`} error>
              {errors.zip}
            </FormMessage>
          )}
        </GridCell>
        <GridCell colSpan={2}></GridCell>
        <GridCell colSpan={5}>
          <Dropdown
            id="country"
            name="country"
            label="Country *"
            value={values.country}
            options={countries}
            theme="underline"
            onChange={handleChange}
            error={Boolean(touched.country && errors.country)}
          />
        </GridCell>
        <GridCell colSpan={5}>
          <TextField
            id="phone"
            label="Phone *"
            value={values.phone}
            type="tel"
            theme="underline"
            onChange={handleChange}
            required
            error={Boolean(errors.phone)}
          />
          {touched.phone && Boolean(errors.phone) && (
            <FormMessage id={`phone-message`} error>
              {errors.phone}
            </FormMessage>
          )}
        </GridCell>
      </Grid>
    </Container>
  );
}
