import { LocationView } from 'modules/locations/domain/LocationView';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { PermissionView } from 'modules/users/domain/Permission';
// import { PermissionResourceValue } from 'modules/users/domain/PermissionResource';

interface UserPermissionView {
  location: string | LocationView;
  users: PermissionView;
  sites: PermissionView;
  billing: PermissionView;
  categories: PermissionView;
  organisms: PermissionView;
  zones: PermissionView;
  tests: PermissionView;
  facilityMaps: PermissionView;
  reports: PermissionView;
  testResults: PermissionView;
  ebacmap: PermissionView;
  capa: PermissionView;
  tour: PermissionView;
}
interface PermissionUserView {
  role: string;
  permissions?: UserPermissionView[];
}

export const getCurrentPermissions = (
  user: PermissionUserView,
  locationId: string
): UserPermissionView | undefined => {
  const currentPermission = user.permissions?.find(p =>
    typeof p.location === 'string'
      ? p.location === locationId
      : p.location.id === locationId
  );
  return currentPermission;
};

export const hasPermission = (
  user: PermissionUserView,
  locationId: string,
  domain: PermissionDomainValue,
  action: PermissionActionValue,
  resource?: string,
  resourceId?: string
): boolean => {
  const currentPermission = getCurrentPermissions(user, locationId);
  if (currentPermission) {
    if (currentPermission.hasOwnProperty(domain)) {
      const userStatus = currentPermission[domain].action;
      if (action === PermissionActionValue.Manage && userStatus === action) {
        if (resource && !resourceId) {
          //check for additional permissions.
          if (!currentPermission![domain][resource]) {
            return false;
          }
        }
        return true;
      }
      if (
        action === PermissionActionValue.View &&
        (userStatus === action || userStatus === PermissionActionValue.Manage)
      ) {
        if (
          userStatus === PermissionActionValue.View &&
          resource &&
          resourceId
        ) {
          //check for this resource in the list
          return currentPermission[domain][resource].includes(resourceId);
        } else if (
          userStatus === PermissionActionValue.Manage &&
          resource &&
          !resourceId
        ) {
          //check for additional permissions.
          if (!currentPermission![domain][resource]) {
            return false;
          }
        }
        return true;
      }
    } else {
      return false;
    }
  } else if (
    (user.role === 'company_admin' || user.role === 'facility_admin') &&
    locationId === 'global' &&
    (domain.valueOf() === 'reports' ||
      domain.valueOf() === 'categories' ||
      domain.valueOf() === 'organisms' ||
      domain.valueOf() === 'zones' ||
      domain.valueOf() === 'users' ||
      domain.valueOf() === 'billing')
  ) {
    return true;
  }
  return false;
};
