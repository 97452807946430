import { Button } from '@react-md/button';
import { Label, Select } from '@react-md/form';
import {
  NavigateBeforeSVGIcon,
  NavigateNextSVGIcon
} from '@react-md/material-icons';
import React from 'react';
import styles from './pagination.module.scss';

export interface PaginationChangedProps {
  offset: number;
  limit: number;
}
export interface PaginationProps {
  id?: string;
  total: number;
  limit: number;
  offset: number;
  onChange: (changeProps: PaginationChangedProps) => void;
}

const LIMIT_OPTIONS = ['10', '15', '25', '50', '100'];

export function Pagination(props: PaginationProps) {
  const { id = '', limit, offset, total } = props;
  const paginationId = `${id}-pagination`;
  const totalPages = Math.floor(total / limit);
  const currentPage = Math.floor(offset / limit);
  const hasNextPage = currentPage < totalPages && total > limit;

  const hasPrevPage = currentPage !== 0;
  const endOffset = offset + limit > total ? total : offset + limit;

  const handlePageChange = (direction: string) => {
    let newOffset = direction === 'forward' ? offset + limit : offset - limit;
    props.onChange({ offset: newOffset, limit });
  };

  const handleLimitChange = (limVal: string) => {
    const newLimit = parseInt(limVal);
    props.onChange({ offset: 0, limit: newLimit });
  };

  let offsetVal = total <= 0 ? -1 : offset;
  const endOffsetVal = total <= 0 ? 0 : endOffset;
  const totalVal = total < 0 ? 0 : total;

  return (
    <div
      id={paginationId}
      aria-label="Pagination"
      className={styles.pagContainer}
    >
      <div className={styles.rows}>
        <Label htmlFor="pagination-limit" className={styles.limitLabel}>
          Rows Per Page
        </Label>
        <Select
          id="pagination-limit"
          className={styles.limit}
          theme="none"
          inline
          options={LIMIT_OPTIONS}
          value={limit ? limit.toString() : ''}
          onChange={limVal => handleLimitChange(limVal)}
        />
      </div>
      <div className={styles.offset}>
        {offsetVal + 1} - {endOffsetVal} of {totalVal}
      </div>
      <div className={styles.arrows}>
        <Button
          buttonType="icon"
          aria-label="Previous Page"
          onClick={() => handlePageChange('back')}
          disabled={!hasPrevPage}
        >
          <NavigateBeforeSVGIcon />
        </Button>
        <Button
          buttonType="icon"
          aria-label="Next Page"
          onClick={() => handlePageChange('forward')}
          disabled={!hasNextPage}
        >
          <NavigateNextSVGIcon />
        </Button>
      </div>
    </div>
  );
}
