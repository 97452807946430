import { Container, OutlineButton } from 'components';
import { Dialog, DialogContent, DialogFooter } from '@react-md/dialog';
import { Typography } from '@react-md/typography';
import Parser from 'html-react-parser';
import React, { ReactElement } from 'react';
import styles from './confirmationModal.module.scss';

interface WarningModalProps {
  title: string;
  copy: string;
  visible: boolean;
  onRequestClose: () => void;
}

export function WarningModal(props: WarningModalProps): ReactElement {
  const { title, copy, visible, onRequestClose } = props;

  return (
    <Container>
      <Dialog
        id="confirmation-modal"
        role="alertdialog"
        modal={true}
        visible={visible}
        onRequestClose={onRequestClose}
        aria-labelledby="dialog-title"
      >
        <DialogContent className={styles.dialogHeader}>
          <Typography className={styles.dialogTitle} type="headline-5">
            {title}
          </Typography>
        </DialogContent>
        <DialogContent>
          <Typography>{Parser(copy)}</Typography>
        </DialogContent>
        <DialogFooter>
          <OutlineButton
            ariaLabel="close"
            theme="warning"
            onClick={onRequestClose}
          >
            Close
          </OutlineButton>
        </DialogFooter>
      </Dialog>
    </Container>
  );
}

export default WarningModal;
