import {
  AttachMoneySVGIcon,
  GroupSVGIcon,
  HomeSVGIcon,
  PersonSVGIcon,
  ShoppingCartSVGIcon
} from '@react-md/material-icons';
import { LayoutNavigationTree } from '@react-md/layout';
import React, { ReactNode } from 'react';
import filterByAccess, {
  RestrictedLayoutNavigationItem
} from 'hooks/filterByAccess';
/**
 * Note: The `parentId` **must** be defaulted to `null` for the navigation tree
 * to render correctly since this uses the @react-md/tree package behind the
 * scenes. Each item that has a `parentId` set to `null` will appear at the root
 * level of your navigation tree.
 */
function createRoute(
  pathname: string,
  children: string,
  as: string | null,
  leftAddon: ReactNode | undefined,
  parentId: string | null = null,
  roles: string[] = ['*'],
  permissions: string[] = ['*']
): RestrictedLayoutNavigationItem {
  return {
    itemId: pathname,
    parentId,
    href: pathname,
    as,
    children,
    leftAddon,
    roles,
    permissions
  };
}

const navItems: LayoutNavigationTree<RestrictedLayoutNavigationItem> = {
  '/admin': createRoute('/admin', 'Dashboard', null, <HomeSVGIcon />),
  '/admin/clients': createRoute(
    '/admin/clients',
    'Clients',
    null,
    <PersonSVGIcon />
  ),
  '/admin/manage-users': createRoute(
    '/admin/manage-users',
    'Users',
    null,
    <GroupSVGIcon />
  ),
  '/admin/purchases': createRoute(
    '/admin/purchases',
    'Purchases',
    null,
    <AttachMoneySVGIcon />
  ),
  '/admin/products': createRoute(
    '/admin/products',
    'Products',
    null,
    <ShoppingCartSVGIcon />
  )
};

export default function getAdminNavItems(user) {
  if (!user) {
    return {};
  }
  return filterByAccess(user, navItems);
}
