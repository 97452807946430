import { ArrowDropDownSVGIcon } from 'react-md';
import { Container } from 'components';
import { FilterSelectProps } from './FilterTypes';
import { Label, ListboxOption, Select } from '@react-md/form';
import AsyncSelect from 'react-select/async';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import styles from './filter-bar.module.scss';

const DropdownIndicator = () => {
  return <ArrowDropDownSVGIcon />;
};
export const FilterSelect = (props: FilterSelectProps): ReactElement => {
  const { filter, filterCallback, disabled, multiple } = props;
  const [defaultValue, setDefaultValue] = useState(filter.value);
  const [value, setValue] = useState(filter.value);
  const handleSelectChange = useCallback(
    (nextValue: string | string[], _option?: ListboxOption | string[]) => {
      setValue(nextValue);
      if (filterCallback) {
        filterCallback(filter.name, nextValue);
      }
    },
    [filterCallback]
  );
  useEffect(() => {
    if (filter.value !== defaultValue || value !== filter.value) {
      setDefaultValue(filter.value);
      setValue(filter.value);
    }
  }, [filter]);
  return (
    <Container className={styles.filterContainer}>
      <Label className={styles.filterLabel} htmlFor={filter.name}>
        {filter.label}
      </Label>
      {filter.icon ? <filter.icon className={styles.filterIcon} /> : null}
      {filter.element ? (
        filter.element
      ) : (
        <>
          {filter.async ? (
            <AsyncSelect
              id={filter.name}
              name={filter.name}
              className="w-[88%] border-none"
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  border: 'none',
                  borderRadius: 0,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  zIndex: 10
                })
              }}
              cacheOptions={true}
              defaultOptions={true}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              value={filter.options?.filter(opt => value?.includes(opt.value))}
              loadOptions={filter.getOptions!}
              placeholder={
                typeof value === 'string' ? value : value ? value[0] : 'All'
              }
              isMulti
              closeMenuOnSelect={false}
              onChange={z => {
                handleSelectChange(
                  z.map(x => (x.hasOwnProperty('value') ? x.value : x))
                );
              }}
            />
          ) : (
            <>
              {multiple ? (
                <ReactSelect
                  id={filter.name}
                  name={filter.name}
                  className="w-[88%] border-none"
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      border: 'none',
                      borderRadius: 0,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                    }),
                    menu: baseStyles => ({
                      ...baseStyles,
                      zIndex: 10
                    })
                  }}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  value={filter.options?.filter(opt =>
                    value?.includes(opt.value)
                  )}
                  options={filter.options!}
                  placeholder={
                    typeof value === 'string' ? value : value ? value[0] : 'All'
                  }
                  filterOption={(candidate, input) => {
                    const opt = filter.options?.find(
                      o => o.value === candidate.value
                    );

                    if (!opt) return false;
                    return opt.searchable
                      ? opt.searchable
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      : opt.label.toLowerCase().includes(input.toLowerCase());
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={z => {
                    handleSelectChange(
                      z.map(x => (x.hasOwnProperty('value') ? x.value : x))
                    );
                  }}
                />
              ) : (
                <Select
                  id={filter.name}
                  name={filter.name}
                  value={value as string}
                  options={filter.options!}
                  disabled={
                    !filter.options || !filter.options.length || disabled
                  }
                  placeholder={value}
                  theme="underline"
                  className={styles.filterInput}
                  onChange={handleSelectChange}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
