import { CreatePlanProps } from 'modules/plans/use-cases/CreatePlan/types';
import { FetchService } from './FetchService';
import { GetPlanFiltersResponse } from 'modules/plans/use-cases/GetPlanFilters/types';
import {
  ImportPlansProps,
  ImportPlansResponse
} from 'modules/plans/use-cases/ImportPlans/types';
import {
  PaginatePlansFilterParams,
  PaginatePlansResponse
} from 'modules/plans/use-cases/PaginatePlans/types';
import { PlanView } from 'modules/plans/use-cases/GetPlan/types';
import { SearchPlanProps } from 'modules/plans/use-cases/SearchPlans/types';
import { UpdatePlanProps } from 'modules/plans/use-cases/UpdatePlan/types';
import { UseCaseCreatedResponse } from 'common/core/UseCase';

export const paginatePlans = (request: PaginatePlansFilterParams) =>
  FetchService<PaginatePlansResponse, PaginatePlansFilterParams>(
    'GET',
    '/api/plans',
    request
  );

export const createPlan = (request: CreatePlanProps) =>
  FetchService<UseCaseCreatedResponse, CreatePlanProps>(
    'POST',
    '/api/plans',
    request
  );
export const updatePlan = (id: string, request: UpdatePlanProps) =>
  FetchService<boolean, UpdatePlanProps>('PUT', `/api/plans/${id}`, request);

export const getPlanById = (url: string) =>
  FetchService<PlanView, void>('GET', url);

export const deletePlan = (id: string) =>
  FetchService<boolean, void>('DELETE', `/api/plans/${id}`);

export const getPlanFilters = url =>
  FetchService<GetPlanFiltersResponse, void>('GET', url);

export const importPlans = (data: ImportPlansProps) =>
  FetchService<ImportPlansResponse, ImportPlansProps>(
    'POST',
    '/api/plans/import',
    data
  );

export const findPlans = (data: SearchPlanProps) =>
  FetchService<PlanView[], SearchPlanProps>(
    'GET',
    `/api/plans/search?${new URLSearchParams({ ...data })}`
  );
