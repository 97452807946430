import { Button, ButtonProps } from '@react-md/button';
import { CircularProgress } from '@react-md/progress';
import React, { ReactElement } from 'react';
import styles from './buttons.module.scss';

interface CustomButtonProps extends ButtonProps {
  label?: string;
  ariaLabel: string;
  saving: boolean;
}

export const SubmitButton = React.memo(function SubmitButtonComponent(
  props: CustomButtonProps
): ReactElement {
  const { label, id, ariaLabel, children, saving, ...rest } = props;
  return (
    <React.Fragment>
      <Button
        id={id}
        {...rest}
        themeType="outline"
        theme="primary"
        type="submit"
        className={styles.outlineBtn}
        aria-label={ariaLabel}
      >
        {label || children}
        {saving && (
          <span className={styles.overlay}>
            <CircularProgress id={`${id}-progress`} />
          </span>
        )}
      </Button>
    </React.Fragment>
  );
});
