import { Tooltipped } from '@react-md/tooltip';
import React, { ReactElement } from 'react';

interface TooltipProps {
  text: any;
  children: ReactElement;
}

export function Tooltip(props: TooltipProps): ReactElement {
  const { text, children } = props;
  return (
    <Tooltipped
      id={`${
        typeof text === 'string' ? text.toLowerCase() : 'trendRing'
      }-tooltip`}
      tooltip={text}
      className="tooltip"
      style={{
        textAlign: 'center',
        fontFamily: `Montserrat, Roboto, sans-serif`
      }}
    >
      {children}
    </Tooltipped>
  );
}
