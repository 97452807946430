import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { AlertProvider } from './AlertProvider';
import { AppBarActions } from './AppBarActions';
import { AppLogo } from './AppLogo';
import { ChangelogPanel } from './ChangelogPanel';
import {
  Configuration,
  LayoutNavigationItem,
  LayoutNavigationTree,
  Layout as RMDLayout,
  useLayoutNavigation
} from '@react-md/layout';
import { MessageQueue } from '@react-md/alert';
import { NON_AUTH_PAGES } from 'config/constants';
import { UserRoleValue } from 'modules/users/domain/UserRole';
import { Version } from './Version';
import { changeLogSteps } from 'config/changelog';
import { useBetween } from 'use-between';
import { useHasChanged, useLocation, useUser } from 'hooks';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import LinkUnstyled from '../LinkUnstyled';
import Shepherd from 'shepherd.js';
import getAdminNavItems from './adminNavItems';
import getNavItems from './navItems';

interface LayoutProps {
  children: ReactNode;
}

// Check out the documentation for Configuring your Layout for more information:
// - https://react-md.dev/guides/configuring-your-layout
export default function AppLayout({ children }: LayoutProps): ReactElement {
  const router = useRouter();
  const [hasNav, setHasNav] = useState(false);
  const [navItems, setNavItems] = useState<
    LayoutNavigationTree<LayoutNavigationItem>
  >({});
  const [showChangesPanel, setShowChangesPanel] = useState(false);
  const { user, isLoggedIn } = useUser();
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const cookies = new Cookies();
  //@ts-ignore
  const version = useSelector(state => state.version);

  const locationHasChanged = useHasChanged(location?.id);
  useEffect(() => {
    if (isLoggedIn && user && !hasNav) {
      setNavItems(
        user?.role === UserRoleValue.Admin ||
          user?.role === UserRoleValue.CustomerSupport
          ? getAdminNavItems(user)
          : getNavItems(user, location)
      );
      setHasNav(true);
    } else if ((!user || !isLoggedIn) && hasNav) {
      setNavItems({});
      setHasNav(false);
    } else if (hasNav && user && isLoggedIn && locationHasChanged) {
      setNavItems(
        user?.role === UserRoleValue.Admin ||
          user?.role === UserRoleValue.CustomerSupport
          ? getAdminNavItems(user)
          : getNavItems(user, location)
      );
    }
  }, [user, navItems, isLoggedIn, locationHasChanged]);

  useEffect(() => {
    const changelogHistory = cookies.get('versions');
    const steps = Object.prototype.hasOwnProperty.call(changeLogSteps, version)
      ? changeLogSteps[version]
      : null;
    if (!isLoggedIn || !user) return;
    if ((!changelogHistory || !changelogHistory.includes(version)) && steps) {
      const history = changelogHistory
        ? [...changelogHistory, version]
        : [version];
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'changelog-tour shepherd',
          scrollTo: true
        }
      });
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        const lastStep = i === steps.length - 1;
        tour.addStep({
          ...step,
          title: "What's New",
          text: `<h3>${step.subtitle}</h3> ${step.text}`,
          buttons: [
            {
              text: lastStep ? 'Done' : 'Next',
              action: lastStep ? tour.complete : tour.next
            }
          ]
        });
      }

      tour.on('complete', () => {
        cookies.set('versions', history);
      });
      tour.on('hide', () => {
        cookies.set('versions', history);
      });
      setTimeout(() => {
        tour.start();
      }, 5000);
    }
  }, [isLoggedIn]);
  const path = router.asPath?.includes('new') ? router.asPath : router.pathname;
  const navTree = useLayoutNavigation(navItems, path, LinkUnstyled);

  return (
    <Configuration>
      <RMDLayout
        customTitle={<AppLogo />}
        appBarProps={{
          children: (
            <AppBarActions
              showChangesPanel={showChangesPanel}
              setShowChangesPanel={setShowChangesPanel}
            />
          )
        }}
        navHeaderTitle="eBacMap"
        tabletLayout="temporary"
        landscapeTabletLayout="temporary"
        desktopLayout={
          NON_AUTH_PAGES.includes(router.pathname) ? 'floating' : 'clipped'
        }
        largeDesktopLayout={
          NON_AUTH_PAGES.includes(router.pathname) ? 'floating' : 'clipped'
        }
        {...(hasNav &&
          user && {
            treeProps: navTree
          })}
      >
        <MessageQueue id="global-message-queue" position="top" timeout={2000}>
          <AlertProvider id="global-alerts">
            {children}
            <Version />
            <ChangelogPanel
              open={showChangesPanel}
              setOpen={setShowChangesPanel}
            />
          </AlertProvider>
        </MessageQueue>
      </RMDLayout>
    </Configuration>
  );
}
