import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { getCurrentPermissions, hasPermission } from 'utils/permissions';
import { useBetween } from 'use-between';
import { useLocation, useUser } from 'hooks';

interface RequirePermissionProps {
  permissionDomain: PermissionDomainValue;
  action: PermissionActionValue;
  children: any;
  allowRoles?: string[];
  additionalPermissions?: string;
}

export const RequiresPermission = (props: RequirePermissionProps) => {
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const { user } = useUser();
  const {
    children,
    permissionDomain,
    action,
    allowRoles,
    additionalPermissions
  } = props;
  if (allowRoles && user) {
    if (allowRoles.includes(user.role)) {
      return children;
    }
  }
  let hasAccess =
    user && location
      ? hasPermission(user, location?.id!, permissionDomain, action)
      : false;
  if (hasAccess && additionalPermissions) {
    const currentPermission = getCurrentPermissions(
      { role: user?.role!, permissions: user?.permissions },
      location?.id!
    );
    hasAccess = currentPermission![permissionDomain][additionalPermissions];
  }
  if (!hasAccess) {
    return <div />;
  }
  return children;
};
