import Step from 'shepherd.js/src/types/step';

export interface ChangelogOptions extends Omit<Step.StepOptions, 'text'> {
  text: string;
  subtitle: string;
  hideInPanel?: boolean;
  image?: string;
}
export interface ChangeLog {
  [version: string]: ChangelogOptions[];
}
export const changeLogSteps: ChangeLog = {
  '4.2.3': [
    {
      id: 'filters',
      subtitle: 'Advanced Filters',
      text: 'We have added advanced filters to the reports page. You can now filter by multiple criteria at once, and save your filters for future use.'
    },
    {
      id: 'tests',
      subtitle: 'COA Scheduled Rules',
      text: 'Users with access to the Sample Schedule module can now create rules for your tests to automatically generate a Corrective Action schedule when any test result matching the rule is entered. You can choose to have schedules automatically created or require approval.'
    },
    {
      id: 'detailImages',
      subtitle: 'Detail Images',
      text: 'Ask us about adding detail images to your test results. This feature allows you to attach images to individual test results for a more complete picture of your testing.'
    },
    {
      id: 'bugfixes',
      subtitle: 'Platform Improvements',
      text: 'We continue to make improvements to the application to improve performance and fix bugs.'
    }
  ],
  '4.1.0': [
    {
      id: 'novalum',
      subtitle: 'novaLUM&reg; Integration',
      text: 'eBacMap&reg; now integrates with the Charm novaLUM II-X System to provide a more complete picture of your facility\'s sanitation status. novaLUM II-X is a hand-held luminometer used for pre-op sanitation verification (Pocketswab, Allergiene, Watergiene, Fieldswab), pasteurization verification, and pesticide detection. With this integration, you can now view novaLUM ATP data alongside your data microbial data in eBacMap&reg;. Automated Charm ATP reports are emailed directly to your Inbox, helping your sanitarians/quality managers monitor the factory\'s sanitation success in real time. <br />Interested to learn more? <a href="https://ebacmap.com/contact/" target="_blank" class="underline">Contact Us!</a>'
    },
    {
      id: 'bugfixes',
      subtitle: 'Platform Improvements',
      text: 'We have made quite a few improvements to the application to improve performance and fix bugs.'
    }
  ],
  '4.0.0': [
    {
      id: 'organisms',
      subtitle: 'Organisms --> Subcategory',
      text: 'Throughout the eBacMap application, "Organisms" have been replaced with "Subcategory". This change requires no action, only awareness when mapping and tracking.'
    },
    {
      id: 'notifications',
      subtitle: 'Get Notified',
      text: 'Choose what you want to know, and when you want to know it. Notifications setup and management have been moved from within Reports to their own section in the left navigation.',
      attachTo: {
        element: '[href="/notifications"]',
        on: 'right'
      },
      image: '/images/changelog/notifications.png'
    },
    {
      id: 'filters',
      subtitle: 'New Filters',
      image: '/images/changelog/filters.png',
      text: 'Reports are now more customizable than ever! Filter for all your trending needs - with the addition of site specific filter - all found in a new filter drawer at the top of the page.'
    },
    {
      id: 'column-preferences',
      subtitle: 'Choose What Matters',
      image: '/images/changelog/columnprefs.png',
      text: 'Column preferences will be set and saved with their corresponding reports and for test results. Choose to show or hide columns for a saved report or within the test results section, and eBacMap will remember.'
    },
    {
      id: 'facilities',
      subtitle: 'Facilities',
      text: 'For accounts with multiple facilities or locations, eBacMap now refers to each as a "Facility" rather than a "Location."  This is a change in terminology, not in functionality.',
      attachTo: {
        element: '#location-select',
        on: 'bottom'
      }
    },
    {
      id: 'changes',
      subtitle: 'Changes',
      text: 'Starting today you will see a new "Changes" section at the top of every page. This is where you will find all the latest updates and changes to eBacMap&reg;.',
      attachTo: {
        element: '#changes',
        on: 'bottom'
      },
      hideInPanel: true
    }
  ]
};
