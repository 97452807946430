import { Transition } from '@headlessui/react';
import { Workbox } from 'workbox-window';
import { useEffect, useState } from 'react';

const NewUpdateAlert = () => {
  let sw: ServiceWorkerContainer | undefined;

  if (typeof window !== 'undefined') {
    sw = window?.navigator?.serviceWorker;
  }

  const [show, setShow] = useState(false);
  const [wb, setWb] = useState<Workbox | null>(null);

  const confirmReload = () => {
    setShow(false);
    wb?.addEventListener('controlling', _event => {
      window.location.reload();
    });

    // Send a message to the waiting service worker, instructing it to activate.
    wb?.messageSkipWaiting();
  };

  useEffect(() => {
    if (sw) {
      const wbox = new Workbox('/sw.js', { scope: '/' });
      setWb(wbox);
      wbox.addEventListener('waiting', () => {
        setShow(true);
      });
      // never forget to call register as auto register is turned off in next.config.js
      wbox.register();

      // sw.register('/sw.js', { scope: '/' })
      //   .then(_registration => {})
      //   .catch(_err => {
      //     // console.log('Service Worker registration failed: ', err);
      //   });
    }
  }, [sw]);

  return (
    <Transition
      appear={true}
      enter="transition ease duration-500 transform"
      enterFrom="opacity-0 -translate-y-12"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-12"
      show={show}
    >
      <div className="fixed z-[9999] top-0 left-1/2 -translate-x-1/2 flex items-center gap-x-6 overflow-hidden bg-blue-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 rounded-sm">
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-900">
            <strong className="font-semibold">
              There are new updates available.
            </strong>
          </p>
          <button
            type="button"
            onClick={confirmReload}
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Load Updates!
          </button>
        </div>
      </div>
    </Transition>
  );
};
export default NewUpdateAlert;
