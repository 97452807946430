import Result from 'common/core/Result';

/** Configuration for validating a value. */
interface ValidateArgs {
  /** The value to validate. */
  value: any;
  /** The name of the value. Used in error messages. */
  name: string;
  /** Validate that the value is not undefined. */
  notUndefined?: boolean;
  /** Validate that the value is not null. */
  notNull?: boolean;
  /** Validate that the value is a string. */
  isString?: boolean;
  /** Validate that `value.length > 0`. */
  isNotEmpty?: boolean;
  /** Validate that the value matches a string or a regular expression. */
  matches?: {
    /** The pattern to match. */
    pattern: RegExp | string;
    /** The name of the pattern. Used in error messages */
    label: string;
  };
  /** Validate that the value has a minimum length. */
  minLength?: number;
  /** Validate that the value is contained in a list. */
  oneOf?: {
    /** The list of valid values. */
    list: readonly any[];
    /** The name of the list. Used in error messages. */
    label: string;
  };
  /** Validates that the value is greater than another value. */
  greaterThan?:
    | number
    | {
        /** The value to compare against. */
        value: number;
        /** The label to use in the error message. */
        label?: string;
      };
  /** Validates that the value is greater than or equal to another value. */
  greaterThanEqual?:
    | number
    | {
        /** The value to compare against. */
        value: number;
        /** The label to use in the error message. */
        label?: string;
      };
}

/** Error when validation fails. */
export class ValidationError extends Error {
  /** The property that failed validation. */
  readonly propName: string;

  /**
   * Constructs a new ValidationError with message and property name.
   * @param message Message describing failed validation.
   * @param propName The property that failed validation.
   */
  constructor(message: string, propName: string) {
    super(message);
    this.propName = propName;
    this.name = 'ValidationError';
  }
}

function notNullOrUndefined<T>(value: T | undefined | null): value is T {
  return typeof value !== 'undefined' && value !== null;
}

/**
 * Validates a list of values. All must pass validation for it to succeed.
 * @param args List of validations to perform.
 */
export default function validate(...args: ValidateArgs[]): Result<void> {
  for (const { value, name, ...validates } of args) {
    if (validates.notUndefined && value === undefined) {
      return Result.fail(
        new ValidationError(`${name} must not be undefined.`, name)
      );
    }
    if (validates.notNull && value === null) {
      return Result.fail(
        new ValidationError(`${name} must not be null.`, name)
      );
    }
    if (validates.isString && typeof value !== 'string') {
      return Result.fail(
        new ValidationError(`${name} must be a string.`, name)
      );
    }
    if (
      validates.isNotEmpty &&
      (value?.length === 'undefined' || value?.length === 0)
    ) {
      return Result.fail(
        new ValidationError(`${name} must not be empty.`, name)
      );
    }
    if (validates.matches && !String(value).match(validates.matches.pattern)) {
      return Result.fail(
        new ValidationError(`${name} must be ${validates.matches.label}.`, name)
      );
    }
    if (
      typeof validates.minLength === 'number' &&
      value.length < validates.minLength
    ) {
      return Result.fail(
        new ValidationError(
          `${name} must have a length of at least ${validates.minLength}.`,
          name
        )
      );
    }
    if (validates.oneOf && !validates.oneOf.list.includes(value)) {
      return Result.fail(
        new ValidationError(`${name} must be ${validates.oneOf.label}.`, name)
      );
    }
    if (notNullOrUndefined(validates.greaterThan)) {
      const compare =
        typeof validates.greaterThan === 'number'
          ? validates.greaterThan
          : validates.greaterThan.value;
      const label =
        typeof validates.greaterThan === 'number'
          ? compare
          : validates.greaterThan.label || compare;
      if (value <= compare) {
        return Result.fail(
          new ValidationError(`${name} must be greater than ${label}.`, name)
        );
      }
    }
    if (notNullOrUndefined(validates.greaterThanEqual)) {
      const compare =
        typeof validates.greaterThanEqual === 'number'
          ? validates.greaterThanEqual
          : validates.greaterThanEqual.value;
      const label =
        typeof validates.greaterThanEqual === 'number'
          ? compare
          : validates.greaterThanEqual.label || compare;
      if (value < compare) {
        return Result.fail(
          new ValidationError(
            `${name} must be greater than or equal to ${label}.`,
            name
          )
        );
      }
    }
  }
  return Result.ok();
}
