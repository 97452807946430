import {
  ArrowBackSVGIcon,
  ArrowDownwardSVGIcon,
  ArrowForwardSVGIcon,
  ArrowUpwardSVGIcon,
  AutorenewSVGIcon,
  ZoomInSVGIcon,
  ZoomOutSVGIcon
} from '@react-md/material-icons';
import { Loading } from 'components/Layout/Loading';
import { Tooltip } from 'components/Layout/Tooltip';
import { useBetween } from 'use-between';
import { useFilestack } from 'hooks/useFilestack';
import { useMapImage } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import styles from './mapImage.module.scss';

type MapImageT = {
  url: string;
  name: string;
  width?: number;
  height?: number;
  className?: any;
  handleMapClick?: (event: React.MouseEvent) => void;
  onImageLoad?: (isLoading: boolean) => void;
  allowScale?: boolean;
  mimeType: string;
  printing?: boolean;
  isThumb?: boolean;
};

const parseOldFilestackUrl = url => {
  const parts = url.split('/');
  return parts.pop();
};

export const MapImage = ({
  url,
  name,
  width,
  height,
  allowScale,
  mimeType,
  handleMapClick,
  onImageLoad,
  isThumb,
  ...rest
}: MapImageT) => {
  const useImage = () => useBetween(useMapImage);
  const {
    setImgRef,
    setRatio,
    setImgPosition,
    findPosition,
    zoom,
    setZoom,
    setOffsets
  } = useImage();

  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const { isSignatureValid, getImageUrl } = useFilestack();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [imageHandle, setImageHandle] = useState<string | undefined>();
  const [defaultSize, setDefaultSize] = useState({ width: 0, height: 0 });
  const [imgPos, setImgPos] = useState({ left: 0, top: 0 });
  const [isZooming, setIsZooming] = useState(false);
  const [reloadedOnError, setReloadedOnError] = useState(false);

  const handle =
    url.indexOf('cdn.filestackcontent') !== -1
      ? parseOldFilestackUrl(url)
      : url;
  useEffect(() => {
    if (!imageHandle || imageHandle !== handle) {
      setImageHandle(handle);
      setImageUrl(undefined);
    }
  }, [handle, imageHandle]);

  const size = `width:${width},height:${height}`;
  const getImage = async () => {
    const url = await getImageUrl(imageHandle!, size, mimeType);
    setImageUrl(url);
  };
  useEffect(() => {
    if (loading && imageUrl) {
      setLoading(false);
    }
    if (isSignatureValid && !imageUrl && !loading) {
      setLoading(true);
      getImage();
      if (allowScale) {
        resetZoom(null);
        setDefaultSize({
          width: 0,
          height: 0
        });
      }
    }
  }, [imageUrl, isSignatureValid, loading, imageHandle]);

  useEffect(() => {
    if (imgRef && imgRef.current && imgRef.current.complete) {
      setImgRef(imgRef.current);
      handleResize();
    }
  }, [imageUrl, imgRef]);

  const imageOnLoadHandler = () => {
    handleResize();
    if (onImageLoad) {
      setTimeout(() => {
        onImageLoad(true);
      }, 400);
    }
  };
  const imgLoadError = () => {
    if (!reloadedOnError) {
      getImage();
      setReloadedOnError(true);
    }
  };

  const getRatio = (newZoom: number) => {
    const isPrint =
      window && window.matchMedia && window.matchMedia('print').matches;

    if (imgRef && imgRef.current && containerRef && containerRef.current) {
      const containerWidth = isPrint ? 1024 : containerRef.current.offsetWidth;
      const ratio = Math.min(
        containerWidth / imgRef.current.naturalWidth,
        containerRef.current.offsetHeight / imgRef.current.naturalHeight
      );
      if (!isNaN(ratio)) {
        setRatio(ratio * newZoom);
      }
      //don't set the width greater than the image natural width
      const width =
        containerWidth < imgRef.current.naturalWidth
          ? containerWidth
          : imgRef.current.naturalWidth;
      setDefaultSize({
        width,
        height: imgRef.current.naturalHeight * ratio
      });
    }
  };

  const handleResize = () => {
    getRatio(zoom);
    setImgPosition(findPosition(imgRef.current));
  };

  useEffect(() => {
    setZoom(1);
    setOffsets({ top: 0, left: 0 });
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  const zoomIn = e => {
    e.preventDefault();
    const newZoom = zoom + 0.5;
    setZoom(newZoom);
    getRatio(newZoom);
    setIsZooming(true);
  };

  const zoomOut = e => {
    e.preventDefault();
    const newZoom = zoom - 0.5;
    if (newZoom >= 1) {
      setZoom(newZoom);
      getRatio(newZoom);
      setIsZooming(true);
    } else {
      setIsZooming(false);
    }
    //else need to handle disabling zoom out.
  };

  const jumpDistance = 50 * zoom;

  const updatePos = newPosition => {
    setImgPos(newPosition);
    setOffsets(newPosition);
  };

  const moveRight = currentPosition => {
    const newLeft = currentPosition.left - jumpDistance;
    const imgOffset =
      0 - (defaultSize.width * zoom - (containerRef.current?.clientWidth || 0));
    const left = newLeft > imgOffset ? newLeft : imgOffset;
    const top = currentPosition.top;
    updatePos({ left, top });
  };

  const moveLeft = currentPosition => {
    const newLeft = currentPosition.left + jumpDistance;
    const left = newLeft < 0 ? newLeft : 0;
    const top = currentPosition.top;

    updatePos({ left, top });
  };

  const moveDown = currentPosition => {
    const left = currentPosition.left;
    const newTop = currentPosition.top - jumpDistance;
    const imgOffset =
      0 -
      (defaultSize.height * zoom - (containerRef.current?.clientHeight || 0));
    const top = newTop > imgOffset ? newTop : imgOffset;

    updatePos({ left, top });
  };

  const moveUp = currentPosition => {
    const newTop = currentPosition.top + jumpDistance;
    const left = currentPosition.left;
    const top = newTop < 0 ? newTop : 0;

    updatePos({ left, top });
  };

  useEffect(() => {
    const keyDown = ev => {
      if (ev.code === 'ArrowLeft') {
        moveLeft(imgPos);
      } else if (ev.code === 'ArrowRight') {
        moveRight(imgPos);
      } else if (ev.code === 'ArrowDown') {
        moveDown(imgPos);
      } else if (ev.code === 'ArrowUp') {
        moveUp(imgPos);
      }
    };
    if (!isZooming) {
      window.removeEventListener('keydown', keyDown);
      return;
    }
    window.addEventListener('keydown', keyDown);
    return () => {
      window.removeEventListener('keydown', keyDown);
    };
  }, [imgPos, isZooming]);

  const resetZoom = e => {
    if (e) e.preventDefault();
    setZoom(1);
    getRatio(1);
    setImgPos({ left: 0, top: 0 });
    setIsZooming(false);
    setOffsets({ left: 0, top: 0 });
  };

  if (loading || !imageUrl) {
    return <Loading />;
  }
  if (!allowScale) {
    return (
      <>
        {!isThumb && <div style={{ height: '34.5px' }}></div>}
        <div className={styles.imageContainer} ref={containerRef}>
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img
            {...rest}
            src={imageUrl}
            alt={name}
            ref={imgRef}
            onLoad={imageOnLoadHandler}
            onError={imgLoadError}
            onClick={event =>
              handleMapClick ? handleMapClick(event) : () => {}
            }
          />
        </div>
      </>
    );
  }
  const isPrint = window.matchMedia('print').matches;

  const printStyle = {
    width: '1024px',
    height: 'auto',
    left: 0,
    top: 0
  };

  const imageStyle = {
    ...(defaultSize.width !== 0 && {
      width: defaultSize.width * zoom,
      height: defaultSize.height * zoom,
      left: imgPos.left,
      top: imgPos.top
    })
  };

  const containerStyle = {
    height: defaultSize.height || 'auto'
  };
  return (
    <>
      <div className={styles.zoomControls}>
        <Tooltip text="Zoom In">
          <a href="#" onClick={zoomIn}>
            <ZoomInSVGIcon />
          </a>
        </Tooltip>
        <Tooltip text="Zoom Out">
          <a href="#" onClick={zoomOut}>
            <ZoomOutSVGIcon />
          </a>
        </Tooltip>
        <Tooltip text="Reset Zoom">
          <a href="#" onClick={resetZoom}>
            <AutorenewSVGIcon />
          </a>
        </Tooltip>
        {isZooming && (
          <>
            <Tooltip text="Move Right (Arrow Right)">
              <a href="#" onClick={() => moveRight(imgPos)}>
                <ArrowForwardSVGIcon />
              </a>
            </Tooltip>
            <Tooltip text="Move Left (Arrow Left)">
              <a href="#" onClick={() => moveLeft(imgPos)}>
                <ArrowBackSVGIcon />
              </a>
            </Tooltip>
            <Tooltip text="Move Up (Arrow Up)">
              <a href="#" onClick={() => moveUp(imgPos)}>
                <ArrowUpwardSVGIcon />
              </a>
            </Tooltip>
            <Tooltip text="Move Down (Arrow Down)">
              <a href="#" onClick={() => moveDown(imgPos)}>
                <ArrowDownwardSVGIcon />
              </a>
            </Tooltip>
          </>
        )}
      </div>
      <div
        className={styles.imageContainer}
        ref={containerRef}
        style={containerStyle}
      >
        {/*eslint-disable-next-line @next/next/no-img-element*/}
        <img
          {...rest}
          className={styles.image}
          src={imageUrl}
          alt={name}
          ref={imgRef}
          onLoad={imageOnLoadHandler}
          onClick={handleMapClick}
          style={!isPrint ? imageStyle : printStyle}
        />
      </div>
    </>
  );
};
export default MapImage;
