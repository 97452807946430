import { DeleteSVGIcon, EditSVGIcon } from '@react-md/material-icons';
import React, { ReactElement, useState } from 'react';

import { ConfirmationModal } from 'components';
import { FacilityMapView } from 'modules/facility-maps/domain/FacilityMapView';
import { Tooltip } from 'components/Layout/Tooltip';
import { deleteFacilityMap } from 'services/facilityMaps';
import styles from './map-list.module.scss';
interface IMapActionsProps {
  map: FacilityMapView;
  getFacilityMaps: () => void;
  small?: boolean;
}

export function MapActions(props: IMapActionsProps): ReactElement {
  const { map, getFacilityMaps } = props;
  const [showModal, setShowModal] = useState<FacilityMapView | null>(null);

  const promptToDelete = () => {
    setShowModal(map!);
  };

  const confirmDelete = async () => {
    await deleteFacilityMap(showModal!.id!);
    getFacilityMaps();
    hideModal();
  };

  const hideModal = () => {
    setShowModal(null);
  };

  const actionBtnStyles = props.small
    ? styles.mapActionBtnSmall
    : styles.mapActions;
  return (
    <div className="flex">
      <a href={`/facility-maps/${map.id}`}>
        <Tooltip text="Edit">
          <EditSVGIcon className={actionBtnStyles} />
        </Tooltip>
      </a>
      <Tooltip text="Delete">
        <DeleteSVGIcon className={actionBtnStyles} onClick={promptToDelete} />
      </Tooltip>
      <ConfirmationModal
        title="Confirm Removal"
        copy={
          showModal !== null
            ? `Are you sure you want to remove ${showModal.title} map?`
            : ''
        }
        confirmCallback={confirmDelete}
        visible={showModal !== null}
        onRequestClose={hideModal}
      />
    </div>
  );
}
