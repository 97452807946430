import { FetchService } from './FetchService';
import { NotificationConfig } from 'modules/notifications/domain/Notification';
import { NotificationPaginateResponse } from 'modules/notifications/repos/NotificationRepo';
import { PaginateParamsT } from 'common/PaginationTypes';
import { UserNotificationView } from 'modules/notifications/domain/UserNotificationView';
import fetchJson from 'utils/fetchJson';

export const getNotifications = async (): Promise<UserNotificationView[]> => {
  const response = await fetchJson('/api/notifications', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return response;
};

export const markRead = async (notificationIds: string[]): Promise<void> => {
  const response = await fetchJson(`/api/notifications`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      read: true,
      notificationIds
    })
  });
  return response;
};

export const addSubscription = async (
  subscription: PushSubscription
): Promise<void> => {
  const response = await fetchJson(`/api/notifications/subscribe`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ subscription: subscription.toJSON() })
  });
  return response;
};

export type NotificationFilterParamsT = PaginateParamsT & {
  location: string;
  query?: string;
};

export const paginateNotifications = (
  request: NotificationFilterParamsT
): Promise<NotificationPaginateResponse> =>
  FetchService<NotificationPaginateResponse, NotificationFilterParamsT>(
    'GET',
    '/api/notifications/list',
    request
  );

export const createNotification = async (notification: NotificationConfig) => {
  const response = await fetchJson(`/api/notifications/config`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(notification)
  });
  return response;
};

export const updateNotification = async (
  id: string,
  notification: NotificationConfig
) => {
  const response = await fetchJson(`/api/notifications/config/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(notification)
  });
  return response;
};

export const deleteNotification = async (id: string) => {
  const response = await fetchJson(`/api/notifications/config/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  });
  return response;
};
