export enum PermissionDomainValue {
  User = 'users',
  Site = 'sites',
  Billing = 'billing',
  Category = 'categories',
  Organism = 'organisms',
  Zones = 'zones',
  Test = 'tests',
  FacilityMap = 'facilityMaps',
  Report = 'reports',
  TestResult = 'testResults',
  Ebacmap = 'ebacmap',
  SwabSchedule = 'swabSchedule',
  Novalum = 'novalum',
  Capa = 'capa',
  Tour = 'tour'
}
