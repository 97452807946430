import { Button } from '@react-md/button';
import { CircularProgress } from '@react-md/progress';
import { DropdownMenu, MenuItem } from '@react-md/menu';
import React, { ReactElement, useState } from 'react';
import styles from './buttons.module.scss';

interface CustomButtonProps {
  label?: string;
  ariaLabel: string;
  saving: boolean;
  disabled?: boolean;
  id?: string;
  children?: any;
  allowDuplicate?: boolean;
  hideSaveAndContinue?: boolean;
  onClick: (doSave: boolean, dupVal: boolean) => void;
}

export const SaveAndBackButton = React.memo(function SaveAndBackButtonComponent(
  props: CustomButtonProps
): ReactElement {
  const [saveAndBack, setSaveAndBack] = useState(true);
  const [duplicate, setDuplicate] = useState(false);
  const {
    label,
    id,
    ariaLabel,
    children,
    saving,
    onClick,
    disabled,
    allowDuplicate,
    hideSaveAndContinue
  } = props;
  const buttonLabel = label || children || 'Save';
  const items = [
    !hideSaveAndContinue && {
      children: buttonLabel,
      onClick: () => {
        setSaveAndBack(false);
        onClick(false, false);
      }
    },
    {
      children: `${buttonLabel} And Back`,
      onClick: () => {
        setSaveAndBack(true);
        onClick(true, false);
      }
    },
    ...(allowDuplicate
      ? [
          {
            children: `${buttonLabel} And Duplicate`,
            onClick: () => {
              setSaveAndBack(false);
              setDuplicate(true);
              onClick(false, true);
            }
          }
        ]
      : [])
  ];

  return (
    <div
      id={id}
      className={disabled ? styles.submitBtnDisabled : styles.submitBtn}
    >
      <Button
        theme="primary"
        type="submit"
        aria-label={ariaLabel}
        className={styles.buttonWithDropdown}
        onClick={() => onClick(saveAndBack, duplicate)}
        disabled={saving || disabled}
      >
        {saveAndBack
          ? `${buttonLabel} And Back`
          : duplicate
          ? `${buttonLabel} And Duplicate`
          : buttonLabel}
      </Button>
      <DropdownMenu
        buttonChildren=""
        className={styles.dropdown}
        id="save_options"
      >
        {items.map((item, i) => (
          <MenuItem key={i} {...item} />
        ))}
      </DropdownMenu>
      {saving && (
        <span className={styles.overlay}>
          <CircularProgress id={`${id}-progress`} />
        </span>
      )}
    </div>
  );
});
