import { LocationPaginateResponse } from 'modules/locations/repos/LocationRepo';
import { LocationView } from 'modules/locations/domain/LocationView';
import { MenuItem } from '@react-md/menu';
import { NavActionDropdownMenu } from './NavActionDropdownMenu';
import { NotificationsMenuItem } from './NotificationsMenuItem';
import { UserRoleValue } from 'modules/users/domain/UserRole';
import { getUnique } from 'utils/array';
import { paginateLocations } from 'services/locations';
import { useBetween } from 'use-between';
import { useLocation, useUser } from 'hooks';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useState } from 'react';
import useService from 'hooks/useService';

const usesLocations = user =>
  Boolean(
    user?.role !== UserRoleValue.Admin &&
      user?.role !== UserRoleValue.CustomerSupport
  );

export interface AppBarActionsProps {
  showChangesPanel?: boolean;
  setShowChangesPanel?: (showChangesPanel: boolean) => void;
}

export const AppBarActions = (props: AppBarActionsProps) => {
  const { user, logout } = useUser();
  const [locations, setLocations] = useState<LocationView[]>([]);

  const router = useRouter();

  const [location, setCurrentLocation] = useBetween(useLocation);

  const handleLocationSelect = loc => {
    if (!loc) return;
    setCurrentLocation(loc, true);
  };

  const { data: locationsResponse, isLoading } =
    useService<LocationPaginateResponse>(
      user && usesLocations(user)
        ? `/api/locations/?offset=0&limit=-1&sort=name:1`
        : null,
      () =>
        paginateLocations({
          offset: 0,
          limit: -1,
          sort: `name:1`
        })
    );

  useEffect(() => {
    if (locationsResponse && locationsResponse.locations) {
      let locs = locationsResponse.locations;
      const globalLocation = {
        active: false,
        id: 'global',
        name: 'Global',
        clientId: user!.client!,
        slug: 'GLO',
        tourEnabled: false
      };
      if (locs.findIndex(l => l.id === 'global') === -1) {
        locs.push(globalLocation);
      }
      setLocations(locs);
    }
  }, [locationsResponse, isLoading]);

  useEffect(() => {
    if (user && usesLocations(user)) {
      if (!location && locations.length) {
        handleLocationSelect(locations[0]);
      } else if (user?.locations && !location) {
        if (user?.locations.length) {
          handleLocationSelect(user?.locations[0]);
        }
      } else if (location && !locations.find(l => l.id === location.id)) {
        handleLocationSelect(locations[0]);
      }
    } else {
      handleLocationSelect(null);
    }
  }, [user, locations]);

  const currentLocationName = usesLocations(user)
    ? location
      ? location.name
      : locations && locations.length
      ? locations[0].name
      : ''
    : '';

  const locationItems =
    usesLocations(user) && locations
      ? getUnique([...(locations || [])], 'id').map(loc => {
          return (
            <MenuItem key={loc.id} onClick={() => handleLocationSelect(loc)}>
              {loc.name}
            </MenuItem>
          );
        })
      : [];

  if (!user) {
    return null;
  }

  const doLogout = () => {
    logout();
  };

  const username = user ? `${user.firstName} ${user.lastName}` : '';
  const profileItems = (): ReactNode[] => {
    return [
      <MenuItem key="profile" onClick={() => router.push('/profile')}>
        My Profile
      </MenuItem>,
      user.role === 'company_admin' && (
        <MenuItem
          key="company-profile"
          onClick={() => router.push('/admin/company-profile')}
        >
          Company Profile
        </MenuItem>
      ),
      <MenuItem key="logout" onClick={() => doLogout()}>
        Logout
      </MenuItem>
    ];
  };

  return (
    <>
      <NotificationsMenuItem key="notification-menu" {...props} />
      {usesLocations(user) && (
        <NavActionDropdownMenu
          id="location-select"
          title={`Facility: ${currentLocationName}`}
          items={locationItems}
        />
      )}
      <NavActionDropdownMenu
        id="profile-select"
        last
        title={username}
        items={profileItems()}
      />
    </>
  );
};
