import {
  AddAlertSVGIcon,
  AssessmentSVGIcon,
  AssignmentSVGIcon,
  AssignmentTurnedInSVGIcon,
  BuildSVGIcon,
  DeveloperModeSVGIcon,
  EventSVGIcon,
  FilterTiltShiftSVGIcon,
  HomeSVGIcon,
  LocationCitySVGIcon,
  PersonOutlineSVGIcon,
  TvSVGIcon
} from '@react-md/material-icons';
import { ExploreSVGIcon } from 'react-md';
import { LayoutNavigationTree } from '@react-md/layout';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import React, { ReactNode } from 'react';
import filterByAccess, {
  RestrictedLayoutNavigationItem
} from 'hooks/filterByAccess';

interface CreateRouteT {
  pathname: string;
  label: string;
  as?: string | null;
  leftAddon?: ReactNode;
  hasChildren?: boolean;
  parentId?: string | null;
  permissionDomain?: PermissionDomainValue;
  roles?: string[];
  action?: PermissionActionValue;
  target?: string;
  nonGlobal?: boolean;
  id?: string;
}
/**
 * Note: The `parentId` **must** be defaulted to `null` for the navigation tree
 * to render correctly since this uses the @react-md/tree package behind the
 * scenes. Each item that has a `parentId` set to `null` will appear at the root
 * level of your navigation tree.
 */
function createRoute(props: CreateRouteT): RestrictedLayoutNavigationItem {
  const {
    pathname,
    label,
    as = null,
    leftAddon,
    hasChildren = false,
    parentId = null,
    permissionDomain,
    action,
    roles,
    target,
    nonGlobal
  } = props;
  return {
    itemId: pathname,
    parentId,
    href: !hasChildren ? pathname : undefined,
    as,
    children: label,
    leftAddon,
    permissionDomain,
    action,
    roles,
    target,
    nonGlobal
  };
}

const navItems: LayoutNavigationTree<RestrictedLayoutNavigationItem> = {
  '/': createRoute({
    pathname: '/',
    label: 'Dashboard',
    leftAddon: <HomeSVGIcon />
  }),
  '/reports': createRoute({
    pathname: '/reports',
    label: 'Reports',
    leftAddon: <AssignmentSVGIcon />,
    permissionDomain: PermissionDomainValue.Report,
    action: PermissionActionValue.View
  }),
  '/ebacmap': createRoute({
    pathname: '/ebacmap',
    label: 'eBacMap',
    leftAddon: <FilterTiltShiftSVGIcon />,
    permissionDomain: PermissionDomainValue.Ebacmap,
    action: PermissionActionValue.View
  }),
  '/test-results': createRoute({
    pathname: '/test-results',
    label: 'Test Results',
    leftAddon: <AssessmentSVGIcon />,
    permissionDomain: PermissionDomainValue.TestResult,
    action: PermissionActionValue.View
  }),
  '/test-results/new': createRoute({
    pathname: '/test-results/new',
    label: 'New',
    parentId: '/test-results',
    permissionDomain: PermissionDomainValue.TestResult,
    action: PermissionActionValue.Manage
  }),
  '/test-results/import': createRoute({
    pathname: '/test-results/import',
    label: 'Import',
    parentId: '/test-results',
    permissionDomain: PermissionDomainValue.TestResult,
    action: PermissionActionValue.Manage
  }),
  '/sample-scheduler': createRoute({
    pathname: '/sample-scheduler',
    label: 'Sample Scheduler',
    leftAddon: <EventSVGIcon />,
    hasChildren: true,
    permissionDomain: PermissionDomainValue.SwabSchedule,
    action: PermissionActionValue.View
  }),
  '/sample-scheduler/calendar': createRoute({
    pathname: '/sample-scheduler/calendar',
    label: 'Calendar',
    parentId: '/sample-scheduler',
    permissionDomain: PermissionDomainValue.SwabSchedule,
    action: PermissionActionValue.View
  }),
  '/sample-scheduler/schedule': createRoute({
    pathname: '/sample-scheduler/schedule',
    label: 'Schedule',
    parentId: '/sample-scheduler',
    permissionDomain: PermissionDomainValue.SwabSchedule,
    action: PermissionActionValue.Manage
  }),
  '/capa': createRoute({
    pathname: '/capa',
    label: 'CAPA',
    leftAddon: <AssignmentTurnedInSVGIcon />,
    hasChildren: true,
    permissionDomain: PermissionDomainValue.Capa,
    action: PermissionActionValue.View
  }),
  '/capa/non-conformances': createRoute({
    pathname: '/capa/non-conformances',
    label: 'Nonconformances',
    parentId: '/capa',
    permissionDomain: PermissionDomainValue.Capa,
    action: PermissionActionValue.View
  }),
  '/capa/reports': createRoute({
    pathname: '/capa/reports',
    label: 'Reports',
    parentId: '/capa',
    permissionDomain: PermissionDomainValue.Capa,
    action: PermissionActionValue.View
  }),
  '/capa/new': createRoute({
    pathname: '/capa/new',
    label: 'New',
    parentId: '/capa',
    permissionDomain: PermissionDomainValue.Capa,
    action: PermissionActionValue.View
  }),
  '/facility-maps': createRoute({
    pathname: '/facility-maps',
    label: 'Facility Maps',
    leftAddon: <LocationCitySVGIcon />,
    permissionDomain: PermissionDomainValue.FacilityMap,
    action: PermissionActionValue.View
  }),
  '/tours': createRoute({
    pathname: '/tours',
    label: 'Tours',
    leftAddon: <ExploreSVGIcon />,
    permissionDomain: PermissionDomainValue.Tour,
    action: PermissionActionValue.View
  }),
  '/notifications': createRoute({
    pathname: '/notifications',
    label: 'Notifications',
    leftAddon: <AddAlertSVGIcon />,
    permissionDomain: PermissionDomainValue.Report,
    action: PermissionActionValue.View,
    nonGlobal: true,
    id: 'notifications-menu-item'
  }),
  '/setup': createRoute({
    pathname: '/setup',
    label: 'Setup',
    leftAddon: <BuildSVGIcon />,
    hasChildren: true,
    permissionDomain: PermissionDomainValue.Category,
    action: PermissionActionValue.Manage
  }),
  '/setup/categories': createRoute({
    pathname: '/setup/categories',
    label: 'Categories',
    hasChildren: false,
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.Category,
    action: PermissionActionValue.Manage
  }),
  '/setup/subcategories': createRoute({
    pathname: '/setup/subcategories',
    label: 'Subcategories',
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.Organism,
    action: PermissionActionValue.Manage
  }),
  '/setup/tests': createRoute({
    pathname: '/setup/tests',
    label: 'Tests',
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.Test,
    action: PermissionActionValue.Manage
  }),
  '/setup/zones': createRoute({
    pathname: '/setup/zones',
    label: 'Zones',
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.Zones,
    action: PermissionActionValue.Manage
  }),
  '/setup/sites': createRoute({
    pathname: '/setup/sites',
    label: 'Sites',
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.Site,
    action: PermissionActionValue.Manage
  }),
  '/setup/groups': createRoute({
    pathname: '/setup/groups',
    label: 'Site Groups',
    parentId: '/setup',
    permissionDomain: PermissionDomainValue.SwabSchedule,
    action: PermissionActionValue.Manage
  }),
  '/novalum': createRoute({
    pathname: '/novalum',
    label: 'novaLum',
    leftAddon: <DeveloperModeSVGIcon />,
    hasChildren: true,
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.View
  }),
  '/novalum/units': createRoute({
    pathname: '/novalum/units',
    label: 'Units',
    hasChildren: false,
    parentId: '/novalum',
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.Manage
  }),
  '/novalum/corrective-notes': createRoute({
    pathname: '/novalum/corrective-notes',
    label: 'Corrective Notes',
    hasChildren: false,
    parentId: '/novalum',
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.Manage
  }),
  // '/novalum/test-types': createRoute({
  //   pathname: '/novalum/test-types',
  //   label: 'Test Types',
  //   hasChildren: false,
  //   parentId: '/novalum',
  //   permissionDomain: PermissionDomainValue.Novalum,
  //   action: PermissionActionValue.Manage
  // }),
  '/novalum/surfaces': createRoute({
    pathname: '/novalum/surfaces',
    label: 'Surfaces',
    hasChildren: false,
    parentId: '/novalum',
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.Manage
  }),
  '/novalum/plans': createRoute({
    pathname: '/novalum/plans',
    label: 'Plans',
    hasChildren: false,
    parentId: '/novalum',
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.Manage
  }),
  // '/novalum/plans/new': createRoute({
  //   pathname: '/novalum/plans/new',
  //   label: 'New',
  //   parentId: '/novalum/plans',
  //   permissionDomain: PermissionDomainValue.Novalum,
  //   action: PermissionActionValue.Manage
  // }),
  // '/novalum/plans/import': createRoute({
  //   pathname: '/novalum/plans/import',
  //   label: 'Import',
  //   parentId: '/novalum/plans',
  //   permissionDomain: PermissionDomainValue.Novalum,
  //   action: PermissionActionValue.Manage
  // }),
  '/novalum/sites': createRoute({
    pathname: '/novalum/sites',
    label: 'Sites',
    hasChildren: false,
    parentId: '/novalum',
    permissionDomain: PermissionDomainValue.Novalum,
    action: PermissionActionValue.Manage
  }),
  '/training': createRoute({
    pathname: 'https://ebacmap.com/ebacmap-3-0-training/',
    label: 'Training',
    leftAddon: <TvSVGIcon />,
    target: '_blank'
  }),
  // '/training/videos': createRoute({
  //   pathname: '/training/videos',
  //   label: 'Videos',
  //   parentId: '/training'
  // }),
  '/admin': createRoute({
    pathname: '/admin',
    label: 'Admin',
    leftAddon: <PersonOutlineSVGIcon />,
    hasChildren: true,
    roles: ['company_admin', 'facility_admin', 'restricted_user']
  }),
  '/admin/company-profile': createRoute({
    pathname: '/admin/company-profile',
    label: 'Company Profile',
    parentId: '/admin',
    roles: ['company_admin', 'restricted_user']
  }),
  '/admin/facilities': createRoute({
    pathname: '/admin/facilities',
    label: 'Facilities',
    parentId: '/admin',
    roles: ['company_admin', 'facility_admin']
  }),
  '/admin/users': createRoute({
    pathname: '/admin/users',
    label: 'Users',
    parentId: '/admin',
    permissionDomain: PermissionDomainValue.User,
    action: PermissionActionValue.Manage
  }),
  ...((process.env.NEXT_PUBLIC_BILLING_DISABLED === 'false' ||
    !process.env.NEXT_PUBLIC_BILLING_DISABLED) && {
    '/admin/billing': createRoute({
      pathname: '/admin/billing',
      label: 'Billing',
      parentId: '/admin',
      permissionDomain: PermissionDomainValue.Billing,
      action: PermissionActionValue.Manage
    })
  })
};

export default function getNavItems(user, location) {
  if (!user) {
    return {};
  }
  return filterByAccess(user, navItems, location);
}
