import { CreateLocationRequest } from 'modules/locations/use-cases/CreateLocation/UseCase';
import { FetchService } from './FetchService';
import { LocationPaginateResponse } from 'modules/locations/repos/LocationRepo';
import { LocationView } from 'modules/locations/domain/LocationView';
import { PaginateParamsT } from 'common/PaginationTypes';
import { UpdateLocationRequest } from 'modules/locations/use-cases/UpdateLocation/UseCase';
import fetchJson from 'utils/fetchJson';

export const paginateLocations = async (
  params: PaginateParamsT
): Promise<LocationPaginateResponse> => {
  const { limit, offset, sort, ...rest } = params;
  const url = `/api/locations?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    ...(sort && { sort: sort }),
    ...rest
  })}`;
  const locations = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return locations;
};

export const addLocation = async (
  data: CreateLocationRequest
): Promise<LocationView> => {
  const newLocation = await fetchJson(
    '/api/locations',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...data
      })
    },
    'Facility'
  );
  return newLocation;
};

export const updateLocation = async (
  id: string,
  data: UpdateLocationRequest
): Promise<LocationView> => {
  const newLocation = await fetchJson(
    `/api/locations/${id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...data
      })
    },
    'Facility'
  );
  return newLocation;
};

export const getLocationById = (url: string) =>
  FetchService<LocationView, void>('GET', url);

export const getLocationsByClient = async (
  client: string
): Promise<LocationPaginateResponse> => {
  const url = `/api/locations?${new URLSearchParams({
    limit: '-1',
    offset: '0',
    client
  })}`;
  const locations = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return locations;
};
