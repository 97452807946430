import { Store, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from 'fast-redux';
import thunkMiddleware from 'redux-thunk';
import withRedux, { createWrapper } from 'next-redux-wrapper';

export const initStore = initialState => {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
};
const debug =
  process.env.APP_ENV === 'development' ||
  process.env.APP_ENV === 'test' ||
  process.env.APP_ENV === 'local';

const wrapper = createWrapper<Store<any>>(initStore, { debug });

export const sharedReduxPage = comp => withRedux(initStore)(comp);
export const reduxPage = comp => wrapper.withRedux(comp);
