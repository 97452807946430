import { ClientAddon } from 'tools/dtos';
import { LocationView } from 'modules/locations/domain/LocationView';
import { ROLES_NEEDING_PERMISSION } from 'config/constants';
import { UserPermissionView } from 'modules/users/domain/UserPermission';
import { UserView } from 'modules/users/domain/UserView';

export interface RolePermission {
  role: string;
  permission: string;
  subPermission?: string;
}

export function hasRequiredRole(
  roles: (string | RolePermission)[],
  role: string
): boolean {
  if (roles[0] === '*') {
    return true;
  }
  const hasRoles = roles.filter(r => {
    if (typeof r === 'string') {
      return r === role;
    }
    return r.role === role;
  });
  return Boolean(hasRoles.length);
}

export function hasRequiredPermissions(
  roles: (string | RolePermission)[],
  role: string,
  userPermission?: UserPermissionView
) {
  if (!userPermission) {
    return false; //for now if permissions isn't set just allow them if they have the role
  }
  const hasPermissions = roles.filter(r => {
    if (typeof r === 'string') {
      return r === role;
    }
    //check if they have a valid permissions
    if (r.role === role) {
      if (
        userPermission[r.permission] &&
        typeof userPermission[r.permission] === 'boolean'
      ) {
        return true;
      }
      return userPermission[r.permission].enabled;
    }
    return false;
  });
  return Boolean(hasPermissions.length);
}

export const userCanAccessPage = (
  user: UserView,
  requiredRoles: (string | RolePermission)[],
  currentLocation?: LocationView
): boolean => {
  if (!user.role) return false;
  if (user.role === 'admin') return true;
  if (
    user.role === 'restricted_user' &&
    user.auAdmin &&
    requiredRoles.includes('restricted_user') &&
    user.client === currentLocation?.clientId
  ) {
    return true;
  } else if (user.role === 'restricted_user') {
    return false;
  }
  if (requiredRoles[0] === '*') {
    return true;
  }
  const hasRole = hasRequiredRole(requiredRoles, user.role);
  const hasPermissions = ROLES_NEEDING_PERMISSION.includes(user.role)
    ? hasRequiredPermissions(
        requiredRoles,
        user.role,
        currentLocation && user.permissions
          ? user.permissions.find(p => p.location.id === currentLocation.id)
          : undefined
      )
    : true;
  return Boolean(hasRole && hasPermissions);
};

export const hasRequiredAddon = (
  addons: ClientAddon[],
  requiredAddon: string
): boolean => {
  const found = addons.find(a => a.product === requiredAddon);
  return Boolean(found);
};
