import EnumValueObject from 'common/core/EnumValueObject';

export enum FilterRuleFieldValues {
  Category = 'category',
  Site = 'site',
  Zone = 'zone',
  Organism = 'organism',
  TestDefinition = 'testDefinition',
  SurfaceType = 'surfaceTypeId',
  Plan = 'planId',
  Channel = 'channel',
  ResultRating = 'resultRating',
  Group = 'group',
  FacilityMap = 'facilityMap',
  ResultValue = 'resultValue',
  TestNum = 'testNum',
  ResultType = 'resultType'
}

export const FIELDS: string[] = Object.values(FilterRuleFieldValues);

export default class FilterRuleFieldValue extends EnumValueObject(
  FilterRuleFieldValues,
  'field'
) {}
