import '../styles/app.scss';
import '../styles/globals.css';
import { AppLayout } from '../components/Layout';
import { AppLogo } from '../components/Layout/AppLogo';
import { AppProps } from 'next/app';
import { AppSizeListener } from '@react-md/utils';
import { Configuration } from '@react-md/layout';
import { CrossFade } from '@react-md/transition';
import { ErrorBoundary } from '@sentry/react';
import { LinearProgress } from '@react-md/progress';
import { Loading } from 'components/Layout/Loading';
import { Provider } from 'react-redux';
import { initStore } from 'hooks/withRedux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import NewUpdateAlert from 'components/Alerts/NewUpdateAlert';
import React, {
  ReactElement,
  Suspense,
  useEffect,
  useRef,
  useState
} from 'react';
import packageJson from '../package.json';

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const rendered = useRef(false);
  const [inMaintenanceMode] = useState(false);

  const handleStart = () => {
    setLoading(true);
  };
  const handleStop = () => {
    setLoading(false);
    // checkForMaintenanceMode();
  };

  // const checkForMaintenanceMode = async () => {
  //   const response = await fetch('/api/maintenance');
  //   const { maintenanceMode } = await response.json();
  //   setInMaintenanceMode(maintenanceMode);
  // };

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  // useEffect(() => {
  //   if (router) {
  //     if (inMaintenanceMode && !router.pathname.includes('maintenance')) {
  //       router.push('/maintenance');
  //     } else if (
  //       !inMaintenanceMode &&
  //       router.pathname.includes('/maintenance')
  //     ) {
  //       router.push('/');
  //     }
  //   }
  // }, [router, inMaintenanceMode]);

  useEffect(() => {
    rendered.current = true;
  }, []);

  function FallbackComponent() {
    return (
      <div style={{ padding: '30px', textAlign: 'center' }}>
        <AppLogo />
        An error has occurred. Please refresh the page. If the problem persists,
        please contact{' '}
        <a href="mailto: customerservice@ebacmap.com">support.</a>
      </div>
    );
  }

  const myFallback = <FallbackComponent />;

  return (
    <AppSizeListener>
      <ErrorBoundary fallback={myFallback} showDialog>
        <Suspense fallback={<Loading />}>
          <Provider store={initStore({ version: packageJson.version })}>
            <Head>
              <title>eBacMap</title>
            </Head>
            {inMaintenanceMode ? (
              <>
                <meta name="robots" content="noindex" />
                <Configuration>
                  <Component {...pageProps} />
                </Configuration>
              </>
            ) : (
              <AppLayout>
                <NewUpdateAlert />
                {loading && (
                  <LinearProgress
                    id="simple-linear-progress"
                    color={'secondary'}
                  />
                )}
                <CrossFade key={router.pathname} appear={rendered.current}>
                  <div>
                    <Component {...pageProps} />
                  </div>
                </CrossFade>
              </AppLayout>
            )}
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </AppSizeListener>
  );
}
