import React, { useState } from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import endOfDay from 'date-fns/endOfDay';
import isBefore from 'date-fns/isBefore';
import moment from 'moment';
import startOfDay from 'date-fns/startOfDay';

export interface SelectedDateRange {
  startDate?: Date;
  endDate?: Date;
}
type DateRangeProps = {
  value?: Date;
  startDate: Date | null;
  endDate: Date | null;
  disabled?: boolean;
  onDatesChange: (range: SelectedDateRange) => void;
};

export const DateRange = (props: DateRangeProps) => {
  const { startDate, endDate, onDatesChange } = props;
  const [focusedInput, setFocusedInput] = useState();
  return (
    <DateRangePicker
      startDate={
        startDate && moment(startDate).isValid() ? moment(startDate) : null
      }
      startDateId={'startDate'}
      endDateId={'endDate'}
      endDate={endDate && moment(endDate).isValid() ? moment(endDate) : null}
      onDatesChange={({ startDate, endDate }) => {
        if (
          startDate === '' ||
          startDate === null ||
          !moment(startDate).isValid()
        ) {
          return onDatesChange({
            startDate: undefined,
            endDate: undefined
          });
        }
        return onDatesChange({
          startDate: moment(startDate).toDate(),
          endDate: endDate ? moment(endDate).toDate() : undefined
        });
      }}
      focusedInput={focusedInput}
      onFocusChange={input => setFocusedInput(input)}
      noBorder={true}
      small={true}
      isOutsideRange={day => {
        const today = endOfDay(new Date());
        const dayFormatted = startOfDay(new Date(day));
        return isBefore(today, dayFormatted);
      }}
      minimumNights={0}
      displayFormat={'MM/DD/YYYY'}
      startDatePlaceholderText={'MM/DD/YYYY'}
      endDatePlaceholderText={'MM/DD/YYYY'}
      disabled={props.disabled}
    />
  );
};
