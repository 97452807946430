import { Button } from '@react-md/button';
import { Card, CardActions, CardContent } from '@react-md/card';
import { DateRange, SelectedDateRange } from './DateRange';
import { Overlay } from '@react-md/overlay';
import { ScrollListener, getFixedPosition } from '@react-md/utils';
import { Select } from '@react-md/form';
import { TYPES } from 'modules/reports/domain/ReportDateRangeType';
import { Tooltip } from 'components/Layout/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import styles from './filter-bar.module.scss';

interface DateRangeT {
  type: string;
  start?: Date;
  end?: Date;
  options?: any[];
  disabled?: boolean;
  onDatesChange: (dateRange) => void;
}

export const ReportDateRange = (props: DateRangeT) => {
  const [showingDates, setShowingDates] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [typeVal, setTypeVal] = useState<string>(props.type || 'ytd');
  const cardRef = useRef(null);
  const selectRef = useRef(null);
  const [style, setStyle] = useState<any>();

  const handleTypeChange = (type: string) => {
    setTypeVal(type.toLowerCase());
    props.onDatesChange({ type: type.toLowerCase() });
    if (type === 'custom' || type === 'Custom') {
      setShowingDates(true);
    } else {
      setShowingDates(false);
    }
  };
  const checkToShowDates = () => {
    if ((props.type === 'custom' || props.type === 'Custom') && !showingDates) {
      setShowingDates(true);
    } else {
      setShowingDates(false);
    }
  };

  const onDateChange = ({ startDate, endDate }: SelectedDateRange) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
    if (startDate && endDate) {
      setShowingDates(false);
      props.onDatesChange({
        type: 'custom',
        start: startDate,
        end: endDate
      });
    }
  };
  const updatePosition = useCallback(() => {
    const newStyle = getFixedPosition({
      container: selectRef.current,
      element: cardRef.current
    }).style;

    setStyle(newStyle);
  }, []);
  const cardRefCB = useCallback(card => {
    cardRef.current = card;
    updatePosition();
    // only want to call this once on mount
  }, []);

  useEffect(() => {
    if (props.type) {
      setTypeVal(props.type.toLowerCase());
    }
    if (props.start) {
      if (props.start instanceof Date) {
        setStartDate(props.start);
      } else {
        setStartDate(parseJSON(props.start));
      }
    }
    if (props.end) {
      if (props.end instanceof Date) {
        setEndDate(props.end);
      } else {
        setEndDate(parseJSON(props.end));
      }
    }
  }, [props.type, props.start, props.end]);

  const isCustomDate = typeVal === 'custom';

  return (
    <>
      <Select
        id={'dateRangeType'}
        name={'type'}
        value={typeVal.charAt(0).toUpperCase() + typeVal.slice(1)}
        options={
          props.options
            ? props.options.map(
                option => option.charAt(0).toUpperCase() + option.slice(1)
              )
            : TYPES.map(type => type.charAt(0).toUpperCase() + type.slice(1))
        }
        placeholder={'Date Range'}
        theme="underline"
        className={styles.filterInput}
        onChange={handleTypeChange}
        onClick={checkToShowDates}
        ref={selectRef}
        disabled={props.disabled}
      />
      {isCustomDate && startDate && endDate && (
        <div className={styles.customDate}>{`${format(
          startDate,
          'MM/dd/yyyy'
        )} - ${format(endDate, 'MM/dd/yyyy')}`}</div>
      )}
      <Overlay
        id="date-range-overlay"
        visible={showingDates}
        className={styles.dateOverlay}
        onRequestClose={() => {}}
      >
        <ScrollListener onScroll={updatePosition} />
        <Card style={style} ref={cardRefCB} role="alertdialog">
          <CardContent>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onDatesChange={onDateChange}
              disabled={props.disabled}
            />
          </CardContent>
          <CardActions className={styles.mapCardActions}>
            <Tooltip text="Cancel">
              <Button
                aria-label="Cancel"
                onClick={() => setShowingDates(false)}
              >
                Cancel
              </Button>
            </Tooltip>
          </CardActions>
        </Card>
      </Overlay>
    </>
  );
};
