import { DoneFontIcon } from '@react-md/material-icons';
import { TextIconSpacing } from '@react-md/icon';
import { ToastMessage } from '@react-md/alert';
import styles from './alerts.module.scss';

export const SuccessToastMessage = (message: string): ToastMessage => {
  const SuccessBody = (
    <TextIconSpacing
      icon={<DoneFontIcon color={'#ffffff'} />}
      className={styles.successMessage}
    >
      {message}
    </TextIconSpacing>
  );
  return {
    messagePriority: 'immediate',
    className: styles.successMessage,
    children: SuccessBody
  };
};
