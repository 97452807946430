import EnumValueObject from 'common/core/EnumValueObject';
import Result from 'common/core/Result';

export enum UserRoleValue {
  Admin = 'admin',
  CustomerSupport = 'customer_support',
  CompanyAdmin = 'company_admin',
  FacilityAdmin = 'facility_admin',
  User = 'user',
  RestrictedUser = 'restricted_user',
  Disabled = 'disabled',
  RestrictedUserAdmin = 'restricted_user_admin'
}

export const RolesList = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Customer Support',
    value: 'customer_support'
  },
  {
    label: 'Company Admin',
    value: 'company_admin'
  },
  {
    label: 'Facility Admin',
    value: 'facility_admin'
  },
  {
    label: 'User',
    value: 'user'
  },
  {
    label: 'Associate User',
    value: 'restricted_user'
  },
  {
    label: 'Disabled',
    value: 'disabled'
  }
];

export const ROLES = Object.values(UserRoleValue);

export default class UserRole extends EnumValueObject(UserRoleValue, 'role') {
  static buildFromOld(roles: string[]): Result<UserRole> {
    //possible roles, active, admin, authorize_registration, disabled, registration_approved, registration_pending
    if (roles.includes('authorize_registration')) {
      return Result.ok(UserRole.Admin);
    }
    if (roles.includes('admin')) {
      return Result.ok(UserRole.CompanyAdmin);
    }
    if (roles.includes('active')) {
      return Result.ok(UserRole.User);
    }
    return Result.ok(UserRole.Disabled);
  }
}
