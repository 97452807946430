import Image from 'next/image';
export const AppLogo = () => {
  return (
    <div className="app-image">
      <Image
        src="/images/logo_ebacmap.png"
        alt="eBacMap"
        width="147"
        height="36"
        priority
      />
    </div>
  );
};
