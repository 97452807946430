import { FacilityMapPaginateResponse } from 'modules/facility-maps/repos/FacilityMapRepo';
import { FacilityMapView } from 'modules/facility-maps/domain/FacilityMapView';
import { PaginateParamsT } from 'common/PaginationTypes';
import fetchJson from 'utils/fetchJson';
export type FacilityMapFilterParamsT = PaginateParamsT & {
  location: string;
  query?: string;
};
export const paginateFacilityMaps = async (
  params: FacilityMapFilterParamsT
): Promise<FacilityMapPaginateResponse> => {
  const url = `/api/facility-maps?${new URLSearchParams({
    limit: params.limit.toString(),
    offset: params.offset.toString(),
    location: params.location,
    ...(params.query && { title: params.query }),
    ...(params.clientId && { clientId: params.clientId })
  })}`;
  const facilityMaps = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return facilityMaps;
};

export const getFacilityMap = async (id: string): Promise<FacilityMapView> => {
  const map = await fetchJson(`/api/facility-maps/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return map;
};

export const createFacilityMap = async (map): Promise<string> => {
  const mapId = await fetchJson(
    '/api/facility-maps',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...map
      })
    },
    'Facility Map'
  );
  return mapId;
};

export const updateFacilityMap = async (map): Promise<void> => {
  await fetchJson(
    `/api/facility-maps/${map.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...map
      })
    },
    'Facility Map'
  );
  return;
};

export const deleteFacilityMap = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/facility-maps/${id}`,
    {
      method: 'DELETE'
    },
    'Facility Map'
  );
  return removed;
};
