import { Button } from '@react-md/button';
import { Card } from '@react-md/card';
import { Grid, GridCell } from '@react-md/utils';
import { SearchSVGIcon } from '@react-md/material-icons';
import { TextField } from '@react-md/form';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Autocomplete } from 'components/Autocomplete';
import { FilterBarProps } from './FilterTypes';
import { FilterSelect } from 'components';
import { RequiresPermission } from 'components/RequiresPermission';
import { ToggleButton } from 'components/Buttons/ToggleButton';
import { Tooltip } from 'components/Layout/Tooltip';
import styles from './filter-bar.module.scss';

export const FilterBar = (props: FilterBarProps): ReactElement => {
  const [search, setSearch] = useState('');
  const isFirstRender = useRef(true);
  const {
    filters,
    autoCompleteFilters,
    searchEnabled,
    actions,
    actionProps,
    filterdrawer,
    searchCallback
  } = props;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (searchCallback && !isFirstRender.current) {
      const timeOutId = setTimeout(() => searchCallback(search), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleFilterSelect = (name: string, value: any) => {
    if (props.filterCallback) {
      props.filterCallback(name, value);
    }
  };
  return (
    <Card className={styles.filterCard}>
      <Grid>
        {searchEnabled ? (
          <GridCell colSpan={2}>
            <TextField
              id="search"
              label="Search"
              value={search}
              type="text"
              theme="underline"
              leftChildren={<SearchSVGIcon />}
              onChange={event => setSearch(event.target.value)}
            />
          </GridCell>
        ) : null}
        {filters ? (
          <>
            {filters.map((filter, index) => {
              return (
                <GridCell colSpan={filter.span || 2} key={`filter-${index}`}>
                  <FilterSelect
                    index={index}
                    filter={filter}
                    filterCallback={handleFilterSelect}
                  />
                </GridCell>
              );
            })}
          </>
        ) : null}
        {filterdrawer && <GridCell colSpan={12}>{filterdrawer}</GridCell>}
        {autoCompleteFilters ? (
          <>
            {autoCompleteFilters.map((filter, index) => {
              return (
                <GridCell colSpan={filter.span || 2} key={`filter-${index}`}>
                  <Autocomplete
                    id={filter.name}
                    key={`${index}-${filter.name}`}
                    label={filter.label}
                    theme="underline"
                    placeholder={filter.label}
                    defaultValue={filter.value}
                    valueKey={filter.valueKey}
                    options={filter.options}
                    getResultValue={data => {
                      if (filter.resultValues) {
                        return `${data[filter.resultValues[0]]} ${
                          data[filter.resultValues[1]]
                        }`;
                      } else {
                        return data['label'];
                      }
                    }}
                    data={filter.selectOptions || []}
                    onAutoComplete={({ dataIndex }) => {
                      if (dataIndex === 0) {
                        handleFilterSelect(filter.name, 'all');
                      } else {
                        const foundItem = filter.options![dataIndex - 1];
                        if (foundItem) {
                          handleFilterSelect(
                            filter.name,
                            typeof foundItem === 'object' ? foundItem.id : ''
                          );
                        }
                      }
                    }}
                    loading={filter.isLoading}
                    disabled={filter.disabled}
                  />
                </GridCell>
              );
            })}
          </>
        ) : null}

        {actions ? (
          <GridCell
            colStart={actionProps?.colStart || 9}
            colSpan={actionProps?.colSpan || 4}
            className={styles.filterBarBtns}
          >
            {actions.map((action, index) => (
              <Tooltip text={action.name} key={`filterTooltip-${index}`}>
                {action.buttonType && action.buttonType === 'toggle' ? (
                  <ToggleButton
                    id={action.name}
                    ariaLabel={action.title!}
                    key={`filterAction-${index}`}
                    themeType="outline"
                    onClick={action.callback}
                    toggled={action.toggled!}
                    className={
                      action.icon && !action.title
                        ? styles.filterBarIconBtn
                        : styles.filterBarBtn
                    }
                  >
                    {action.icon ? action.icon : null} {action.title}
                  </ToggleButton>
                ) : (
                  <RequiresPermission
                    permissionDomain={action.domain!}
                    action={action.actionValue!}
                    allowRoles={action.allowRoles}
                  >
                    <Tooltip text={action.name || action.title}>
                      <Button
                        id={action.name}
                        key={`filterAction-${index}`}
                        themeType="outline"
                        onClick={action.callback}
                        className={
                          action.icon && !action.title
                            ? styles.filterBarIconBtn
                            : styles.filterBarBtn
                        }
                      >
                        {action.icon ? action.icon : null} {action.title}
                      </Button>
                    </Tooltip>
                  </RequiresPermission>
                )}
              </Tooltip>
            ))}
          </GridCell>
        ) : null}
      </Grid>
    </Card>
  );
};
