import { MapMarker } from './MapMarker';
import { MapMarkerProps, MapMarkersProps } from './types';
import { useBetween } from 'use-between';
import { useMapImage } from 'hooks';
import React from 'react';

export const MapMarkers = (props: MapMarkersProps) => {
  const {
    markers,
    sites,
    onRemove,
    onUpdate,
    isClickable,
    isInUse,
    handleIsInUse
  } = props;

  const useImage = () => useBetween(useMapImage);
  const { ratio, offsets } = useImage();
  if (!ratio || ratio === 0) {
    return null;
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: 1
      }}
    >
      {markers.map((marker, i) => {
        let markerProps: MapMarkerProps;
        if (handleIsInUse) {
          markerProps = {
            ratio: ratio,
            sites: sites || [],
            marker: marker,
            isNew: marker.id === 'new',
            onRemove: onRemove,
            onUpdate: onUpdate,
            isClickable: isClickable,
            offsets,
            handleIsInUse,
            isInUse
          };
        } else {
          markerProps = {
            ratio: ratio,
            sites: sites || [],
            marker: marker,
            offsets,
            isNew: marker.id === 'new',
            onRemove: onRemove,
            onUpdate: onUpdate,
            isClickable: isClickable
          };
        }
        return <MapMarker key={`$marker.id}-${i}`} {...markerProps} />;
      })}
    </div>
  );
};

export default MapMarkers;
