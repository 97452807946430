import { CircularProgress } from '@react-md/progress';
import { Grid } from '@react-md/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow
} from '@react-md/table';
import React, { ReactElement } from 'react';

import { Container, Map, MapActions } from 'components';
import { FacilityMapView } from 'modules/facility-maps/domain/FacilityMapView';
import { MapImage } from './MapImage';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { RequiresPermission } from 'components/RequiresPermission';
import styles from './map-list.module.scss';

type MapListProps = {
  view: string;
  maps: FacilityMapView[];
  getFacilityMaps: () => void;
  isFetching: boolean;
  actionValue: PermissionActionValue;
  domainValue: PermissionDomainValue;
};

export function MapList(props: MapListProps): ReactElement {
  const { view, maps, getFacilityMaps, isFetching, actionValue, domainValue } =
    props;

  if (!maps || !maps.length) {
    return <div>No Facility Maps</div>;
  }

  return (
    <>
      {view === 'list' ? (
        <TableContainer className={styles.mapListTable}>
          <Table fullWidth={true}>
            <TableHeader>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell hAlign="center">Thumbnail</TableCell>
                <TableCell hAlign="right"></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell>
                    <CircularProgress id="fetching" color="primary" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {maps.map((map, index) => (
                    <TableRow
                      key={index}
                      className={
                        (index + 1) % 2 === 0
                          ? styles.mapListRowEven
                          : styles.mapListRowOdd
                      }
                    >
                      <TableCell>
                        <a href={`/facility-maps/${map.id}`}>{map.title}</a>
                      </TableCell>
                      <TableCell hAlign="center">
                        <a href={`/facility-maps/${map.id}`}>
                          <MapImage
                            url={map.url!}
                            name={map.title}
                            className={styles.mapListThumbnail}
                            width={100}
                            height={100}
                            isThumb={true}
                            mimeType={map.mimetype || 'image/jpeg'}
                          />
                        </a>
                      </TableCell>
                      <TableCell hAlign="right">
                        <RequiresPermission
                          permissionDomain={domainValue}
                          action={actionValue}
                        >
                          <MapActions
                            map={map}
                            getFacilityMaps={getFacilityMaps}
                          />
                        </RequiresPermission>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Container className={styles.mapListGridContainer}>
          <Grid className={styles.mapListGrid}>
            {maps.map(map => (
              <Map key={map.id} map={map} getFacilityMaps={getFacilityMaps} />
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}
