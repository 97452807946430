import { Button, ButtonProps } from '@react-md/button';
import React, { ReactElement } from 'react';

import styles from './buttons.module.scss';

interface CustomButtonProps extends ButtonProps {
  label?: string;
  ariaLabel: string;
  toggled: boolean;
}

export function ToggleButton(props: CustomButtonProps): ReactElement {
  const { label, id, ariaLabel, children, toggled, ...rest } = props;
  return (
    <React.Fragment>
      <Button
        id={id}
        {...rest}
        themeType="outline"
        className={toggled ? styles.outlineToggled : styles.outlineNotToggled}
        aria-label={ariaLabel}
      >
        {label || children}
      </Button>
    </React.Fragment>
  );
}
