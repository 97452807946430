import { LocationView } from 'modules/locations/domain/LocationView';
import { NON_AUTH_PAGES } from 'config/constants';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { PermissionResourceValue } from 'modules/users/domain/PermissionResource';
import { RolePermission, hasRequiredRole } from 'utils/auth';
import { UserView } from 'modules/users/domain/UserView';
import { alertService } from 'utils/alerts';
import { useEffect } from 'react';
import { useLocation } from './useLocation';
import { useRouter } from 'next/router';
// import { useValidSubscription } from './useValidSubscription';
import Cookies from 'universal-cookie';
import fetchJson from 'utils/fetchJson';
import useSWR from 'swr';
interface UseUserProps {
  redirectTo?: string;
  redirectIfFound?: boolean;
  requireRoles?: (string | RolePermission)[];
  domain?: PermissionDomainValue;
  action?: PermissionActionValue;
  resource?: PermissionResourceValue;
  resourceId?: string;
}
interface UserHookResponse {
  isLoggedIn: boolean;
  user?: UserView;
  locations: LocationView[];
  mutateUser: any;
  isCheckingUser: boolean;
  refreshUser: any;
  logout: () => void;
}

const isNonAuthPage = (route: string): boolean => {
  if (NON_AUTH_PAGES.includes(route)) {
    return true;
  }
  NON_AUTH_PAGES.forEach(page => {
    if (route.includes(page)) {
      return true;
    }
  });
  return false;
};

const fetcher = (url: string) =>
  fetch(url).then(async r => {
    const res = await r.json();
    if (res && res.id) {
      return res;
    }
    return null;
  });

export function useUser({
  redirectTo,
  redirectIfFound,
  requireRoles
}: UseUserProps = {}): UserHookResponse {
  const router = useRouter();
  const cookies = new Cookies();
  const {
    data: user,
    mutate: mutateUser,
    isLoading
  } = useSWR<UserView>('/api/users/me', fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  });
  const [location, , clearLocation] = useLocation(user);

  useEffect(() => {
    if (isLoading) return;
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo) {
      if (!user && !isNonAuthPage(router.pathname)) {
        router.push('/login');
        return;
      }
      return;
    }

    if (redirectTo && !user && !redirectIfFound) {
      router.push(redirectTo);
    }
    if (user && location && router.pathname !== '/') {
      if (requireRoles) {
        if (!hasRequiredRole(requireRoles, user.role)) {
          router.push('/');
          alertService.error('You do not have access to that area', {
            id: 'global-alerts',
            autoClose: true,
            keepAfterRouteChange: false
          });
          return;
        }
      }
    }
    if (redirectIfFound && user) {
      router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo, isLoading]);

  const refresh = async () => {
    const user = await fetcher('/api/users/me/');
    mutateUser(user);
  };

  const logout = async () => {
    await fetchJson('/api/auth/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
    mutateUser(undefined);
    localStorage.clear();
    cookies.remove('location', { path: '/' });
    clearLocation();
    if (!isNonAuthPage(router.pathname)) {
      return router.push('/login');
    }
  };

  let locations: LocationView[] = [];
  if (user) {
    locations = user.locations ?? [];
  }
  if (
    user &&
    (user.role === 'company_admin' || user.role === 'facility_admin') &&
    locations &&
    locations.length > 1
  ) {
    const globalLocation = {
      active: false,
      id: 'global',
      name: 'Global',
      clientId: user.client!,
      slug: 'GLO',
      tourEnabled: false
    };
    if (locations.findIndex(l => l.id === 'global') === -1) {
      locations.push(globalLocation);
    }
  }

  return {
    user,
    mutateUser,
    isCheckingUser: isLoading,
    refreshUser: refresh,
    logout,
    locations,
    isLoggedIn: !!user
  };
}
