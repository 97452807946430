import EnumValueObject from 'common/core/EnumValueObject';

export enum PermissionActionValue {
  Manage = 'manage',
  View = 'view',
  NoAccess = 'noaccess'
}
export const STATUSES: string[] = Object.values(PermissionActionValue);

export default class PermissionAction extends EnumValueObject(
  PermissionActionValue,
  'action'
) {}
