import { FormMessage, Select, SelectProps } from '@react-md/form';
import { useField } from 'formik';

interface PropsType {
  id: string;
  [x: string]: any;
}
const DropDown: React.FC<SelectProps> = ({ label, ...props }: PropsType) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.

  const [field, meta, helpers] = useField({ ...props, name: props.name! });
  const { options } = props;
  const { error, value } = meta;
  const { setValue } = helpers;
  return (
    <div>
      <Select
        id={props.id}
        label={label}
        value={value}
        options={options}
        disabled={props.disabled}
        name={field.name}
        onChange={value => {
          if (props.onChange) props.onChange(value);
          else setValue(value);
        }}
      />

      {error ? (
        <FormMessage id={`${field.name}-message`} error>
          {error}
        </FormMessage>
      ) : null}
    </div>
  );
};

export default DropDown;
