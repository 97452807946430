import { Button } from '@react-md/button';
import { Card, CardActions, CardContent, CardTitle } from '@react-md/card';
import { Container } from 'components/Layout';
import {
  DehazeSVGIcon,
  SettingsSVGIcon,
  SortSVGIcon
} from '@react-md/material-icons';
import { DropdownMenu, MenuItem } from '@react-md/menu';
import { FilterDrawer } from 'components/Filter/FilterDrawer';
import { Form, Label, TextField } from '@react-md/form';
import { ReportDateRange } from 'components/Filter/ReportDateRange';
import { ReportView } from 'modules/reports/domain/ReportView';
import { SelectedFiltersType } from 'components/Filter/FilterTypes';
import { Tooltip } from 'components/Layout/Tooltip';
import { differenceInDays } from 'date-fns';
import { useBetween } from 'use-between';
import { useLocation } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import router from 'next/router';
import styles from './dashboard-report.module.scss';
interface DashboardModuleProps {
  id: string;
  reportId: string;
  title: string;
  report?: ReportView;
  allowRemove: boolean;
  allowEdit: boolean;
  allowView: boolean;
  reportLink?: string;
  saveCallback: (values: any) => void;
  removeCallback: (id: string) => void;
  ReportComponent: ReactElement;
  style?: any;
}

function DashboardModule(props: DashboardModuleProps): ReactElement {
  const r = router;
  const [editing, setEditing] = useState(false);
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const {
    id,
    reportId,
    title,
    report,
    allowRemove = true,
    allowEdit = true,
    allowView = true,
    saveCallback,
    removeCallback,
    reportLink,
    ReportComponent,
    ...rest
  } = props;
  const defaultFilterValues: any = {};
  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  const items = [
    ...(allowView
      ? [
          <MenuItem key={0} onClick={() => r.push(`${reportLink}`)}>
            {title === 'Sample Scheduler'
              ? 'View Calendar'
              : title === 'CAPA'
              ? '+ New'
              : 'View Report'}
          </MenuItem>
        ]
      : []),
    ...(allowEdit
      ? [
          <MenuItem key={1} onClick={() => setEditing(true)}>
            Edit
          </MenuItem>
        ]
      : []),
    ...(allowRemove
      ? [
          <MenuItem key={2} onClick={() => removeCallback(reportId)}>
            Remove
          </MenuItem>
        ]
      : [])
  ];

  useEffect(() => {
    if (report?.filters) {
      const intVal =
        report.filters?.dataType === 'rolling'
          ? 'day'
          : report.filters?.interval;
      setFilterValues({
        ...report.filters,
        dateRange: {
          type: report.filters.dateRangeType,
          start: report.filters.startDate,
          end: report.filters.endDate
        },
        interval: intVal
      });
    }
  }, [report?.filters]);

  const updateFilters = (newFilters: SelectedFiltersType) => {
    const updated = { ...newFilters };
    if (updated.dataType && updated.dataType !== filterValues.dataType) {
      updated['chartType'] = 'line';
      updated['rollingInterval'] =
        updated.dataType === 'rolling' ? 'sevenDay' : '';
      updated['interval'] = updated.dataType === 'rolling' ? 'day' : 'month';
    }
    if (updated.dateRange && updated.dateRange !== filterValues.dateRange) {
      if (
        updated.dateRange?.type === 'custom' &&
        (!updated.dateRange.start || !updated.dateRange.end)
      ) {
        return false;
      }
    }
    if (
      (updated.dateRange && updated.dateRange !== filterValues.dateRange) ||
      (updated.interval && updated.interval !== filterValues.interval)
    ) {
      const numberOfDays = {
        week: 7,
        month: 30,
        quarter: 90,
        year: 365,
        ytd: 365
      };
      const intervalNumDays = numberOfDays[updated.interval || 'day'];
      let rangeNumDays;
      if (updated.dateRange?.type && updated.dateRange.type !== 'custom') {
        rangeNumDays = numberOfDays[updated.dateRange!.type];
      } else {
        if (updated.dateRange?.end && updated.dateRange?.start) {
          rangeNumDays = differenceInDays(
            new Date(updated.dateRange.end),
            new Date(updated.dateRange.start)
          );
        }
      }
      if (intervalNumDays > rangeNumDays) {
        // setShowWarningModal(true);
      }
    }
    setFilterValues(updated);
  };

  const handleNameChange = event => {
    const filters = { ...filterValues };
    filters['name'] = event.target.value;
    setFilterValues(filters);
  };

  const handleSave = () => {
    if (
      filterValues.dateRange?.type === 'custom' &&
      (!filterValues.dateRange?.start || !filterValues.dateRange?.end)
    ) {
      return false;
    }
    saveCallback(filterValues);
    setEditing(false);
  };

  return (
    <Card
      className={editing ? styles.dashReportCardEdit : styles.dashReportCard}
      {...rest}
    >
      {editing ? (
        <>
          <CardContent>
            <Form className={styles.dashReportEditForm}>
              {title !== 'No. Tests By Subcategory' &&
              title !== 'No. Tests By Organism' ? (
                <div className={styles.inputRow}>
                  <Label className={styles.inputLabel} htmlFor="title">
                    Title
                  </Label>
                  <SortSVGIcon className={styles.inputIcon} />
                  <TextField
                    id="title"
                    value={filterValues['name'] || title}
                    type="text"
                    theme="underline"
                    className={styles.input}
                    maxLength={80}
                    onChange={event => handleNameChange(event)}
                  />
                </div>
              ) : null}
              {title === 'No. Tests By Subcategory' ||
              title === 'No. Tests By Organism' ? (
                <Container className={styles.filterContainer}>
                  <Label className={styles.filterLabel} htmlFor={'dateRange'}>
                    Date Range
                  </Label>
                  <DehazeSVGIcon className={styles.filterIcon} />

                  <ReportDateRange
                    type={filterValues.dateRange.type}
                    start={filterValues.dateRange.start}
                    end={filterValues.dateRange.end}
                    onDatesChange={dateRange => {
                      updateFilters({
                        ...filterValues,
                        dateRange
                      });
                    }}
                    options={['year', 'ytd']}
                  />
                </Container>
              ) : (
                <FilterDrawer
                  permanent
                  selectedFilters={filterValues}
                  rolling
                  locationId={location?.id}
                  clientId={location?.clientId}
                  onFilter={updateFilters}
                />
              )}
            </Form>
          </CardContent>
          <CardActions className={styles.dashReportFooter}>
            <Button theme="primary" onClick={() => setEditing(false)}>
              CANCEL
            </Button>
            <Button
              theme="secondary"
              themeType="outline"
              id={styles.dashReportSaveBtn}
              onClick={() => handleSave()}
            >
              SAVE
            </Button>
          </CardActions>
        </>
      ) : (
        <>
          {title && (
            <CardTitle className={styles.dashReportCardTitle}>
              {title}
            </CardTitle>
          )}
          {items && items.length > 0 && (
            <CardActions className={styles.dashReportGear}>
              <DropdownMenu
                id={id}
                aria-label="Settings"
                disableDropdownIcon
                className={styles.gearBtn}
                buttonChildren={
                  <Tooltip text="Settings">
                    <SettingsSVGIcon aria-label="Settings" />
                  </Tooltip>
                }
              >
                {items.map(item => item)}
              </DropdownMenu>
            </CardActions>
          )}
          <CardContent className={styles.dashReportBody}>
            {ReportComponent}
          </CardContent>
          {allowView ? (
            <CardContent className={styles.dashReportFooter}>
              <Button
                theme="primary"
                themeType="outline"
                className={styles.dashReportFooterBtn}
                onClick={() => r.push(`${reportLink}`)}
              >
                {title === 'Sample Scheduler'
                  ? 'View Calendar'
                  : title === 'CAPA'
                  ? '+ New'
                  : 'View Report'}
              </Button>
            </CardContent>
          ) : (
            <div style={{ height: '1rem' }}></div>
          )}
        </>
      )}
    </Card>
  );
}

export default DashboardModule;
