import { useSelector } from 'react-redux';
import styles from './version.module.scss';
export const Version = () => {
  //@ts-ignore
  const version = useSelector(state => state.version);

  return (
    <div className={styles.version}>
      <small>v{version}</small>
    </div>
  );
};
