import { Button } from '@react-md/button';
import {
  CheckSVGIcon,
  CloseSVGIcon,
  EditSVGIcon
} from '@react-md/material-icons';
import { Grid, GridCell } from '@react-md/utils';
import { TextField } from '@react-md/form';
import { Typography } from '@react-md/typography';
import React, { ReactElement, useEffect, useState } from 'react';

import { Container, UseWarningOnExit } from 'components';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { RequiresPermission } from 'components/RequiresPermission';
import { Tooltip } from 'components/Layout/Tooltip';
import styles from './editableinput.module.scss';
interface IEditableInputProps {
  text: string;
  placeholderTxt?: string;
  isHeader?: boolean;
  isNew?: boolean;
  callback: (value: string) => void;
  maxCharacters?: number;
  isEditing?: boolean;
  actionValue: PermissionActionValue;
  domainValue: PermissionDomainValue;
}

export function EditableInput(props: IEditableInputProps): ReactElement {
  const {
    text,
    callback,
    maxCharacters,
    isEditing,
    isNew,
    isHeader,
    placeholderTxt,
    actionValue,
    domainValue
  } = props;
  const [editing, setEditing] = useState(isEditing ? isEditing : false),
    [inputValue, setInputValue] = useState(text);
  useEffect(() => {
    setInputValue(text);
  }, [text]);
  const handleSave = () => {
    callback(inputValue);
    setEditing(false);
  };
  const handleCancel = () => {
    setInputValue(text);
    setEditing(false);
  };

  if (isNew) {
    return (
      <Container>
        <UseWarningOnExit shouldWarn={editing} />
        <Grid>
          <GridCell colSpan={8}>
            <TextField
              id={text}
              required
              value={inputValue}
              type="text"
              theme="underline"
              onChange={event => setInputValue(event.target.value)}
              onBlur={() => handleSave()}
              maxLength={maxCharacters ? maxCharacters : undefined}
              placeholder={placeholderTxt ? placeholderTxt : 'Report Title'}
              className={styles.inputStyle}
            />
          </GridCell>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <UseWarningOnExit shouldWarn={editing} />
      <Grid>
        {!editing ? (
          <>
            <GridCell colSpan={1} />
            <GridCell colSpan={10}>
              <div className="flex w-full justify-center items-center">
                {isHeader ? (
                  <Typography
                    type="headline-5"
                    style={{
                      marginTop: 'unset',
                      textAlign: 'center'
                    }}
                  >
                    {inputValue}
                  </Typography>
                ) : (
                  <>{inputValue}</>
                )}

                <RequiresPermission
                  permissionDomain={domainValue}
                  action={actionValue}
                >
                  <Tooltip text="Edit">
                    <Button onClick={() => setEditing(true)}>
                      <EditSVGIcon />
                    </Button>
                  </Tooltip>
                </RequiresPermission>
              </div>
            </GridCell>
            <GridCell colSpan={1} />
          </>
        ) : (
          <>
            <GridCell colSpan={1} />
            <GridCell colSpan={8}>
              <TextField
                id={text}
                disabled={!editing}
                value={inputValue}
                type="text"
                theme="underline"
                onChange={event => setInputValue(event.target.value)}
                maxLength={maxCharacters ? maxCharacters : undefined}
                placeholder={placeholderTxt ? placeholderTxt : 'Report Title'}
                className={styles.inputStyle}
              />
            </GridCell>
            <GridCell colSpan={2}>
              <Tooltip text="Cancel">
                <Button onClick={handleCancel}>
                  <CloseSVGIcon color="red" />
                </Button>
              </Tooltip>
              <Tooltip text="Save">
                <Button onClick={handleSave}>
                  <CheckSVGIcon color="green" />
                </Button>
              </Tooltip>
            </GridCell>
            <GridCell colSpan={1} />
          </>
        )}
      </Grid>
    </Container>
  );
}
