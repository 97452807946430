import { AutoCompleteProps, AutoCompleteResult } from '@react-md/autocomplete';
import { Loading } from './Layout/Loading';
import { MdAutoComplete } from 'components/Form/MdAutoComplete';
import React, { useState } from 'react';

interface AutocompleteProps extends AutoCompleteProps {
  options?: any[];
  defaultValue: any;
  valueKey?: string;
  valuekey2?: string;
  loading?: boolean;
}

const getValueForAutocomplete = (
  options?: any[],
  id?: string,
  returnField?: string,
  returnField2?: string
) => {
  if (id === 'all') {
    return 'All';
  }
  const found = [...(options || [])].find(opt => opt.id === id);
  if (found && !returnField2) {
    return returnField ? found[returnField] : found;
  } else if (found && returnField2 && returnField) {
    return `${found[returnField]}: ${found[returnField2]}`;
  }
  return '';
};
export const Autocomplete = (props: AutocompleteProps) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {!props.loading ? (
        <MdAutoComplete
          {...props}
          value={
            !isEditing
              ? getValueForAutocomplete(
                  props.options,
                  props.defaultValue,
                  props.valueKey,
                  props.valuekey2
                )
              : null
          }
          theme="underline"
          onAutoComplete={(result: AutoCompleteResult) => {
            setIsEditing(false);
            if (props.onAutoComplete) props.onAutoComplete(result);
          }}
          onChange={() => {
            setIsEditing(true);
          }}
        />
      ) : (
        <Loading loaderStyles={{ maxWidth: 'fit-content' }} isSmall={true} />
      )}
    </>
  );
};
