import { ChangelogOptions, changeLogSteps } from 'config/changelog';
import { CloseFontIcon } from '@react-md/material-icons';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Image from 'next/image';
import Parser from 'html-react-parser';

export interface ChangelogPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const ChangelogPanel = (props: ChangelogPanelProps) => {
  const { open, setOpen } = props;

  const renderChange = (version: string) => {
    const changes: ChangelogOptions[] = changeLogSteps[version].filter(
      c => !c.hideInPanel
    );
    if (!changes) return null;

    return (
      <div className="p-3" key={version}>
        <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
          {version}
        </span>
        {changes.map((change, i) => (
          <div className="py-3" key={i}>
            <h5 className="text-lg font-semibold">{Parser(change.subtitle)}</h5>
            <p className="mb-2">{Parser(change.text)}</p>
            {change.image && (
              <Image
                src={change.image}
                alt=""
                width={600}
                height={600}
                className="mb-2"
              />
            )}
          </div>
        ))}
      </div>
    );
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-[#0b884c] px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-xl font-semibold leading-6 text-white">
                          What&apos;s New
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-[#0b884c] text-white hover:text-white focus:outline-none focus:ring-none focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <CloseFontIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-white">
                          Stay up to date with the newest updates and
                          improvements in eBacMap&reg;.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-6 sm:px-6 divide-y-2 divide-slate-50">
                      {Object.keys(changeLogSteps).map(version =>
                        renderChange(version)
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
