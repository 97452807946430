/* eslint-disable prettier/prettier */
import {
  FilterConditionView,
  FilterRuleOrConditionView,
  FilterRuleView
} from 'modules/reports/domain/ReportView';
import { FilterOperatorValues } from 'modules/reports/domain/FilterOperatorValue';
import { FilterOption } from './FilterTypes';
import { FiltersResponse } from 'modules/shared/UseCases/GetFilters/types';
import {
  filterOrganisms,
  filterSitesByParents,
  findTests,
  getConditionByField
} from 'utils/reportFilters';
import AdvancedFilterAndOr from './AdvancedFilterAndOr';
import AdvancedFilterCell, {
  AdvancedFilterCellType
} from './AdvancedFilterCell';
import React, { ReactElement, useEffect, useState } from 'react';

type AdvancedFilterProps = {
  id?: string;
  onFilter: (filterCon: FilterConditionView) => void;
  isChild?: boolean;
  index?: number;
  filtersData: FiltersResponse;
  selectedCondition?: FilterConditionView;
  childLevel: number;
  triggerPropogation?: boolean;
  selfDestruct?: () => void;
  rolling?: boolean;
  maps?: boolean;
  includeCharts?: boolean;
  includeCustomCharts?: boolean;
  disableDateRange?: boolean;
  nonConformances?: boolean;
  reportTypes?: FilterOption[];
  surfacePlans?: boolean;
};

const buildOptions = (
  data: any[],
  labelKey: string,
  includeAll: boolean = true,
  secondaryField?: string
): FilterOption[] => {
  if (!data || !data.length) {
    return includeAll ? [{ label: 'All', value: 'all' }] : [];
  }
  const options = data.map(result => {
    return {
      label: secondaryField ? (
        <>
          {result[labelKey]}&nbsp;
          <span className="text-xs">{result[secondaryField]}</span>
        </>
      ) : (
        result[labelKey]
      ),
      value: result.id,
      searchable: secondaryField
        ? `${result[labelKey]} ${result[secondaryField]}`.toLowerCase()
        : result[labelKey].toLowerCase()
    };
  });

  return includeAll
    ? [{ label: 'All', value: 'all' }].concat(options)
    : options;
};

const getFilteredTests = (
  selectedConditions?: FilterConditionView[],
  filtersData?: FiltersResponse
) => {
  const selectedCategoryCondition = getConditionByField<string[]>(
    selectedConditions ?? [],
    'category'
  );
  const selectedOrganismCondition = getConditionByField<string[]>(
    selectedConditions ?? [],
    'organism'
  );
  if (selectedCategoryCondition) {
    if (selectedOrganismCondition) {
      return findTests(
        filtersData?.testDefinitions ?? [],
        selectedCategoryCondition,
        selectedOrganismCondition
      );
    } else {
      return findTests(
        filtersData?.testDefinitions ?? [],
        selectedCategoryCondition
      );
    }
  } else if (selectedOrganismCondition) {
    return findTests(
      filtersData?.testDefinitions ?? [],
      undefined,
      selectedOrganismCondition
    );
  }

  return filtersData?.testDefinitions || [];
};

const AdvancedFilter = (props: AdvancedFilterProps): ReactElement => {
  const {
    isChild,
    index,
    selfDestruct,
    childLevel,
    filtersData,
    selectedCondition,
    nonConformances,
    surfacePlans,
    onFilter
  } = props;

  const [condition, setCondition] = useState<FilterConditionView>(
    selectedCondition ?? { condition: 'and', rules: [] }
  );
  const [conditionCount, setConditionCount] = useState<number>(0);

  const handleNewCondition = () => {
    const newCond = { ...condition };
    newCond.rules.push({
      field: '',
      operator: '',
      value: ''
    });
    setCondition(newCond);
  };

  const handleNewGroup = () => {
    const newCond = { ...condition };
    newCond.rules.push({
      condition: 'and',
      rules: [
        {
          field: '',
          operator: '',
          value: ''
        }
      ]
    });
    setCondition(newCond);
  };

  const handleRemove = (curIndex: number) => {
    const newCond = { ...condition };
    newCond.rules.splice(curIndex, 1);
    setCondition(newCond);
    onFilter(newCond);
  };

  function isAdvancedFilter(
    cell: FilterRuleOrConditionView
  ): cell is AdvancedFilterCellType {
    return cell.hasOwnProperty('condition');
  }

  const calcConditions = (): number => {
    return condition.rules.map(cell => !isAdvancedFilter(cell)).length;
  };

  const checkWouldTriggerNonValid = () => {
    return (
      conditionCount == 2
      // && filterCells.map(cell => isAdvancedFilter(cell)).length < 2
    );
  };

  const updateGroup = (group: FilterConditionView, i: number) => {
    const newConditions = { ...condition };
    newConditions.rules[i] = group;
    setCondition(newConditions);
    onFilter(newConditions);
  };

  const updateRule = (rule: FilterRuleView, i: number) => {
    const newConditions = { ...condition };
    newConditions.rules[i] = rule;
    setCondition(newConditions);
    onFilter(newConditions);
  };
  const availableFilters = [
    {
      name: 'category',
      label: 'Category',
      multiple: true,
      fieldType: 'select',
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(filtersData.categories || [], 'name', false),
      value: getConditionByField([condition], 'category')
    },
    {
      name: 'organism',
      label: 'Subcategory',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(
        filterOrganisms(
          filtersData.categories || [],
          getConditionByField([condition], 'category'),
          filtersData.organisms
        ),

        'name',
        false
      ),
      value: getConditionByField([condition], 'organism')
    },
    {
      name: 'testDefinition',
      label: 'Test',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(
        getFilteredTests([condition], filtersData) || [],
        'subTest',
        false
      ),
      value: getConditionByField([condition], 'testDefinition')
    },
    {
      name: 'zone',
      label: 'Zone',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(filtersData.zones || [], 'name', false),
      value: getConditionByField([condition], 'zone')
    },
    {
      name: 'site',
      label: 'Site',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(
        filterSitesByParents(
          filtersData.sites,
          (getConditionByField([condition], 'zone') as string[]) ?? [],
          (getConditionByField([condition], 'group') as string[]) ?? [],
          (getConditionByField([condition], 'facilityMap') as string[]) ?? [],
          filtersData
        ) || [],
        'zoneCode',
        false,
        'description'
      ),
      value: getConditionByField([condition], 'site')
    },
    {
      name: 'group',
      label: 'Group',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(filtersData.groups || [], 'name', false),
      value: getConditionByField([condition], 'group')
    },
    {
      name: 'facilityMap',
      label: 'Facility Map',
      fieldType: 'select',
      multiple: true,
      validComparisons: [
        { label: 'In', value: FilterOperatorValues.In },
        { label: 'Not in', value: FilterOperatorValues.NotIn }
      ],
      options: buildOptions(filtersData.facilityMaps || [], 'title', false),
      value: getConditionByField([condition], 'facilityMap')
    },
    ...(surfacePlans
      ? [
          {
            name: 'planId',
            label: 'Plan',
            multiple: true,
            fieldType: 'select',
            validComparisons: [
              { label: 'In', value: FilterOperatorValues.In },
              { label: 'Not in', value: FilterOperatorValues.NotIn }
            ],
            options: buildOptions(filtersData.plans || [], 'name', false),
            value: getConditionByField([condition], 'planId')
          },
          {
            name: 'surfaceTypeId',
            label: 'Surface',
            multiple: true,
            fieldType: 'select',
            validComparisons: [
              { label: 'In', value: FilterOperatorValues.In },
              { label: 'Not in', value: FilterOperatorValues.NotIn }
            ],
            options: buildOptions(
              filtersData.surfaceTypes || [],
              'name',
              false
            ),
            value: getConditionByField([condition], 'surfaceTypeId')
          }
        ]
      : []),

    ...(!nonConformances
      ? [
          {
            name: 'resultRating',
            label: 'Result Type',
            multiple: false,
            fieldType: 'select',
            validComparisons: [
              { label: 'Equal To', value: FilterOperatorValues.EqualTo }
            ],
            options: [
              {
                label: 'All',
                value: 'all'
              },
              {
                label: 'Conformance',
                value: 'conformance'
              },
              {
                label: 'Nonconformance',
                value: 'nonconformance'
              }
            ],
            value: getConditionByField([condition], 'resultRating') ?? 'all'
          }
        ]
      : []),
    {
      label: 'Test Num',
      name: 'testNum',
      fieldType: 'input',
      validComparisons: [
        { label: 'Equal To', value: FilterOperatorValues.EqualTo },
        {
          label: 'Greater Than or Equal To',
          value: FilterOperatorValues.GreaterThanOrEqualTo
        },
        { label: 'Greater Than', value: FilterOperatorValues.GreaterThan },
        { label: 'Less Than', value: FilterOperatorValues.LessThan },
        {
          label: 'Less Than or Equal To',
          value: FilterOperatorValues.LessThanOrEqualTo
        }
      ],
      value: getConditionByField([condition], 'testNum')
    },
    {
      name: 'resultType',
      fieldType: 'select',
      label: 'Test Type',
      validComparisons: [
        { label: 'Equal To', value: FilterOperatorValues.EqualTo }
      ],
      options: [
        { label: 'Quantitative', value: 'quantitative' },
        { label: 'Qualitative', value: 'qualitative' },
        { label: 'All', value: 'all' }
      ]
    },
    ...(getConditionByField([condition], 'resultType') === 'quantitative'
      ? [
          {
            name: 'resultValue',
            fieldType: 'input',
            label: 'Result Value',
            validComparisons: [
              { label: 'Equal To', value: FilterOperatorValues.EqualTo },
              {
                label: 'Greater Than or Equal To',
                value: FilterOperatorValues.GreaterThanOrEqualTo
              },
              {
                label: 'Greater Than',
                value: FilterOperatorValues.GreaterThan
              },
              { label: 'Less Than', value: FilterOperatorValues.LessThan },
              {
                label: 'Less Than or Equal To',
                value: FilterOperatorValues.LessThanOrEqualTo
              }
            ]
          }
        ]
      : []),
    ...(getConditionByField([condition], 'resultType') === 'qualitative'
      ? [
          {
            name: 'resultValue',
            fieldType: 'select',
            label: 'Result Value',
            validComparisons: [
              { label: 'Equal To', value: FilterOperatorValues.EqualTo }
            ],
            options: [
              {
                label: 'Positive',
                value: 'positive'
              },
              {
                label: 'Negative',
                value: 'negative'
              },
              {
                label: 'Presumptive',
                value: 'presumptive'
              },
              {
                label: 'All',
                value: 'all'
              }
            ]
          }
        ]
      : [])
  ].filter(f => f);

  useEffect(() => {
    setConditionCount(calcConditions());
  }, [condition]);

  useEffect(() => {
    setCondition(
      selectedCondition ?? {
        condition: 'and',
        rules: [
          {
            field: '',
            operator: '',
            value: ''
          }
        ]
      }
    );
  }, [selectedCondition]);

  const filterUsedFields = (fields: any[], currentField: string): any[] => {
    return fields.filter(f => {
      if (f.name == currentField) return true;
      const hasCondition = condition.rules.some(rule =>
        rule.hasOwnProperty('condition')
          ? (rule as FilterConditionView).rules.some(
              r => (r as FilterRuleView).field === f.name
            )
          : (rule as FilterRuleView).field === f.name
      );
      return !hasCondition;
    });
  };

  return (
    <div className={'advanced-filter md:flex flex-col  m-8 gap-2'}>
      <AdvancedFilterAndOr
        validComparison={conditionCount < 2}
        handleNewCell={handleNewCondition}
        handleNewGroup={handleNewGroup}
        handleRemove={handleRemove}
        isChild={isChild}
        setCondition={cond =>
          setCondition(prevCells => {
            const newConditions = { ...prevCells };
            newConditions.condition = cond;
            return newConditions;
          })
        }
        condition={condition.condition}
        selfDestruct={
          selfDestruct
            ? selfDestruct
            : isChild
            ? () => handleRemove(index!!)
            : undefined
        }
        checkTriggerNonValid={checkWouldTriggerNonValid}
        childLevel={childLevel}
        index={index}
      />
      {condition.rules.map((cell, i) =>
        isAdvancedFilter(cell) ? (
          <AdvancedFilter
            key={`row-${childLevel}-${i}`}
            filtersData={filtersData}
            selfDestruct={() => handleRemove(i)}
            isChild={true}
            childLevel={childLevel + 1}
            index={i}
            selectedCondition={cell}
            // temp false for now
            triggerPropogation={false}
            onFilter={(group: FilterConditionView) => updateGroup(group, i)}
          />
        ) : (
          <AdvancedFilterCell
            key={(cell as FilterRuleView).field}
            validCellSelections={filterUsedFields(
              availableFilters,
              (cell as FilterRuleView).field
            )}
            currentRule={cell as FilterRuleView}
            onChange={(rule: FilterRuleView) => updateRule(rule, i)}
            checkTriggerNonValid={checkWouldTriggerNonValid}
            handleRemove={() => handleRemove(i)}
          />
        )
      )}
    </div>
  );
};

export default AdvancedFilter;
