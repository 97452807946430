export const insertElemAt = (arr, index, ...newItems) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index)
];

export const removeElemAt = (arr, index) => arr.filter((_, i) => i !== index);

export const arraysEqual = (a: any[], b: any[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getUnique = (arr, comp) => {
  if (!arr || !arr.length || !comp) return arr;
  // store the comparison  values in array
  const unique = arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);
  return unique;
};

//randomize an array
export const shuffle = (arr): any[] => {
  return arr.reduceRight(
    (r, _, __, s) => (
      r.push(s.splice(0 | (Math.random() * s.length), 1)[0]), r
    ),
    []
  );
};

//flatten an array of arrays (only flattens one level)
export const flatten = arr => [].concat(...arr);

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunked_arr: T[][] = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const asyncFilter = async (arr, predicate) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.filter((_v, index) => results[index]);
};

export const asyncMap = async (arr, fn) => {
  return await Promise.all(arr.map(fn));
};
