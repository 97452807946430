import { BELOW_CENTER_ANCHOR } from '@react-md/utils';
import { DropdownMenu } from '@react-md/menu';
import { MenuItem } from 'react-md';
import { useActionClassName } from '@react-md/app-bar';
import React from 'react';

type TableActionDropdownMenuT = {
  items: {
    title: string;
    callback: (id: string) => void;
  }[];
  title: string | React.ReactNode;
  position: any;
  id: string;
  resultId: string;
};

export const TableActionDropdownMenu = (props: TableActionDropdownMenuT) => {
  const menuItems = props.items.map(item => {
    return (
      <MenuItem key="add-new" onClick={() => item.callback(props.resultId)}>
        {item.title}
      </MenuItem>
    );
  });

  return (
    <div
      className={useActionClassName({ ...props.position })}
      style={{ color: '#ffffff' }}
    >
      <DropdownMenu
        {...props.position}
        buttonType="icon"
        id={props.id}
        buttonChildren={props.title}
        anchor={BELOW_CENTER_ANCHOR}
      >
        {menuItems}
      </DropdownMenu>
    </div>
  );
};
