import {
  CategoryFilterOption,
  FiltersResponse,
  OrganismFilterOption,
  TestFilterOption
} from 'modules/shared/UseCases/GetFilters/types';
import {
  FilterConditionView,
  FilterRuleOrConditionView,
  FilterRuleView,
  ReportFiltersView
} from 'modules/reports/domain/ReportView';
import { SelectedFiltersType } from 'components/Filter/FilterTypes';
import { TestResultPaginateFilterConditions } from 'modules/test-results/repos/types';
import { flatten } from 'utils/array';

export const getConditionByField = <RT>(
  conditions: FilterConditionView[],
  field: string,
  returnField?: boolean
): RT | undefined => {
  if (!conditions || !conditions.length) return;
  const filterCondition: FilterConditionView | undefined = conditions.find(c =>
    c.rules.some(
      r => r.hasOwnProperty('field') && (r as FilterRuleView).field === field
    )
  );
  if (!filterCondition) {
    return undefined;
  }

  const filterRule: FilterRuleOrConditionView | undefined =
    filterCondition.rules.find(
      r => r.hasOwnProperty('field') && (r as FilterRuleView).field === field
    );

  if (!filterRule) return;
  return !returnField
    ? ((filterRule as FilterRuleView).value as RT)
    : (filterRule as RT);
};

export const isUsingNestedConditions = (
  condition?: FilterConditionView
): boolean => {
  return condition?.rules.some(r => r.hasOwnProperty('condition')) ?? false;
};

export const filterListByParent = <T>(
  list: T[],
  compareField?: string,
  selectedValues?: string[]
): T[] => {
  if (list.length === 0) {
    return list;
  }
  if (!selectedValues || selectedValues.length === 0) {
    return list;
  }
  return [...list].filter(item =>
    selectedValues.includes(compareField ? item[compareField] : item)
  );
};

export const filterSitesByParents = <T>(
  list: T[],
  zones: string[],
  groups: string[],
  facilityMaps: string[],
  filtersData: FiltersResponse
): T[] => {
  let filteredList = [...list];
  if (zones.length === 0 && groups.length === 0 && facilityMaps.length === 0) {
    return list;
  }
  if (zones.length > 0) {
    filteredList = filteredList.filter((item: any) =>
      zones.includes(item.zoneId)
    );
  }
  if (groups.length > 0) {
    filteredList = filteredList.filter((item: any) =>
      filtersData.groups
        .filter(g => groups.includes(g.id))
        .flatMap(g => g.siteIds)
        .includes(item.id)
    );
  }
  if (facilityMaps.length > 0) {
    filteredList = filteredList.filter((item: any) =>
      filtersData.facilityMaps
        ?.filter(m => facilityMaps.includes(m.id))
        .flatMap(g => g.markers.flatMap(m => m.siteId))
        .includes(item.id)
    );
  }
  return filteredList;
};

export const buildReportFilters = (
  filters: SelectedFiltersType
): ReportFiltersView => {
  return {
    interval: filters.interval,
    reportType: filters.reportType,
    dataType: filters.dataType,
    chartType: filters.chartType!,
    rollingInterval: filters.rollingInterval,
    dateRangeType: filters.dateRange!.type,
    startDate: filters.dateRange?.start,
    endDate: filters.dateRange?.end,
    advanced: filters.advanced || false,
    ...(filters.includeVectors && {
      includeVectors: filters.includeVectors
    }),
    ...(filters.nonconformancesOnly && {
      nonconformancesOnly: filters.nonconformancesOnly
    }),
    conditions: filters.conditions,
    showTestCounts: filters.showTestCounts,
    dailyFailureTolerance: filters.dailyFailureTolerance,
    hideUntested: filters.hideUntested || false
  };
};

export const buildTestResultFilters = (
  filters: SelectedFiltersType
): TestResultPaginateFilterConditions => {
  return {
    interval: filters.interval,
    reportType: filters.reportType,
    dataType: filters.dataType,
    chartType: filters.chartType!,
    rollingInterval: filters.rollingInterval,
    dateRangeType: filters.dateRange?.type ?? 'ytd',
    ...(filters.dateRange?.start && { startDate: filters.dateRange?.start }),
    ...(filters.dateRange?.end && { endDate: filters.dateRange?.end }),
    advanced: filters.advanced || false,
    ...(filters.includeVectors && {
      includeVectors: filters.includeVectors
    }),
    ...(filters.nonconformancesOnly && {
      nonconformancesOnly: filters.nonconformancesOnly
    }),
    ...(filters.facilityMap && { facilityMapId: filters.facilityMap.id }),
    ...(filters.conditions && {
      conditions: JSON.stringify(filters.conditions)
    }),
    showTestCounts: filters.showTestCounts,
    dailyFailureTolerance: filters.dailyFailureTolerance
  };
};

export const findTests = (
  tests: TestFilterOption[],
  categoryIds?: string[],
  organismIds?: string[]
): TestFilterOption[] => {
  const filteredTests = [...tests].filter(test => {
    if (categoryIds && categoryIds.length > 0) {
      if (organismIds && organismIds.length > 0) {
        return (
          organismIds.includes(test.organismId) &&
          categoryIds.includes(test.categoryId)
        );
      } else {
        return categoryIds.includes(test.categoryId);
      }
    }

    if (organismIds && organismIds.length > 0) {
      return organismIds.includes(test.organismId);
    }

    return true;
  });
  return filteredTests;
};

export const filterOrganisms = (
  categories: CategoryFilterOption[],
  selectedCategories: string[] | undefined,
  organisms: OrganismFilterOption[]
) => {
  if (!selectedCategories || selectedCategories.length === 0) {
    return organisms;
  }
  const filteredOrganismIds: string[] = flatten(
    categories
      .filter(c => selectedCategories.includes(c.id))
      .map(c => c.organisms)
  );
  return organisms.filter(o => filteredOrganismIds.includes(o.id));
};
