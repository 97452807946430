import { CardHeader, CardTitle } from '@react-md/card';
import React from 'react';

import styles from './pageheader.module.scss';

type PageHeaderT = React.FunctionComponent<{
  title: string;
  children?: any;
}>;

export const PageHeader: PageHeaderT = ({ title, children }) => {
  return (
    <CardHeader className={styles.printStyles}>
      <CardTitle
        style={{
          borderBottom: 'solid lightgrey 1px',
          paddingBottom: '8px'
        }}
      >
        {title}
        {children}
      </CardTitle>
    </CardHeader>
  );
};
