import { LocationView } from 'modules/locations/domain/LocationView';
import { UserView } from 'modules/users/domain/UserView';
import { mutate } from 'swr';
import { useEffect } from 'react';
import { useStorageState } from 'react-storage-hooks';
import Cookies from 'universal-cookie';
import Router from 'next/router';

type LocationStateT = [
  LocationView | undefined,
  (LocationView, boolean) => void,
  () => void
];

const dummyStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {}
};

export function useLocation(user?: UserView): LocationStateT {
  const isSsr = typeof window === 'undefined';

  const [location, setLocation] = useStorageState<LocationView | undefined>(
    isSsr ? dummyStorage : localStorage,
    'location-state',
    undefined
  );
  const cookies = new Cookies();

  const setCurrentLocation = (loc: LocationView) => {
    cookies.set('location', loc, { path: '/', maxAge: 1000000 });
    const locationChanged = location && location?.id !== loc.id;
    setLocation(loc);
    const path = Router.pathname;
    const refreshRoutes: any[] = [
      { url: '/reports/[id]', newRoute: '/reports/' },
      {
        url: '/ebacmap/[id]',
        newRoute: '/ebacmap/',
        clearCache: ['/api/test-results']
      },
      { url: '/facility-maps/[id]', newRoute: '/facility-maps/' },
      {
        url: '/test-results/[id]',
        newRoute: '/test-results/',
        clearCache: ['/api/test-results']
      },
      { url: '/admin/users/[id]', newRoute: '/admin/users/' },
      { url: '/setup/sites/[id]', newRoute: '/setup/sites/' },
      { url: '/capa/[id]', newRoute: '/capa/reports/' },
      {
        url: '/sample-scheduler/schedule/[id]',
        newRoute: '/sample-scheduler/schedule/'
      },
      {
        url: '/novalum/plans/[id]',
        newRoute: '/novalum/plans/'
      },
      {
        url: '/novalum/surfaces/[id]',
        newRoute: '/novalum/surfaces/'
      },
      {
        url: '/novalum/corrective-notes/[id]',
        newRoute: '/novalum/corrective-notes/'
      },
      {
        url: '/reports/weekly/[id]',
        newRoute: '/reports/',
        clearCache: ['/api/test-results']
      },
      {
        url: '/reports/monthly/[id]',
        newRoute: '/reports/',
        clearCache: ['/api/test-results']
      },
      {
        url: '/reports/daily/[id]',
        newRoute: '/reports/',
        clearCache: ['/api/test-results']
      },
      {
        url: '/novalum/units/[id]',
        newRoute: '/novalum/units/'
      }
    ];
    const hasMatch = refreshRoutes.find(route => route.url === path);
    if (hasMatch && locationChanged) {
      if (hasMatch.clearCache) {
        mutate(
          key =>
            typeof key === 'string' &&
            hasMatch.clearCache.some(url => key.includes(url)),
          undefined,
          { revalidate: false }
        );
      }
      Router.push(hasMatch.newRoute);
    }
  };

  const clearLocation = () => setLocation(undefined);

  useEffect(() => {
    if (user && user.locations && !location) {
      const cookieLocation = cookies.get('location');
      if (cookieLocation) {
        setLocation(cookieLocation);
      } else {
        setCurrentLocation!(user.locations[0]);
      }
    }
  }, [user, location]);

  return [location, setCurrentLocation, clearLocation];
}

function _withLocation(handler) {
  return async context => {
    const { req } = context;
    const cookies = new Cookies(req.headers.cookie);
    const location = cookies.get('location');

    const newCtx = {
      ...context,
      location
    };

    return handler(newCtx);
  };
}

export default function withLocation(handler) {
  return _withLocation(handler);
}
