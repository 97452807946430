import EnumValueObject from 'common/core/EnumValueObject';

export enum ReportDateRangeTypeValue {
  Day = 'day',
  Custom = 'custom',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  YTD = 'ytd',
  Year = 'year'
}

export const TYPES: string[] = Object.values(ReportDateRangeTypeValue);

export default class ReportDateRangeType extends EnumValueObject(
  ReportDateRangeTypeValue,
  'type'
) {}
