import { BELOW_CENTER_ANCHOR } from '@react-md/utils';
import { DropdownMenu } from '@react-md/menu';
import { useActionClassName } from '@react-md/app-bar';
import React, { ReactNode } from 'react';
import styles from './nav.module.scss';

type NavActionDropdownMenuT = {
  items: ReactNode[];
  title: string;
  last?: boolean;
  id: string;
};

export const NavActionDropdownMenu = (props: NavActionDropdownMenuT) => {
  const className = props.last ? { last: true } : {};
  return (
    <div className={useActionClassName(className)} style={{ color: '#ffffff' }}>
      <DropdownMenu
        buttonType="text"
        id={props.id}
        anchor={BELOW_CENTER_ANCHOR}
        className={styles.appBarAction}
        buttonChildren={props.title}
      >
        {props.items.map(item => item)}
      </DropdownMenu>
    </div>
  );
};
