import { LayoutNavigationItem, LayoutNavigationTree } from '@react-md/layout';
import { LocationView } from 'modules/locations/domain/LocationView';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { UserView } from 'modules/users/domain/UserView';
import { hasPermission } from 'utils/permissions';
import { hasRequiredRole, userCanAccessPage } from 'utils/auth';
export interface RestrictedLayoutNavigationItem extends LayoutNavigationItem {
  roles?: string[];
  action?: PermissionActionValue;
  permissionDomain?: PermissionDomainValue;
  nonGlobal?: boolean;
  additionalPermissions?: string;
}

const restrictedUserNavItems = [
  '/',
  '/sample-scheduler',
  '/sample-scheduler/calendar',
  '/sample-scheduler/schedule',
  '/test-results',
  '/reports',
  '/ebacmap',
  '/training',
  '/admin',
  '/admin/company-profile',
  '/admin/billing'
];

export default function filterByAccess(
  user: UserView,
  items: LayoutNavigationTree<RestrictedLayoutNavigationItem>,
  location?: LocationView
): LayoutNavigationTree {
  const filtered = {};
  Object.keys(items).forEach(key => {
    const item: RestrictedLayoutNavigationItem = items[key];
    if (!item) {
      return;
    }
    if (item.nonGlobal && location?.id === 'global') {
      return;
    }
    if (item.parentId) {
      const parent = items[item.parentId];
      let parentHasAccess = false;
      if (parent.roles) {
        parentHasAccess = hasRequiredRole(parent.roles, user.role);
      } else {
        parentHasAccess = hasPermission(
          user,
          location?.id || '',
          parent.permissionDomain!,
          parent.action!
        );
      }
      if (parentHasAccess) {
        //check if the child has roles
        if (item.roles) {
          if (!location) {
            if (hasRequiredRole(item.roles, user.role)) {
              filtered[key] = item;
            }
          } else if (userCanAccessPage(user, item.roles, location)) {
            filtered[key] = item;
          }
        } else {
          const hasAccess = hasPermission(
            user,
            location?.id || '',
            item.permissionDomain!,
            item.action!,
            item.additionalPermissions
          );
          if (hasAccess) {
            filtered[key] = item;
          }
        }
      }
    } else {
      if (item.roles) {
        if (!location) {
          if (hasRequiredRole(item.roles, user.role)) {
            filtered[key] = item;
          }
        } else if (userCanAccessPage(user, item.roles, location)) {
          filtered[key] = item;
        }
      } else {
        if (item.permissionDomain && item.action) {
          const hasAccess = hasPermission(
            user,
            location?.id || '',
            item.permissionDomain!,
            item.action!,
            item.additionalPermissions
          );
          if (hasAccess) {
            filtered[key] = item;
          }
        } else {
          filtered[key] = item;
        }
      }
    }
  });

  if (user.role === 'restricted_user') {
    return Object.keys(filtered)
      .filter(key => restrictedUserNavItems.includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: filtered[key] });
      }, {});
  }
  return filtered;
}
