import { MapMarkerModal } from './MapMarkerModal';
import { MapMarkerProps } from './types';
import { SiteLocationView } from 'modules/sites/domain/SiteView';
import { useRouter } from 'next/router';
import Draggable from 'react-draggable';
import React, { useEffect, useRef, useState } from 'react';
import styles from './map-marker.module.scss';

export const MapMarker = (props: MapMarkerProps) => {
  const {
    marker,
    sites,
    isNew,
    ratio,
    offsets,
    isClickable,
    handleIsInUse,
    isInUse
  } = props;
  const { posX, posY, siteId } = marker;
  const router = useRouter();
  const markerRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [markerPos, setMarkerPos] = useState({ x: posX, y: posY });
  //Used to reset/reload draggable when saving
  const [randomVal, setRandomVal] = useState<number>(Math.random());

  let left = posX * ratio + (offsets?.left || 0);
  let top = posY * ratio + (offsets?.top || 0);
  const isEbacMap =
    router.pathname === '/ebacmap' || window.matchMedia('print').matches;
  const markerStyle = {
    border: isEbacMap ? '2px solid #f5f5f5' : '2px solid #0091FF',
    height: isEbacMap ? '0.8rem' : '1rem',
    width: isEbacMap ? '0.8rem' : '1rem',
    borderRadius: '50%',
    background: isDraggable ? 'red' : isEbacMap ? '#0091FF' : '#f5f5f5',
    display: 'block',
    position: 'absolute' as 'absolute',
    zIndex: 100,
    transition: 'all 0.3s linear 0s',
    left,
    top: top + 34.5
  };
  const spanStyle = {
    background: 'yellow',
    transition: 'all 0.3s linear 0s',
    position: 'absolute' as 'absolute',
    display: 'block',
    fontSize: '.7rem',
    left,
    top: top - 15 + 34.5
  };

  useEffect(() => {
    if (markerRef && markerRef.current) {
      if (!showModal && isNew && !isInUse) {
        setTimeout(() => {
          setShowModal(true);
        }, 100);
      }
    }
  }, [markerRef]);

  const hideModal = () => {
    setShowModal(false);
    setIsDraggable(false);
    if (handleIsInUse) {
      handleIsInUse(false);
    }
    if (isNew && props.onRemove) {
      props.onRemove(marker);
    }
  };
  const handleSave = (site: SiteLocationView) => {
    setShowModal(false);
    setIsDraggable(false);
    if (handleIsInUse) {
      handleIsInUse(false);
    }
    marker.siteId = site.id;
    marker.zoneCode = site.zoneCode!;
    marker.posX = markerPos.x;
    marker.posY = markerPos.y;
    if (props.onUpdate) {
      props.onUpdate(marker);
      setRandomVal(Math.random());
    }
  };

  const handleDelete = (site: string) => {
    setShowModal(false);
    marker.siteId = site;
    if (props.onRemove) {
      props.onRemove(marker);
    }
  };

  const handleMove = () => {
    setShowModal(false);
    setIsDraggable(true);
    if (handleIsInUse) {
      handleIsInUse(true);
    }
  };
  const handleDrag = (_e, data) => {
    setMarkerPos({
      x: markerPos.x + data.x / ratio,
      y: markerPos.y + data.y / ratio
    });
  };

  const showEdit = () => {
    setShowModal(true);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Draggable key={randomVal} onStop={handleDrag} disabled={!isDraggable}>
        <div className={styles.markerHoverStyles}>
          <span style={spanStyle}>{marker.zoneCode}</span>
          <div
            ref={markerRef}
            style={markerStyle}
            onClick={
              isClickable || isClickable === undefined ? showEdit : () => {}
            }
          ></div>
        </div>
      </Draggable>
      {markerRef && markerRef.current && showModal && (
        <MapMarkerModal
          visible={showModal}
          mapMarker={markerRef.current}
          sites={sites}
          value={siteId}
          onSave={handleSave}
          onDelete={handleDelete}
          onMove={handleMove}
          onRequestClose={hideModal}
        />
      )}
    </>
  );
};
