import {
  AssignmentSVGIcon,
  BurstModeSVGIcon,
  CenterFocusStrongSVGIcon,
  ClearAllSVGIcon,
  DehazeSVGIcon,
  LocationCitySVGIcon
} from '@react-md/material-icons';
import { Container } from 'components/Layout';
import { ExploreSVGIcon, GridCell, Label, Switch, TextField } from 'react-md';
import { FIELDS } from 'modules/reports/domain/FilterRuleFieldValue';
import {
  Filter,
  FilterDateRange,
  FilterOption,
  SelectedFiltersType
} from './FilterTypes';
import { FilterSelect } from './FilterSelect';
import { FiltersResponse } from 'modules/shared/UseCases/GetFilters/types';
import { ReportDateRange } from './ReportDateRange';
import { TYPES } from 'modules/reports/domain/ReportDateRangeType';
import { useState } from 'react';
import styles from './filter-drawer.module.scss';

interface LayoutFilterProps {
  onFilter: (selectedFilters: SelectedFiltersType, isChanged: boolean) => void;
  selectedFilters?: SelectedFiltersType;
  rolling?: boolean;
  maps?: boolean;
  includeCharts?: boolean;
  includeCustomCharts?: boolean;
  filtersData: FiltersResponse;
  disableDateRange?: boolean;
  reportTypes?: FilterOption[];
  surfacePlans?: boolean;
  includeUntestedToggle?: boolean;
}
const buildOptions = (
  data: any[],
  labelKey: string,
  includeAll: boolean = true,
  secondaryField?: string
): FilterOption[] => {
  if (!data || !data.length) {
    return includeAll ? [{ label: 'All', value: 'all' }] : [];
  }
  const options = data.map(result => {
    return {
      label: secondaryField ? (
        <>
          {result[labelKey]}&nbsp;
          <span className="text-xs">{result[secondaryField]}</span>
        </>
      ) : (
        result[labelKey]
      ),
      value: result.id
    };
  });

  return includeAll
    ? [{ label: 'All', value: 'all' }].concat(options)
    : options;
};

export const LayoutFilters = (props: LayoutFilterProps) => {
  const {
    filtersData,
    onFilter,
    selectedFilters,
    maps,
    rolling,
    includeCharts,
    reportTypes,
    includeCustomCharts,
    disableDateRange,
    includeUntestedToggle
  } = props;
  const [tolerance, setTolerance] = useState<number>(
    selectedFilters?.dailyFailureTolerance ?? 100
  );

  const [filteredDateRangeTypes, setFilteredDateRangeTypes] =
    useState<string[]>(TYPES);

  const { facilityMaps } = filtersData;

  const handleInputChange = (name, value: number) => {
    const updated = {
      ...selectedFilters,
      [name]: value
    };
    onFilter(updated, true);
  };

  const handleFilter = (
    name: string,
    value: string[] | FilterDateRange | boolean | number
  ) => {
    if (name === 'map') {
      const selectedMap = filtersData?.facilityMaps?.find(
        m => m.id === String(value)
      );
      onFilter(
        {
          ...selectedFilters,
          facilityMap: selectedMap,
          //@ts-ignore
          facilityMapId: String(value),
          name: selectedMap?.title,
          map: String(value)
        },
        true
      );
      return;
    }
    if (!FIELDS.includes(name)) {
      onFilter(
        {
          ...selectedFilters,
          [name]: value
        },
        true
      );
      return;
    }

    const updated = {
      ...selectedFilters
    };
    if (name === 'chartType') {
      if (typeof value === 'string' && value === 'custom') {
        setFilteredDateRangeTypes(['day', 'week', 'month']);
        updated['dateRange'] = {
          type: 'month'
        };
      } else {
        setFilteredDateRangeTypes(TYPES);
      }
    }
    onFilter(updated, true);
  };

  const availableFilters = [
    ...(maps && (selectedFilters?.reportType !== 'tour' || !filtersData.tours)
      ? [
          {
            name: 'map',
            label: 'Map',
            multiple: false,
            options: buildOptions(facilityMaps || [], 'title', false),
            value: selectedFilters?.map || 'all',
            icon: LocationCitySVGIcon
          }
        ]
      : []),
    ...(selectedFilters?.reportType === 'tour' &&
    filtersData.tours &&
    filtersData.tours.length
      ? [
          {
            name: 'tour',
            label: 'Tour',
            multiple: false,
            options: buildOptions(filtersData.tours || [], 'title', false),
            value: selectedFilters?.tour,
            icon: ExploreSVGIcon
          }
        ]
      : []),

    ...(!disableDateRange
      ? [
          {
            label: 'Date Range',
            name: 'dateRange',
            multiple: false,
            options: filteredDateRangeTypes,
            value: selectedFilters?.dateRange,
            icon: DehazeSVGIcon
          }
        ]
      : []),
    rolling && selectedFilters?.dataType !== 'rolling'
      ? {
          name: 'interval',
          label: 'Interval',
          multiple: false,
          options: [
            {
              label: 'Day',
              value: 'day'
            },
            {
              label: 'Week',
              value: 'week'
            },
            {
              label: 'Month',
              value: 'month'
            },
            {
              label: 'Quarter',
              value: 'quarter'
            },
            {
              label: 'Year',
              value: 'year'
            }
          ],
          value: selectedFilters?.interval || 'day',
          icon: DehazeSVGIcon
        }
      : null,
    rolling
      ? {
          name: 'dataType',
          label: 'Data Type',
          multiple: false,
          options: [
            {
              label: 'Rolling',
              value: 'rolling'
            },
            {
              label: 'Calendar',
              value: 'calendar'
            }
          ],
          value: selectedFilters?.dataType || 'rolling',
          icon: AssignmentSVGIcon
        }
      : null,
    rolling &&
    (selectedFilters?.dataType === 'rolling' || !selectedFilters?.dataType)
      ? {
          name: 'rollingInterval',
          label: 'Rolling Interval',
          multiple: false,
          options: [
            {
              label: '7 Day',
              value: 'sevenDay'
            },
            {
              label: '30 Day',
              value: 'thirtyDay'
            },
            {
              label: '90 Day',
              value: 'ninetyDay'
            }
          ],
          value: selectedFilters?.rollingInterval || 'sevendDay',
          icon: ClearAllSVGIcon
        }
      : null,
    includeCharts
      ? {
          name: 'chartType',
          label: 'Chart Type',
          multiple: false,
          options: [
            {
              label: 'Line',
              value: 'line'
            },
            {
              label: 'Bar',
              value: 'bar'
            },
            ...(includeCustomCharts
              ? [
                  {
                    label: 'Custom',
                    value: 'custom'
                  }
                ]
              : [])
          ],
          value: selectedFilters?.chartType || 'line',
          icon: BurstModeSVGIcon
        }
      : null,
    reportTypes
      ? {
          name: 'reportType',
          label: 'Report Type',
          multiple: false,
          options: reportTypes,
          value: selectedFilters?.reportType || reportTypes[0],
          icon: CenterFocusStrongSVGIcon
        }
      : null,
    ...(includeCharts && !includeCustomCharts
      ? [
          {
            label: 'Show Test Result Counts',
            name: 'showTestCounts',
            type: 'toggle',
            value: selectedFilters?.showTestCounts || false
          }
        ]
      : []),
    ...(selectedFilters?.chartType === 'custom' &&
    selectedFilters?.dateRange?.type === 'month'
      ? [
          {
            label: 'Daily Pass Rate Target %',
            name: 'dailyFailureTolerance',
            type: 'input',
            value: selectedFilters?.dailyFailureTolerance || '100'
          }
        ]
      : []),
    ...(maps
      ? [
          {
            label: 'Include Vector Sites',
            name: 'includeVectors',
            type: 'toggle',
            value: selectedFilters?.includeVectors || false
          }
        ]
      : []),
    ...(includeUntestedToggle
      ? [
          {
            label: 'Hide Untested Sites',
            name: 'hideUntested',
            type: 'toggle',
            value: selectedFilters?.hideUntested || false
          }
        ]
      : [])
  ].filter(f => f);

  return (
    <>
      {availableFilters.map((element, index) => {
        if (element?.type && element.type === 'toggle') {
          return (
            <GridCell key={index} colSpan={4}>
              <Switch
                key={index}
                id={element.name}
                name={element.name}
                label={element.label}
                defaultChecked={!!element.value}
                onChange={e => handleFilter(element?.name, e.target.checked)}
              />
            </GridCell>
          );
        } else if (element?.type && element.type === 'input') {
          return (
            <GridCell key={index} colSpan={4}>
              <TextField
                key={index}
                id={element.name}
                name={element.name}
                label={element.label}
                value={tolerance.toString()}
                type="number"
                onChange={e => setTolerance(parseFloat(e.target.value))}
                onBlur={e =>
                  handleInputChange(element?.name, parseFloat(e.target.value))
                }
              />
            </GridCell>
          );
        } else if (element!.label !== 'Date Range') {
          return (
            <GridCell key={index} colSpan={4}>
              <FilterSelect
                index={index}
                multiple={element?.multiple ?? false}
                filter={element! as Filter}
                filterCallback={(name: string, value: any) =>
                  handleFilter(name, value)
                }
              />
            </GridCell>
          );
        } else {
          return (
            <GridCell colSpan={4} key={index}>
              <Container className={styles.filterContainer}>
                <Label className={styles.filterLabel} htmlFor={element!.name}>
                  {element!.label}
                </Label>
                {element?.icon ? (
                  <element.icon className={styles.filterIcon} />
                ) : null}
                <ReportDateRange
                  type={(element!.value as FilterDateRange)?.type}
                  start={(element!.value as FilterDateRange)?.start}
                  end={(element!.value as FilterDateRange)?.end}
                  onDatesChange={dateRange => {
                    handleFilter('dateRange', dateRange);
                  }}
                  options={element!.options}
                />
              </Container>
            </GridCell>
          );
        }
      })}
    </>
  );
};
