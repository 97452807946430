import {
  Button,
  Dialog,
  DialogContent,
  DropdownMenu,
  MenuItem
} from 'react-md';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import React, { useState } from 'react';

interface AdvancedFilterAndOrProps {
  handleNewCell: () => void;
  handleNewGroup: () => void;
  handleRemove: (index: number) => void;
  childLevel: number;
  index?: number;
  condition: 'and' | 'or';
  isChild?: boolean;
  setCondition: (cond: 'and' | 'or') => void;
  checkTriggerNonValid: () => boolean;
  validComparison: boolean;
  selfDestruct?: () => void;
}

const AdvancedFilterAndOr = (props: AdvancedFilterAndOrProps) => {
  const {
    handleNewCell,
    handleNewGroup,
    isChild,
    selfDestruct,
    childLevel,
    condition,
    setCondition
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <div className={'md:flex flex-row items-center'}>
      <Button
        className={`${
          condition === 'and'
            ? 'bg-primary-green text-white'
            : 'bg-gray-200 text-black'
        } rounded-none`}
        onClick={() => setCondition('and')}
      >
        And
      </Button>
      <Button
        className={`${
          condition === 'or'
            ? 'bg-primary-green text-white'
            : 'bg-gray-200 text-black'
        } rounded-none`}
        onClick={() => setCondition('or')}
      >
        Or
      </Button>

      <DropdownMenu
        buttonType="icon"
        className={`mx-4 cursor-pointer`}
        buttonChildren={<MdAddCircleOutline className={`w-6 h-6`} />}
        id="condition-group-menu"
      >
        {childLevel <= 2 && (
          <MenuItem
            rightAddon={<MdAddCircleOutline className={`w-6 h-6`} />}
            onClick={() => handleNewGroup()}
          >
            Add Group
          </MenuItem>
        )}
        <MenuItem
          rightAddon={<MdAddCircleOutline className={`w-6 h-6`} />}
          onClick={() => handleNewCell()}
        >
          Add Condition
        </MenuItem>
      </DropdownMenu>

      {isChild && childLevel > 1 && (
        <MdRemoveCircleOutline
          className={`w-6 h-6 mx-4 cursor-pointer`}
          onClick={selfDestruct}
        />
      )}

      <Dialog
        id="condition-dialog"
        visible={dropdownOpen}
        onRequestClose={() => setDropdownOpen(false)}
        aria-labelledby="condition-dialog"
      >
        <DialogContent>
          <div className={`p-2 gap-4 md:flex flex-col`}>
            <Button
              themeType="outline"
              onClick={() => {
                handleNewGroup();
                setDropdownOpen(false);
              }}
            >
              Add Group
            </Button>
            <Button
              themeType="outline"
              onClick={() => {
                handleNewCell();
                setDropdownOpen(false);
              }}
            >
              Add Condition
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdvancedFilterAndOr;
